import { useLocation, useParams } from 'react-router-dom';
// sections
import { UserManager } from 'src/@types/user';
import { appApi } from 'src/api/redux/app/appApi';
import useAppUserContext from 'src/hooks/useAppUserContext';

// @mui
import { Container } from '@mui/material';

// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import UserNewEditForm from '../../sections/@dashboard/user/UserNewEditForm';

// ----------------------------------------------------------------------

export default function FacilitatorCreate() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { id = '' } = useParams();
  const userContext = useAppUserContext();
  const isEdit = pathname.includes('/edit');

  const getUserRolesQuery = isEdit ? appApi.endpoints.identityUserGetRoles.useQuery({
    id: id
  },
  {
    refetchOnMountOrArgChange: true
  }
  ): undefined;

  const getUserQuery = isEdit ? appApi.endpoints.identityUserGet.useQuery({
    id: id
  },
  {
    refetchOnMountOrArgChange: true
  }) : undefined;

  const getUserClaimsQuery = isEdit ? appApi.endpoints.identityUserGetClaims.useQuery({
    id: id
  },
  {
    refetchOnMountOrArgChange: true
  }) : undefined;

  const currentUser = isEdit ? {
    id: id,
    avatarUrl: undefined,
    name: getUserQuery?.data?.name,
    userName: getUserQuery?.data?.userName,
    lastName: getUserQuery?.data?.surname,
    email: getUserQuery?.data?.email,
    phoneNumber: getUserQuery?.data?.phoneNumber,
    address: getUserClaimsQuery?.data?.find(c => c.claimType === 'address')?.claimValue,
    isActive: getUserQuery?.data?.isActive,
    lockoutEnabled: getUserQuery?.data?.lockoutEnabled,
    roleNames: getUserRolesQuery?.data?.items?.map(e => e.name) || getUserQuery?.data?.roleNames,
    organizationUnitIds: [],
    concurrencyStamp: getUserQuery?.data?.concurrencyStamp,
    creationTime: getUserQuery?.data?.creationTime,    
    postcode: getUserQuery?.data?.extraProperties ? getUserQuery?.data?.extraProperties['Postcode'] : null,
    countryCode: getUserQuery?.data?.extraProperties ? getUserQuery?.data?.extraProperties['CountryCode'] : null,    
    isPartialUser: getUserQuery?.data?.extraProperties ? getUserQuery?.data?.extraProperties['IsPartialUser'] === true : null
  } as UserManager : undefined;

  const pageTitle = !isEdit ? 'Create facilitator' : (currentUser?.name + ' ' + currentUser?.lastName);

  return (
    <Page title={pageTitle}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create facilitator' : 'Edit facilitator'}
          links={[
            { name: 'Facilitators', href: PATH_DASHBOARD.tenant.facilitator.list },
            { name: pageTitle },
          ]}
        />        

        {
          !isEdit || (!getUserQuery?.isLoading && currentUser) ?        
        <UserNewEditForm isEdit={isEdit} isEditCurrentUserProfile={false} isFacilitatorForm={true} currentUser={currentUser} />
        : undefined }
      </Container>
    </Page>
  );
}
