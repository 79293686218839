import { useState, useEffect } from 'react';

export function usePageLifecycle() {
    const [isLoading, setIsLoading] = useState(document.readyState === 'loading' || document.readyState === 'interactive');
    const [isNavigatingAway, setIsNavigatingAway] = useState(false);

    useEffect(() => {
        // Handle document loading state changes
        const handleReadyStateChange = () => {
            setIsLoading(document.readyState === 'loading' || document.readyState === 'interactive');
        };

        // Handle the beforeunload event for navigation/refresh
        const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
            setIsNavigatingAway(true);
            // event.preventDefault();
            // event.returnValue = ''; // Required for Chrome to show the confirmation dialog
        };

        // Add event listeners
        document.addEventListener('readystatechange', handleReadyStateChange);
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listeners on unmount
        return () => {
            document.removeEventListener('readystatechange', handleReadyStateChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return { isLoading, isNavigatingAway };
}