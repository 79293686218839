import { Box } from '@mui/material';
import parse, { HTMLReactParserOptions, Element, domToReact, DOMNode } from 'html-react-parser';

const options: HTMLReactParserOptions = {
    replace({ name, attribs, children, type }: Element) {
        if (!attribs) {
            return;
        }
        if (type === 'tag') {
            if (name === 'ol') {
                return <Box pl={4} pt={1} pb={1}><ol>{domToReact(children as DOMNode[], options)}</ol></Box>;
            }
            else if (name === 'ul') {
                return <Box pl={4} pt={1} pb={1}><ul>{domToReact(children as DOMNode[], options)}</ul></Box>;
            }
            else if (name === 'a') {
                const href = attribs.href;

                const link = !href.startsWith('http') || !href.startsWith('https') ? `https://${href}` : href;

                return <a {...attribs} target="_blank" rel='noreferrer' href={link}>{domToReact(children as DOMNode[], options)}</a>
            }
        }
    }
};

export const htmlToReact = (html: string) => {
    return parse(html, options);
}