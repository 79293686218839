import { MenuItem, TextField } from "@mui/material";
import { appApi } from "src/api/redux/app/appApi";

type Props = {
  initialValue: string;
  label?: string;
  includeAllOption: boolean;
  onFilterTenant: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function TenantSelect({
  initialValue,
  onFilterTenant,
  label,
  includeAllOption,
}: Props) {
  const tenantQuery = appApi.endpoints.tenantGetList.useQuery({
    maxResultCount: 100
  });

  return (
    <TextField
      fullWidth
      select
      label={label || "Tenant"}
      value={initialValue}
      onChange={onFilterTenant}
      SelectProps={{
        MenuProps: {
          sx: { '& .MuiPaper-root': { maxHeight: 260 } },
        },
      }}
      sx={{
        maxWidth: { sm: 240 },
        textTransform: 'capitalize',
      }}
    >
      {includeAllOption ?
        <MenuItem
          key={'all'}
          value={'all'}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          All
        </MenuItem>
        : undefined
      }
      {(tenantQuery.data?.items || []).map((option) => (
        <MenuItem
          key={option.name}
          value={option.id}
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: 'body2',
            textTransform: 'capitalize',
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}