// components
import { useNavigate } from 'react-router';
import { appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import AppNotifications from 'src/sections/@dashboard/general/app/AppNotifications';
import GenericDashboardTile from 'src/sections/shared/GenericDashboardTile';

// @mui
import { Container, Grid, Typography } from '@mui/material';

import Page from '../../components/Page';

// ----------------------------------------------------------------------

export default function ManagementTiles() {
    const navigate = useNavigate();

    const appUserContext = useAppUserContext();
    const isHostTenant = !appUserContext?.currentTenant?.id;
    const partnerAdminDashboardQuery = !isHostTenant ? appApi.endpoints.partnerDashboardGet.useQuery() : undefined;
    const permissionChecker = usePermissionChecker();

    const { themeStretch } = useSettings();

    const tileProps = {
        xs: 12,
        md: 6,
        lg: 4,
        xl: 3
    }

    return (
        <Page title="Manage">
            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Grid container spacing={3} rowSpacing={8}>
                    {/* <Grid item xs={12} md={6}>
                        <AppWelcome
                        title={`Hi ${user?.name}!`}
                        description="Manage all your settings below"
                        />
                    </Grid> */}

                    <Grid item xs={12} md={12}>
                        <AppNotifications
                            title="Notifications"
                            tableData={partnerAdminDashboardQuery?.data?.notifications || []}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='h4' sx={{ mb: 0.5 }}>My account</Typography>

                        <Grid container spacing={3}>
                            <Grid item {...tileProps}>
                                <GenericDashboardTile icon={'eva:shield-fill'} title="Update password" description={'Update your password used to login to the portal'} onClick={() => navigate(PATH_DASHBOARD.user.changePassword)} />
                            </Grid>
                            <Grid item {...tileProps}>
                                <GenericDashboardTile icon={'eva:person-fill'} title="Update profile" description='Update your personal user profile' onClick={() => navigate(PATH_DASHBOARD.user.account)} />
                            </Grid>
                        </Grid>
                    </Grid>

                    { isHostTenant || permissionChecker.has(permissionKeys.tenant.partner.admin.setup) ?
                        <Grid item xs={12}>
                            <Typography variant='h4' sx={{ mb: 0.5 }}>Manage</Typography>

                            <Grid container spacing={3}>
                                {isHostTenant ?
                                    <>
                                        <Grid item {...tileProps}>
                                            <GenericDashboardTile icon={'eva:people-fill'} title="Team members" description={'Manage users that have access to the master portal'} onClick={() => navigate(PATH_DASHBOARD.user.list)} />
                                        </Grid>
                                        <Grid item {...tileProps}>
                                            <GenericDashboardTile icon={'eva:checkmark-circle-2-fill'} title="Attributes" description='Manage the list background experience, qualifications and interest attributes to be assigned to facilitators and participants' onClick={() => navigate(PATH_DASHBOARD.master.person.attributes.list)} />
                                        </Grid>
                                        <Grid item {...tileProps}>
                                            <GenericDashboardTile icon={'eva:award-fill'} title="Accreditations" description='Manage the list of accreditations that can be assigned to courses and be used to credit the facilitators and participants' onClick={() => navigate(PATH_DASHBOARD.master.person.accreditations.list)} />
                                        </Grid>
                                    </>
                                    : undefined
                                }
                                <PermissionBasedGuard permissionNameKeys={[permissionKeys.tenant.partner.admin.setup]}>
                                    <Grid item {...tileProps}>
                                        <GenericDashboardTile icon={'eva:people-fill'} title="Team members" description={'Manage admins and course co-ordinators that have access to your partner portal'} onClick={() => navigate(PATH_DASHBOARD.user.list)} />
                                    </Grid>
                                    <Grid item {...tileProps}>
                                        <GenericDashboardTile icon={'eva:info-fill'} title="Partner profile" description='Update your public partner profile that is provided to facilitators and participants' onClick={() => navigate(PATH_DASHBOARD.tenant.partner.profile.edit)} />
                                    </Grid>
                                </PermissionBasedGuard>                            
                            </Grid>
                        </Grid> : undefined
                    }

                    { isHostTenant || permissionChecker.has(permissionKeys.tenant.partner.admin.setup) ?
                    <Grid item xs={12}>
                        <Typography variant='h4' sx={{ mb: 0.5 }}>Connections</Typography>

                        <Grid container spacing={3}>
                            {isHostTenant ?
                                <>
                                    <Grid item {...tileProps}>
                                        <GenericDashboardTile icon={'eva:email-fill'} title="ActiveCampaign" description={'Manage settings for the ActiveCampaign integration that enables automated email and marketing workflows'} onClick={() => navigate(PATH_DASHBOARD.master.integrations.activeCampaign.settings)} />
                                    </Grid>
                                    {/* <Grid item {...tileProps}>
                                        <GenericDashboardTile icon={'eva:cast-fill'} title="Blog &amp; news feeds" description='Manage blog and news RSS feeds that are displayed on the dashboards to allow users to favourite articles' />
                                    </Grid> */}
                                </>
                                :
                                undefined
                            }
                            <PermissionBasedGuard permissionNameKeys={[permissionKeys.tenant.partner.admin.setup, permissionKeys.master.manageSettings]}>
                                        <Grid item {...tileProps}>
                                            <GenericDashboardTile icon={'eva:move-fill'} title="API" description={'View settings for API interaction and other integrations'} onClick={() => navigate(PATH_DASHBOARD.master.integrations.api)} />
                                        </Grid>
                                    </PermissionBasedGuard> 
                        </Grid>
                    </Grid> : undefined }

                </Grid>
            </Container>
        </Page>
    );
}
