//
// import Searchbar from './Searchbar';
import { useAuth } from 'react-oidc-context';
import useAppUserContext from 'src/hooks/useAppUserContext';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useImpersonation from 'src/utils/impersonation';

// @mui
import { AppBar, Box, Button, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// components
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import Logo from '../../../components/Logo';
// config
import { HEADER, NAVBAR } from '../../../config';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
import AccountPopover from './AccountPopover';
import LoadingScreen from 'src/components/LoadingScreen';
import { usePageLifecycle } from 'src/hooks/usePageLifeycle';
import { appApi } from 'src/api/redux/app/appApi';

// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  color: theme.palette.primary.main,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const theme = useTheme();
  const pageLifecycle = usePageLifecycle();

  const userContext = useAppUserContext();
  const currentUser = useCurrentUser();
  const configQuery = appApi.endpoints.abpApplicationConfigurationGet.useQuery({ includeLocalizationResources: false }, { refetchOnReconnect: true, refetchOnFocus: true });
  const { resetToPreviousUser, isImpersonatingUser, isImpersonatingTenant } = useImpersonation();

  const isDesktop = useResponsive('up', 'lg');
  if (pageLifecycle.isLoading || pageLifecycle.isNavigatingAway || !currentUser || configQuery.isLoading || configQuery.isFetching) return <LoadingScreen />

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
          backgroundColor: isImpersonatingUser ? theme.palette.error.light : (isImpersonatingTenant ? theme.palette.warning.lighter : undefined)
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {isDesktop && verticalLayout && userContext?.currentTenant?.id && <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>{userContext.currentTenant.name}</Typography>}
        {isDesktop && verticalLayout && !userContext?.currentTenant?.id && <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Halogen, sans-serif' }}>Master portal</Typography>}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        {isImpersonatingUser || isImpersonatingTenant ?
          <Tooltip title={isImpersonatingUser ? 'Stop impersonating ' + currentUser?.name + ' and return to partner portal' : 'Stop viewing partner and return to the master portal'} placement='bottom'>
            <Button onClick={() => resetToPreviousUser()} variant="contained" color={isImpersonatingUser ? 'error' : 'warning'} style={{ marginRight: '30px' }} sx={{ m: 1 }} startIcon={<Iconify icon={'eva:undo-fill'} />}>
              {isImpersonatingUser ? 'Partner portal' : 'Master portal'}
            </Button>
          </Tooltip>
          :
          <></>
        }

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover />
          <NotificationsPopover />
          <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
