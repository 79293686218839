import { Activ8CoursesTemplatesLearningCourseTemplateDto } from 'src/api/redux/app/appApi';

import {
    Card, CardActions, CardContent, Divider, Grid, Paper, Stack, Typography, useTheme
} from '@mui/material';

type Props = {
    template: Activ8CoursesTemplatesLearningCourseTemplateDto;
    actions?: React.ReactElement;
    selected?: boolean;
    faded?: boolean;
};

export default function LearningCourseTemplateDetailsCard({ template, actions, selected, faded }: Props) {
    const theme = useTheme();

    const fadedColor = faded ? theme.palette.grey[400] : undefined;

    return (
        <Card variant='outlined' sx={{ borderColor: selected ? theme.palette.primary.main : undefined, borderWidth: selected ? 2 : undefined}}>
            <CardContent>
                <Typography variant='h6' color={fadedColor || "text.secondary"} gutterBottom>
                    { template.internalName }
                </Typography>
                <Typography variant='body2' sx={{ mb: 2 }} color={fadedColor || "text.secondary"}>
                    #{ template.courseReferenceCode }
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography color={fadedColor}>
                    { template.partnerShortDescription }
                </Typography>
            </CardContent>
            {actions ? 
                <CardActions>
                    {actions}
                </CardActions> 
            : undefined}
        </Card>
    )
}