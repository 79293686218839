import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
// sections
import { appApi } from 'src/api/redux/app/appApi';
import AttributeNewEditForm from 'src/sections/master/setup/person/attribute/AttributeNewEditForm';

// ----------------------------------------------------------------------

export default function AttributeCreate() {
  const { themeStretch } = useSettings();

  const { pathname } = useLocation();

  const { id = '' } = useParams();

  const isEdit = pathname.includes('/edit');

  const getAttributeQuery = isEdit ? appApi.endpoints.personAttributeGet.useQuery({
    id: id
  },
  {
    refetchOnMountOrArgChange: true
  }) : undefined;

  const currentAttribute = isEdit ? getAttributeQuery?.data : undefined;

  return (
    <Page title="Attribute: Create">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create person attribute' : 'Edit person attribute'}
          links={[
            { name: 'Manage', href: PATH_DASHBOARD.general.manageSettings },
            { name: 'Attributes', href: PATH_DASHBOARD.master.person.attributes.list },
            { name: !isEdit ? 'Create' : (currentAttribute?.description || '') },
          ]}
        />

        <AttributeNewEditForm isEdit={isEdit} currentAttribute={currentAttribute} />
      </Container>
    </Page>
  );
}
