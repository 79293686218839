import RHFPersonAccreditationSelect from 'src/components/hook-form/RHFPersonAccreditationSelect';

import { Stack, Typography } from '@mui/material';

export default function LearningCourseTemplateWizardFormAccreditationStep() {
  const accredHelperText = 
    <>
      Upon completion of the course, grant participants the specified accrediations which will allow them to undertake other courses that have these prerequisites. 
    </>;

  return (
    <Stack rowGap={6}>
      <Stack gap={1}>
        <Typography variant='h5' gutterBottom>Grant accreditations</Typography>
        <Stack gap={4}>
        <RHFPersonAccreditationSelect name="postCompletionAccreditationsIds" helperText={accredHelperText} />
        </Stack>
      </Stack>
    </Stack>    
  )
}