// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/app';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/dashboard'),
    manageSettings: path(ROOTS_DASHBOARD, '/manage'),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    changePassword: path(ROOTS_DASHBOARD, '/user/account?tab=password'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
  },  
  master: {
    partner: {
      new: path(ROOTS_DASHBOARD, '/partner/new'),
      list: path(ROOTS_DASHBOARD, '/partner/list'),
      edit: (id: string) => path(ROOTS_DASHBOARD, `/partner/${id}/edit`),
    }, 
    person: {
      accreditations: {
        new: path(ROOTS_DASHBOARD, '/setup/accreditations/new'),
        list: path(ROOTS_DASHBOARD, '/setup/accreditations/list'),
        edit: (id: string) => path(ROOTS_DASHBOARD, `/setup/accreditations/${id}/edit`),
      }, 
      attributes: {
        new: path(ROOTS_DASHBOARD, '/setup/attributes/new'),
        list: path(ROOTS_DASHBOARD, '/setup/attributes/list'),
        edit: (id: string) => path(ROOTS_DASHBOARD, `/setup/attributes/${id}/edit`),
      }, 
    },
    integrations: {
      activeCampaign: {
        settings: path(ROOTS_DASHBOARD, '/setup/integrations/active-campaign')
      },
      api: path(ROOTS_DASHBOARD, '/setup/integrations/api')
    },
    courseTemplate: {
      new: path(ROOTS_DASHBOARD, '/course/template/new'),
      list: path(ROOTS_DASHBOARD, '/course/template/list'),
      edit: (id: string) => path(ROOTS_DASHBOARD, `/course/template/${id}/edit`),
      overview: (id: string) => path(ROOTS_DASHBOARD, `/course/template/${id}/overview`),
    },
  },
  tenant: {
    partner: {
      profile: {
        edit: path(ROOTS_DASHBOARD, '/partner/profile/edit'),
      }
    }, 
    participant: {
      new: path(ROOTS_DASHBOARD, '/participant/new'),
      list: path(ROOTS_DASHBOARD, '/participant/list'),
      edit: (id: string) => path(ROOTS_DASHBOARD, `/participant/${id}/edit`),
      overview: (id: string) => path(ROOTS_DASHBOARD, `/participant/${id}/overview`),
    },
    facilitator: {
      new: path(ROOTS_DASHBOARD, '/facilitator/new'),
      list: path(ROOTS_DASHBOARD, '/facilitator/list'),
      edit: (id: string) => path(ROOTS_DASHBOARD, `/facilitator/${id}/edit`),
      overview: (id: string) => path(ROOTS_DASHBOARD, `/facilitator/${id}/overview`),
    },
    program: {
      new: path(ROOTS_DASHBOARD, '/program/new'),
      list: path(ROOTS_DASHBOARD, '/program/list'),
      edit: (id: string) => path(ROOTS_DASHBOARD, `/program/${id}/edit`),
      overview: (id: string) => path(ROOTS_DASHBOARD, `/program/${id}/overview`),
    }
  }
};
