import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import { ForbiddenIllustration } from '../assets';

// ----------------------------------------------------------------------
 
type PermissionBasedGuardProp = {
  permissionNameKeys?: string[];
  children: React.ReactNode;
  injectFeedback?: boolean;
};

export default function PermissionBasedGuard({ permissionNameKeys, injectFeedback, children }: PermissionBasedGuardProp) {
  const permissionChecker = usePermissionChecker();  

  if (typeof permissionNameKeys !== 'undefined' && !permissionChecker.hasAny(permissionNameKeys)) {
    if(!injectFeedback) return <></>;

    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    )
  }

  return <>{children}</>;
}
