import { Activ8CoursesTemplatesLearningCourseTemplateDto, appApi } from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';

import { Chip, Stack, Typography } from '@mui/material';

type IProps = {  
  courseTemplate: Activ8CoursesTemplatesLearningCourseTemplateDto;
}

export default function LearningCourseTemplateWizardFormAccreditationStep({ courseTemplate }: IProps) {
  const accreditationQuery = appApi.endpoints.personAccreditationGetList.useQuery({maxResultCount: 100 });

  const accreditationIds = courseTemplate.postCompletionAccreditations?.map(e => e.personAccreditationId) || [];

  const { isLoading } = accreditationQuery;

  return (
    isLoading ?
          <LoadingScreen /> 
          :      
    <Stack rowGap={6}>
      <Stack gap={1}>
        <Typography variant='h5' gutterBottom>Granted accreditations</Typography>
        {
          accreditationIds.length === 0 ?
          <Typography>
            No post completion accreditations have been assigned to this course.
          </Typography> 
          :
          <Stack gap={2}>
            <Typography>
              Upon completion of this program the below accreditations will be granted and assigned to participants.
            </Typography>
            <Typography>
              These will allow them to complete programs that depend on the accreditations.
            </Typography>
            <Stack direction="row" spacing={1}>
              {                
                (accreditationQuery.data?.items || []).filter(e => accreditationIds.indexOf(e.id as string) > -1).map(accred => <Chip icon={<Iconify icon='eva:award-outline' />} key={accred.id} label={accred.description} />)
              }
            </Stack>
          </Stack>  
        }        
      </Stack>
    </Stack>    
  )
}