import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useSettings from '../../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from '../../../../components/Page';
// sections
import { appApi } from 'src/api/redux/app/appApi';
import AccreditationNewEditForm from 'src/sections/master/setup/person/accreditation/AccreditationNewEditForm';

// ----------------------------------------------------------------------

export default function AccreditationCreate() {
  const { themeStretch } = useSettings();

  const { pathname } = useLocation();

  const { id = '' } = useParams();

  const isEdit = pathname.includes('/edit');

  const getAccreditationQuery = isEdit ? appApi.endpoints.personAccreditationGet.useQuery({
    id: id
  },
  {
    refetchOnMountOrArgChange: true
  }) : undefined;

  const currentAccreditation = isEdit ? getAccreditationQuery?.data : undefined;

  return (
    <Page title="Accreditation: Create">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create person accreditation' : 'Edit person accreditation'}
          links={[
            // { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Manage', href: PATH_DASHBOARD.general.manageSettings },
            { name: 'Accreditations', href: PATH_DASHBOARD.master.person.accreditations.list },
            { name: !isEdit ? 'Create' : (currentAccreditation?.description || '') },
          ]}
        />

        <AccreditationNewEditForm isEdit={isEdit} currentAccreditation={currentAccreditation} />
      </Container>
    </Page>
  );
}
