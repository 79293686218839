import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Activ8CoursesTemplatesLearningCourseTemplateDto, appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import {
  LearningCourseProgramActions
} from 'src/sections/courses/programs/LearningCourseProgramActions';
import LearningCourseProgramOverviewDashboard from 'src/sections/courses/programs/LearningCourseProgramOverviewDashboard';
import ParticipantImportDialog from 'src/sections/participant/ParticipantImportDialog';

import { Button, Container, Menu } from '@mui/material';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function LearningCourseProgramOverview() {
  const { themeStretch } = useSettings();
  const { id = '' } = useParams();
  // const confirm = useConfirm();
  // const navigate = useNavigate();
  // const { handleError } = useApiResponseHandler();
  // const { enqueueSnackbar } = useSnackbar();
  const permissionChecker = usePermissionChecker();
  const hasProgramCrudPermission = permissionChecker.has(permissionKeys.tenant.program.createUpdateDelete);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [courseTemplate, setCourseTemplate] = useState<Activ8CoursesTemplatesLearningCourseTemplateDto>({});

  const hasImportPermission = permissionChecker.has(permissionKeys.tenant.participant.import);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [getLearningCourseTemplateLazyQuery, getLearningCourseTemplateLazyQueryStatus] = appApi.endpoints.learningCourseTemplateGet.useLazyQuery();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLearningCourseProgramQuery = appApi.endpoints.learningCourseProgramGet.useQuery({
    id: id
  },
    {
      refetchOnMountOrArgChange: true
    });

  const currentLearningCourseProgram = getLearningCourseProgramQuery && !getLearningCourseProgramQuery.isLoading && getLearningCourseProgramQuery.data ? getLearningCourseProgramQuery.data : undefined;

  const programName = currentLearningCourseProgram?.internalName || '';

  const onDataRefetch = () => {
    getLearningCourseProgramQuery.refetch();
  }

  useEffect(() => {
    const getTemplate = async () => {
      const t = await getLearningCourseTemplateLazyQuery({ id: getLearningCourseProgramQuery?.data?.learningCourseTemplateId as string })
      setCourseTemplate(t.data as Activ8CoursesTemplatesLearningCourseTemplateDto);
    }

    if (!getLearningCourseProgramQuery?.data?.learningCourseTemplateId) return;

    getTemplate();
  }, [getLearningCourseProgramQuery.data]);

  const isLoading = !id || !courseTemplate.id || !getLearningCourseTemplateLazyQueryStatus.data || getLearningCourseTemplateLazyQueryStatus.isLoading || getLearningCourseProgramQuery.isLoading;

  return (
    <Page title={programName}>
      {isLoading ? <LoadingScreen /> :

        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={programName}
            links={[
              { name: 'Programs', href: PATH_DASHBOARD.tenant.program.list },
              { name: programName },
            ]}
            action={
              hasProgramCrudPermission ?
                <>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant="outlined"
                    sx={{ mr: 3 }}
                  >
                    Actions
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {
                      <LearningCourseProgramActions onImport={hasImportPermission ? () => { setIsImportDialogOpen(true) } : undefined} onDataRefetch={onDataRefetch} row={currentLearningCourseProgram as any} handleCloseMenu={handleClose} />
                    }
                  </Menu>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={PATH_DASHBOARD.tenant.program.edit(id)}
                    startIcon={<Iconify icon={'eva:edit-fill'} />}
                  >
                    Edit
                  </Button>
                </> : undefined
            }
          />

          {hasImportPermission && getLearningCourseProgramQuery.data ? <ParticipantImportDialog assignToProgram={getLearningCourseProgramQuery.data} isOpen={isImportDialogOpen} onClose={() => setIsImportDialogOpen(false)} /> : undefined}

          {
            (!getLearningCourseProgramQuery?.isLoading && !getLearningCourseProgramQuery.isFetching && currentLearningCourseProgram) ?
              <LearningCourseProgramOverviewDashboard onDataRefetch={onDataRefetch} template={courseTemplate} program={currentLearningCourseProgram} />
              : undefined}
        </Container>
      }
    </Page>
  );
}
