import {
    VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto
} from 'src/api/redux/account/accountApi';
import { appApi } from 'src/api/redux/app/appApi';
import UserRoleSelect from 'src/components/account/role/UserRoleSelect';

import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material';

// components
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  optionsStatus: string[];
  filterStatus: string;
  onFilterStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  appUserContext: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto | undefined;
  filterName: string;
  filterRole?: string;
  onFilterName: (value: string) => void;
  onFilterRole?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function UserTableToolbar({
  filterName,
  filterRole,
  filterStatus,
  onFilterName,
  onFilterRole,
  onFilterStatus,
  optionsStatus,
  appUserContext,
}: Props) {
  const isHostTenant = !appUserContext?.currentTenant?.id;

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        select
        label="Status"
        value={filterStatus}
        onChange={onFilterStatus}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {optionsStatus.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>

      { !isHostTenant && filterRole && onFilterRole ? 
        <UserRoleSelect initialValue={filterRole} onFilterRole={onFilterRole} includeAllOption={true} />
        : undefined
      }

      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
