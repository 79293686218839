import { UserManager } from 'src/@types/user';
import { VoloAbpIdentityIdentityUserDto } from 'src/api/redux/app/appApi';

export function mapIdentityUser(user: VoloAbpIdentityIdentityUserDto | any | undefined): UserManager {
    if(!user) return {} as UserManager;

    return {
        ...user,
        lastName: user.lastName || user.surname as string,
        address: user.address || (user.extraProperties ? user.extraProperties['claims.address'] || user.extraProperties['address'] : null),
        postcode: user.postcode || (user.extraProperties ? user.extraProperties['Postcode'] : null),
        countryCode: user.countryCode || (user.extraProperties ? user.extraProperties['CountryCode'] : null),
        isPartialUser: user.isPartialUser || (user.extraProperties ? user.extraProperties['IsPartialUser'] === true : false),
    } as UserManager;
}