import { useLocation } from 'react-router-dom';
import { appApi } from 'src/api/redux/app/appApi';
import LoadingScreen from 'src/components/LoadingScreen';
import { PATH_DASHBOARD } from 'src/routes/paths';
import PartnerProfileForm from 'src/sections/master/partner/PartnerProfileForm';

import { Container } from '@mui/material';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import useSettings from '../../hooks/useSettings';

// @mui

// hooks

// components


// sections





// ----------------------------------------------------------------------

export default function PartnerProfile() {
  const { themeStretch } = useSettings();

  const { pathname } = useLocation();

  const isEdit = pathname.includes('/edit');

  const getProfileQuery = appApi.endpoints.partnerProfileGet.useQuery(undefined,
  {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true
  });

  const currentProfile = getProfileQuery?.data;

  const { isLoading } = getProfileQuery;

  return (
    <Page title="Partner profile">
      {
        isLoading ? <LoadingScreen /> :         
          <Container maxWidth={themeStretch ? false : 'lg'}>
            <HeaderBreadcrumbs
              heading={'Partner profile'}
              links={[
                { name: 'Manage', href: PATH_DASHBOARD.general.manageSettings },
                { name: 'Partner profile' },
              ]}
            />

            <PartnerProfileForm isEdit={isEdit} currentProfile={currentProfile} />
          </Container>        
      }
    </Page>    
  );
}
