import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Activ8PersonAttributesPersonAttributeCreateOrUpdateDto,
  Activ8PersonAttributesPersonAttributeDto, appApi
} from 'src/api/redux/app/appApi';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack } from '@mui/material';

import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';

// ----------------------------------------------------------------------

type ExtraFormValuesProps = {
  description: string
}

type FormValuesProps = ExtraFormValuesProps & Activ8PersonAttributesPersonAttributeCreateOrUpdateDto;

type Props = {
  isEdit: boolean;
  currentAttribute?: Activ8PersonAttributesPersonAttributeDto;
};

export default function AttributeNewEditForm({
  isEdit,
  currentAttribute,
}: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useApiResponseHandler();
  const [attributeCreatePost] = appApi.endpoints.personAttributeCreate.useMutation();
  const [attributeUpdatePost] = appApi.endpoints.personAttributeUpdate.useMutation();
  const attributeListQuery = appApi.endpoints.personAttributeGetList.useQuery({
    maxResultCount: 100,
  });

  const NewAttributeSchema =
    Yup.object().shape({
      description: Yup.string().required('Name is required'),
    })

  const defaultValues = useMemo(
    () => ({
      description: currentAttribute?.description || ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAttribute]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAttributeSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (isEdit && currentAttribute) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentAttribute]);

  const onSubmit = async (data: FormValuesProps) => {
    let success = false;
    try {
      if (isEdit) {
        try {
          await attributeUpdatePost({
            id: currentAttribute?.id as string,
            activ8PersonAttributesPersonAttributeCreateOrUpdateDto: {
              description: data.description || ''
            }
          }).unwrap();
          success = true;
        } catch (error) {
          handleError(error);
        }
      } else {
        try {
          await attributeCreatePost({
            activ8PersonAttributesPersonAttributeCreateOrUpdateDto: {
              description: data.description || ''
            }
          }).unwrap();
          success = true;
        } catch (error) {
          handleError(error);
        }
      }

      if (success) {
        await attributeListQuery.refetch();
        navigate(PATH_DASHBOARD.master.person.attributes.list);
        enqueueSnackbar(!isEdit ? 'Created!' : 'Updated!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} alignItems="flex-end">
          <RHFTextField name="description" label="Attribute name" />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!isValid}>
            {!isEdit ? 'Create attribute' : 'Update'}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
