// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, FormControlLabelProps, FormHelperText, Switch } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = Omit<FormControlLabelProps, 'control'>;

interface Props extends IProps {
  name: string;
  helperText?: string;
}

export default function RHFSwitch({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <>
            <Switch {...field} checked={field.value === true} />
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : undefined}
          </>}
        />
      }
      {...other}
    />
  );
}
