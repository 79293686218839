import { format, formatDistanceToNow, getTime } from 'date-fns';
import { Duration } from 'luxon';
import { SystemTimeSpan } from 'src/api/redux/app/appApi';
import * as tz from '@date-fns/tz';

const userTimezone = (tz as any).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

// export function toLocalTime(date: Date | string | number) {
//   const u = utc(date);

//   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's local timezone

//   return new 

// }

export function fDate(date: Date | string | number) {
  return format(date, 'dd MMMM yyyy', { in: userTimezone });
}

export function fDateTime(date: Date | string | number) {
  return format(date, 'dd MMM yyyy p', { in: userTimezone });
}

export function fTimestamp(date: Date | string | number) {
  return getTime(date);
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(date, 'dd/MM/yyyy hh:mm p', { in: userTimezone });
}

export function fToNow(date: Date | string | number, addSuffix: boolean = true) {
  return formatDistanceToNow(date, {
    addSuffix: addSuffix
  });
}

export function isJavaScriptDate(date: any): boolean {
  return Object.prototype.toString.call(date) === '[object Date]';
}

function ticksToTimeSpan(ticks: number): { totalMinutes: number; minutes: number; hours: number } {
  const ticksPerSecond = 10_000_000; // 10 million ticks in one second
  const seconds = ticks / ticksPerSecond;

  const totalMinutes = seconds / 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  return {
    totalMinutes: totalMinutes,
    minutes: minutes,
    hours: hours,
  };
}

function convertMinutesToTimespan(totalMinutes: number): SystemTimeSpan {
  const ticksPerMinute = 60 * 1_000 * 10_000; // 60,000,000 ticks per minute
  return {
    ticks: totalMinutes * ticksPerMinute
  }
}

export function timespanToFriendlyText(timespan: SystemTimeSpan | undefined | null): string | undefined {
  if (!timespan || !timespan.ticks) return undefined;

  // no value
  const span = ticksToTimeSpan(timespan.ticks);

  if (!span.totalMinutes) return undefined;

  let str = '';
  if (span.minutes) str += `${span.minutes} ${span.minutes > 1 ? 'mins' : 'min'}`;

  if (span.hours) str = `${span.hours} ${span.hours > 1 ? 'hrs' : 'hr'} ` + str;

  return str.trim();
}

export function minutesToFriendlyText(minutes: number | undefined | null): string | undefined {
  if (!minutes) return undefined;

  return timespanToFriendlyText(convertMinutesToTimespan(minutes))
}

export function minutesToIsoTimespan(minutes: number | undefined | null): string {
  return Duration.fromObject({ minutes: (minutes || 0) }).toISOTime({ suppressMilliseconds: true }) || '';
}

export function isoTimespanToFriendlyText(timespan: string | undefined | null): string | undefined {
  return minutesToFriendlyText(isoTimespanToTotalMinutes(timespan));
}

export function isoTimespanToTotalMinutes(timespan: string | undefined | null): number {
  return Duration.fromISOTime(timespan || '00:00:00').as('minutes');
}