import { upperFirst } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { RHFSelect, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import RHFRichTextEditor from 'src/components/hook-form/RHFRichTextEditor';

import { Grid, Stack, Typography, useTheme } from '@mui/material';

import { LearningTemplateFormValuesSchema } from '../LearningCourseTemplateWizardForm';

type IProps = {
  isEdit: boolean;
}

const STATUS_OPTIONS = ['Draft', 'Paused', 'Live'];

export default function LearningCourseTemplateWizardFormPublishingStep({ isEdit }: IProps) {
  const { watch, control, trigger } = useFormContext<LearningTemplateFormValuesSchema>();
  const theme = useTheme();

  // const examplePartnerPortalUrl = !control.getFieldState('urlFriendlyName').error?.message && watch('urlFriendlyName') ? appSettings.selfUrl.replace('https://', 'global-cricket-association' + '.') + '/course/' + watch('urlFriendlyName') : undefined;

  return (
    <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }} gap={8} alignItems={'flex-start'} justifyContent='space-between'>
      <Stack gap={2}>
        <Typography variant='h5'>Public details</Typography>
        <Stack gap={4}>
          <RHFTextField name="publicName" label="Public name" helperText="Public name of the course" />

          {/* <RHFTextField onChangeCapture={() => trigger('urlFriendlyName')} disabled={isEdit} name="urlFriendlyName" label="Partner URL friendly name" helperText={<><p>This name makes up the URL used to access the partner portal. Use acronyms to keep the URL friendly name short.</p><strong style={{ color: theme.palette.warning.dark }}>Friendly name cannot be changed after partner creation.</strong></>} 
          endAdornment={<InputAdornment position="end">{<small>{examplePartnerPortalUrl}</small>}</InputAdornment>} /> */}

          <RHFTextField name="publicShortDescription" label="Public short description" helperText="Short summary description of the course aim targeted at prospective participants or public websites" />
          <RHFRichTextEditor name="publicLongRichTextDescription" label="Public long description" helperText="Long description of the course aim targeted at prospective participants or public websites" />
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Typography variant='h5'>Portal details</Typography>
        <Stack gap={4}>
          <RHFSwitch name="isRecommendedForPartnerPublicWebsite" label="Recommend to partners that this course should be viewable on their website" />

          <RHFSelect name="status" label="Course status">
            {STATUS_OPTIONS.map((opt) => ({ id: opt, name: upperFirst(opt || '') })).map((option) => (
              <option
                key={option.id}
                value={option.id || ''}
              >
                {option.name}
              </option>
            ))}
          </RHFSelect>
        </Stack>
      </Stack>
    </Stack>
  )
}