// form
import { Controller, useFormContext } from 'react-hook-form';

// @mui
import { Checkbox, FormControl, FormControlLabel, FormControlLabelProps, FormGroup, Tooltip, useTheme } from '@mui/material';
import { FormHelperText } from '@mui/material';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  feedbackResult?: 'correct' | 'incorrect' | 'missed';
  helperText?: string;
}

export function RHFCheckbox({ name, feedbackResult, helperText, disabled, ...other }: RHFCheckboxProps) {
  const { control } = useFormContext();
  const theme = useTheme();
  const color = feedbackResult === 'correct' ? theme.palette.success.dark : feedbackResult === 'incorrect' ? theme.palette.error.main : (feedbackResult === 'missed' ? theme.palette.warning.main : undefined);
  const icon = feedbackResult === 'correct' ? <Iconify icon='eva:checkmark-circle-2-fill' color={color} /> : feedbackResult === 'incorrect' ? <Iconify icon='eva:close-circle-fill' color={color} /> : (feedbackResult === 'missed' ? <Iconify icon='eva:alert-circle-fill' color={color} /> : undefined);
  // const helperText = feedbackResult === true ? 'Correct!' : feedbackResult === false ? 'Incorrect' : undefined;
  const tooltipText = feedbackResult === 'correct' ? 'You correctly selected this answer' : feedbackResult === 'incorrect' ? 'You incorrectly selected this answer' : (feedbackResult === 'missed' ? 'You missed out on selecting this correct answer' : '');
  const helperTextContent = helperText || (feedbackResult ? (feedbackResult === 'correct' ? 'Correct' : feedbackResult === 'incorrect' ? 'Incorrect' : (feedbackResult === 'missed' ? 'Missed' : '')) : undefined);

  return (
    <FormControl>
      <Tooltip title={tooltipText} placement='bottom-start'>
        <FormControlLabel
          color={color}
          control={
            <Controller
              name={name}
              control={control}
              render={({ field }) =>
                <>
                  <Checkbox {...field} icon={icon} disabled={disabled || Boolean(feedbackResult)} onChange={other.onChange || field.onChange} />
                </>
              }
            />
          }
          {...other}
        />
      </Tooltip>
      {helperTextContent ? <FormHelperText sx={{ color: color, ml: 4, mt: 0, mb: 2 }}>{helperTextContent}</FormHelperText> : undefined}
    </FormControl>
  );
}

// ----------------------------------------------------------------------

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: {
    label: string;
    value: any;
  }[];
}

export function RHFMultiCheckbox({ name, options, ...other }: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
