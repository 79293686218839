import { kebabCase } from 'change-case';
import { useNavigate } from 'react-router';
import { Activ8ParticipantsParticipantDto, appApi } from 'src/api/redux/app/appApi';
import LoadingScreen from 'src/components/LoadingScreen';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { mapIdentityUser } from 'src/utils/mapUser';

import {
  Card, CardHeader, Chip, Divider, Grid, Link, Stack, Tooltip, Typography
} from '@mui/material';

import UserDetailsCard from '../@dashboard/user/cards/UserDetailsCard';
import UserParentDetailsCard from '../@dashboard/user/cards/UserParentDetailsCard';
import permissionKeys from 'src/appPermissionKeys';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import { useEffect } from 'react';

type Props = {
  participant: Activ8ParticipantsParticipantDto;
};

export default function ParticipantOverviewDashboard({ participant }: Props) {
  const permissionChecker = usePermissionChecker();
  const canManageFacilitators = permissionChecker.has(permissionKeys.tenant.program.manageFacilitators);
  const canManageParticipants = permissionChecker.has(permissionKeys.tenant.program.manageParticipants);
  const canManagePartners = permissionChecker.has(permissionKeys.master.partners);
  const [activeProgramsQuery, activeProgramsQueryState] = appApi.endpoints.learningCourseProgramParticipantGetList.useLazyQuery();
  const [pastProgramsQuery, pastProgramsQueryState] = appApi.endpoints.learningCourseProgramParticipantGetList.useLazyQuery();

  useEffect(() => {
    if (canManageFacilitators || canManageParticipants || canManagePartners) {
      activeProgramsQuery({ participantIdentityUserIds: [participant.id as string], includeParticipantDetails: false, includeProgramDetails: true, programStatuses: ['Live'] });
      pastProgramsQuery({ participantIdentityUserIds: [participant.id as string], includeParticipantDetails: false, includeProgramDetails: true, programStatuses: ['Complete', 'Cancelled'] });
    }
  }, [participant])

  const navigate = useNavigate();
  const viewCourseProgram = (programId: string) => {
    navigate(PATH_DASHBOARD.tenant.program.overview(kebabCase(programId)));
  }

  const user = mapIdentityUser(participant);

  const isLoading = activeProgramsQueryState.isLoading || pastProgramsQueryState.isLoading;

  return (
    isLoading ? <LoadingScreen /> :

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack direction='column' spacing={2} >
            <UserDetailsCard user={user} />
            <UserParentDetailsCard user={user} />
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 1.4 }}>
                <CardHeader title={'Live programs'} sx={{ mb: 0, p: 0, px: 1.5, py: 1 }} />
                <Stack sx={{ width: '100%', p: 1, pl: 2 }} spacing={1} divider={<Divider orientation="horizontal" flexItem />}>
                  {
                    activeProgramsQueryState?.data?.items && activeProgramsQueryState?.data?.items.length > 0 ?
                      (activeProgramsQueryState?.data?.items || []).map(p =>
                        <Stack key={p.id} direction='row' justifyContent={'space-between'} alignItems='flex-start'>
                          <Stack key={p.id} direction='row' gap={1} justifyContent={'flex-start'} alignItems='flex-start'>
                            <code>{p.learningCourseProgram?.programReferenceCode}</code>
                            <Link href='#' onClick={() => viewCourseProgram(p.learningCourseProgram?.id as string)}><Typography variant='body2'>{p.learningCourseProgram?.publicName}</Typography></Link>
                          </Stack>
                          <Stack direction='row' gap={2} justifyContent={'flex-end'} alignItems='flex-end'>
                            {p.assignmentStatus === 'Completed' && p.onlineModuleScorePercentage !== undefined && p.onlineModuleScorePercentage !== null ? <Tooltip title='Online module score'><Chip size='small' variant={'filled'} label={`${p.onlineModuleScorePercentage}%`} /></Tooltip> : undefined}
                            {p.assignmentStatus === 'Completed' && (p.onlineModuleScorePercentage === undefined || p.onlineModuleScorePercentage === null) ? <Tooltip title='No online modules for session'><Chip size='small' variant={'filled'} label={'N/A'} /></Tooltip> : undefined}
                            <Tooltip title='Program participant status'><Chip size='small' color={p.assignmentStatus !== 'Completed' ? 'warning' : 'success'} variant={p.assignmentStatus !== 'Completed' ? 'filled' : 'outlined'} label={p.assignmentStatus} /></Tooltip>
                          </Stack>
                        </Stack>
                      ) : <Typography sx={{ textAlign: 'center' }}>No live programs</Typography>
                  }
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ p: 1.4 }}>
                <CardHeader title={'Past programs'} sx={{ mb: 0, p: 0, px: 1.5, py: 1 }} />
                <Stack sx={{ width: '100%', p: 1, pl: 2 }} spacing={1} divider={<Divider orientation="horizontal" flexItem />}>
                  {
                    pastProgramsQueryState?.data?.items && pastProgramsQueryState?.data?.items.length > 0 ?
                      (pastProgramsQueryState?.data?.items || []).map(p =>
                        <Stack key={p.id} direction='row' justifyContent={'space-between'}>
                          <Stack key={p.id} direction='row' gap={1} justifyContent={'flex-start'} alignItems='flex-start'>
                            <code>{p.learningCourseProgram?.programReferenceCode}</code>
                            <Link href='#' onClick={() => viewCourseProgram(p.learningCourseProgram?.id as string)}><Typography variant='body2'>{p.learningCourseProgram?.publicName} ({p.learningCourseProgram?.status})</Typography></Link>
                          </Stack>
                          <Stack direction='row' gap={2} justifyContent={'flex-end'} alignItems='flex-end'>
                            {p.assignmentStatus === 'Completed' && p.onlineModuleScorePercentage !== undefined && p.onlineModuleScorePercentage !== null ? <Tooltip title='Online module score'><Chip size='small' variant={'outlined'} label={`${p.onlineModuleScorePercentage}%`} /></Tooltip> : undefined}
                            {p.assignmentStatus === 'Completed' && (p.onlineModuleScorePercentage === undefined || p.onlineModuleScorePercentage === null) ? <Tooltip title='No online modules for session'><Chip size='small' variant={'outlined'} label={'N/A'} /></Tooltip> : undefined}
                            <Tooltip title='Program participant status'><Chip size='small' color={p.assignmentStatus !== 'Completed' ? 'warning' : 'success'} variant={'outlined'} label={p.assignmentStatus} /></Tooltip>
                          </Stack>
                        </Stack>
                      ) : <Typography sx={{ textAlign: 'center' }}>No past programs</Typography>
                  }
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  )
}