import { m } from 'framer-motion';

import { Box, SxProps } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material/styles';

import appSettings from 'src/appSettings';


// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps;
  preventFullScreen?: boolean;
};



const preventFullScreenRootStyle = styled('div')(({ theme }) => ({
  // right: 0,
  // bottom: 0,
  zIndex: 99999,
  width: '200px',
  height: '200px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto'
  // backgroundColor: theme.palette.background.default,
}))
const rootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));


const logo = <img alt="logo" height="64px" width="64px" src={appSettings.assetsBaseUrl + '/logo/logo_single.svg'} />;

// const RootStyle = rootStyle

export default function LoadingScreen({ preventFullScreen, ...other }: Props) {

  const RootStyle = preventFullScreen ? preventFullScreenRootStyle : rootStyle;

  return (
    <RootStyle {...other}>
      <m.div
        animate={{
          scale: [1, 0.9, 0.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        {logo}
      </m.div>

      <Box
        component={m.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
        sx={{
          width: 100,
          height: 100,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />

      <Box
        component={m.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: 120,
          height: 120,
          borderRadius: '25%',
          position: 'absolute',
          border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
        }}
      />
    </RootStyle>
  );
}
