// form

import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DateInput, DateInputProps } from '@mantine/dates';
import { useTheme } from '@mui/material';

// @mui


// ----------------------------------------------------------------------

type IProps = {
  name: string;
  helperText?: string;
  clearable?: boolean;
};

type Props = IProps & DateInputProps;

export default function RHFDateField({ name, helperText, clearable, ...other }: Props) {
  const { control, watch, setValue } = useFormContext();
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const formVal = field.value === undefined || field.value === null || field.value === '' ? null : typeof field.value === 'string' ? (field.value !== '' ? DateTime.fromISO(field.value).toJSDate() : '') : field.value;
        // console.log('heree formVal', formVal, field.value);

        return (
          <>
            {/* {JSON.stringify(field.value)} */}
            <DateInput
              {...field}
              {...other}
              style={{ ...other.style, width: '218px' }}
              onChange={(value) => {
                // console.log('heree value', value);
                setValue(name, value ? value : null)
              }}
              // style={{ minWidth: '203px' }}
              value={formVal}
              error={error?.message}
              // inputFormat=''
              // closeCalendarOnChange={true}
              description={helperText} radius={theme.shape.borderRadius as number}
              // withinPortal={true}
              clearable={clearable !== undefined ? clearable : true}
            />
          </>
        )
      }}
    />
  );
}
