// form

import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';
import {
  LearningProgramFormValuesSchema
} from 'src/sections/courses/programs/wizard/LearningCourseProgramWizardForm';

import { DatesProvider, TimeInput, TimeInputProps } from '@mantine/dates';
import { useTheme } from '@mui/material';

// @mui


// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label: string;
  helperText?: string;
  clearable?: boolean;
};

type Props = IProps & TimeInputProps;

export default function RHFTimeField({ name, helperText, clearable, ...other }: Props) {
  const { control, getValues, watch, setValue, formState, getFieldState } = useFormContext<LearningProgramFormValuesSchema>();
  const theme = useTheme();
  // const [currentValue, setCurrentValue] = useState<string | null>(getValues(name as any) ? DateTime.fromFormat(getValues(name as any), 'hh:mm').toFormat('HH:mm:ss.0') : null);
  // const [ errorMessage, setErrorMessage ] = useState<string | null>(getFieldState(name as any).error?.message || null);
  // const formValueWatch = watch(name as any);
  // const formValueState = getFieldState(name as any);

  // useEffect(() => {
  //   // console.log('heree formValueWatch', formValueWatch);
  //   // const newVal = formValueWatch ? DateTime.fromISO('2000-01-01T' + formValueWatch).toJSDate() : null
  //   setCurrentValue(formValueWatch);
  // }, [formValueWatch])

  // useEffect(() => {
  //   setErrorMessage(formValueState?.error?.message || null);
  // }, [ formValueState ])

  return (
    <Controller
      name={name as any}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            {/* {JSON.stringify(field.value)} */}
            {/* {JSON.stringify(error)} */}
            <TimeInput
              // key={formState.submitCount}
              {...field}
              style={{ minWidth: '117px' }}
              {...other}
              // defaultValue={getValues(name as any) ? DateTime.fromFormat(getValues(name as any), 'hh:mm').toFormat('HH:mm:ss.0') : undefined}
              // value={currentValue?.toString()}
              // onChange={(e: any) => {
              //   const dateVal = e.target.value ? DateTime.fromFormat(e.target.value, 'hh:mm') : null;
              //   const formatted = dateVal ? dateVal.toFormat('HH:mm:ss.0') : null;
              //   console.log('heree TimeInput change', e.target.value, formatted);

              //   // setCurrentValue(formatted || null);
              //   // field.onChange(formatted);
              //   setValue(name as any, formatted, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
              // }}
              error={error?.message}
              description={helperText}
              radius={theme.shape.borderRadius as number}
              required={!clearable}
            // clearable={clearable !== undefined ? clearable : true}
            // format='12'
            />
          </>
        )
      }}
    />
  );
}
