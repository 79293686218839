import { User } from "oidc-client-ts";
import appSettings from "src/appSettings";

function getLoggedInUser(): User | null {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${appSettings.oAuthAuthority}:${appSettings.oAuthClientId}`)
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export default getLoggedInUser;