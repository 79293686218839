import 'react-phone-number-input/style.css';

import { useFormContext } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import RHFAddressSelect from 'src/components/hook-form/RHFAddressSelect';
import RHFPhoneField from 'src/components/hook-form/RHFPhoneField';
import RHFTimezoneSelect from 'src/components/hook-form/RHFTimezoneSelect';

import { Grid, Stack, Typography } from '@mui/material';

import { LearningProgramFormValuesSchema } from '../LearningCourseProgramWizardForm';

export default function LearningCourseProgramWizardFormDetailsStep() {
  const context = useFormContext<LearningProgramFormValuesSchema>();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Stack rowGap={2}>
          <Typography variant='h5'>General details</Typography>
          <Stack rowGap={4}>
            <RHFTextField name="internalName" label="Internal name" helperText="Title of the program that is viewable by course co-ordinators and facilitators only" disableAutoComplete />
            <RHFAddressSelect name="conductionAddress" label="Address" helperText="Address where the program will be conducted" />
            <RHFTimezoneSelect name="conductionIanaTimezone" label="Timezone" helperText="Timezone where the program will be conducted" />
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Stack rowGap={2}>
          <Typography variant='h5'>Support contact</Typography>
          <Stack rowGap={4}>
            <RHFTextField name="supportContactEmail" label="Email" disableAutoComplete />
            <RHFPhoneField name="supportContactPhone" label="Phone" placeholder='International format. Eg AUS mobile: 61 411 222 333' />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}