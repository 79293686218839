import { useSnackbar } from 'notistack';
import { useState } from 'react';
// hooks
import { useAuth } from 'react-oidc-context';
import { Link as RouterLink } from 'react-router-dom';
// components
import useCurrentUser from 'src/hooks/useCurrentUser';
import useImpersonation, { onFreshLogin } from 'src/utils/impersonation';

// @mui
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { IconButtonAnimate } from '../../../components/animate';
import MenuPopover from '../../../components/MenuPopover';
import MyAvatar from '../../../components/MyAvatar';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Account',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { removeUser, signoutRedirect, revokeTokens, user } = useAuth();
  const currentUser = useCurrentUser();

  const isMountedRef = useIsMountedRef();
  const { resetToPreviousUser, isImpersonatingTenant, isImpersonatingUser } = useImpersonation();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      localStorage.setItem('is_logout_pending', '1');
      onFreshLogin();
      await removeUser();
      await revokeTokens();
      localStorage.setItem('is_logout_pending', '');
      await signoutRedirect({ id_token_hint: user?.id_token });
      // navigate(PATH_DASHBOARD.root, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentUser?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {currentUser?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {isImpersonatingTenant || isImpersonatingUser ?
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={() => resetToPreviousUser()} sx={{ m: 1 }}>
              <strong>Back to {isImpersonatingUser ? 'partner portal' : 'master portal'}</strong>
            </MenuItem>
          </>
          :
          <></>
        }

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
