import * as mime from 'mime-types';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import BlockContent from './BlockContent';
import MultiFilePreview from './MultiFilePreview';
import { UploadMultiFileProps } from './type';

// @mui


// type

//






// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: 0,
  // borderRadius: theme.shape.borderRadius,
  // backgroundColor: theme.palette.background.neutral,
  // border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

// supported types come from https://cookpete.com/react-player/
export const videoMimeTypes = [
  'video/mpeg', 'video/mp4', 'application/mp4', // mp4
  'video/ogg', 'application/ogg', // ogv
  'video/webm', // webm
  'video/MP2T', // m3u8
  'application/dash+xml', // mpd 
  'video/quicktime', // mov
  'video/3gpp' // 3gp  
  // not supported by mime-types npm package: 'vnd.apple.mpegURL', 'application/x-mpegURL'
]
export const audioMimeTypes = [
  'audio/mp4', // mp4
  'audio/mpeg', 'audio/mp3', // mp3
  // not supported by mime-types npm package: 'audio/aac'
]

export const imageMimeTypes = [
  'image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp'
]

const mediaMimeTypes = [...videoMimeTypes, ...audioMimeTypes, ...imageMimeTypes];

const dropZoneMediaAcceptProp: any = {};
for (const mimeType of mediaMimeTypes) {
  dropZoneMediaAcceptProp[mimeType] = mime.extensions[mimeType.toLowerCase()].map(e => '.' + e)
}

export default function UploadMultiFile({
  error,
  files,
  onUpload,
  onDownload,
  onPreview,
  helperText,
  onUpdateMetadata,
  onDelete,
  hideDropzone,
  sx,
  autoplay,
  restrictedToMedia,
  singleFile,
  direction,
  preload,
  prepareReadUrl,
  ...other
}: UploadMultiFileProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
    accept: restrictedToMedia ? dropZoneMediaAcceptProp : undefined,
    maxFiles: singleFile ? 1 : undefined,
    multiple: !singleFile,
    onDropRejected: ((rejections) => {
      // console.log('heree rejections', rejections)
      rejections.forEach(r => {
        enqueueSnackbar(restrictedToMedia ? `File ${r.file.name} is not a media file` : 'File type or size rejected', {
          variant: 'error',
          preventDuplicate: true
        })
      })
    })
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {/* <code style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(files)}</code> */}
      {!hideDropzone ?
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',
            }),
          }}
        >
          <input {...getInputProps()} />

          <BlockContent restrictedToMedia={restrictedToMedia} singleFile={singleFile} />
        </DropZoneStyle> : undefined}

      {/* {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />} */}

      <MultiFilePreview files={files} autoplay={autoplay} onDelete={onDelete} onUpdateMetadata={onUpdateMetadata} onPreview={onPreview} prepareReadUrl={prepareReadUrl} onDownload={onDownload} direction={direction} restrictedToMedia={restrictedToMedia} preload={preload} />

      {/* {files.length > 0 && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
          <Button size="small" variant="contained" onClick={onUpload}>
            Upload files
          </Button>
        </Stack>
      )} */}

      {helperText && helperText}
    </Box>
  );
}
