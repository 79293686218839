import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { appApi } from 'src/api/redux/app/appApi';
import LoadingScreen from 'src/components/LoadingScreen';
import { stringifyNumber } from 'src/utils/formatNumber';
import uuidv4 from 'src/utils/uuidv4';

import {
  Alert, Button, Chip, Grid, InputAdornment, ListItemText, MenuItem, Stack, Typography
} from '@mui/material';

import { LearningTemplateFormValuesSchema } from '../../LearningCourseTemplateWizardForm';
import { CourseContentChapterAccordianItem } from './CourseContentChapterAccordianItem';

type IProps = {
  courseTemplateId: string;
  isEdit: boolean;
}

// type LearningTemplateSchemaType = InferType<typeof LearningTemplateSchema>;

export function LearningCourseTemplateWizardFormDeliveryStep({ courseTemplateId, isEdit }: IProps) {
  const { control, getValues, formState } = useFormContext<LearningTemplateFormValuesSchema>();

  const countOfLiveProgramsUsingCourseTemplateQuery = isEdit ? appApi.endpoints.learningCourseTemplateGetCountOfLiveProgramsUsingCourseTemplate.useQuery({ courseTemplateId: courseTemplateId }, { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true }) : undefined;

  const [expandedChapterId, setExpandedChapterId] = useState<number | null>(null);
  const [autoExpandLastChapterId, setAutoExpandLastChapterId] = useState<boolean>(false);
  const confirm = useConfirm();
  const { fields, append, remove } = useFieldArray<LearningTemplateFormValuesSchema>({
    control,
    name: 'chapters',
    // mode: 'onBlur',
    // shouldUnregister: false
  });

  const onDisplayToggle = (index: number, forceVisibility?: boolean) => {
    if ((forceVisibility === false || (expandedChapterId === index && forceVisibility !== true))) {
      setExpandedChapterId(null); // force hide
    }
    else {
      setExpandedChapterId(index);
    }
  }

  const onDeleteChapter = async (index: number) => {
    const toDelete = (getValues('chapters') || [])[index];
    const touchedFieldsOnChapter = !formState.touchedFields.chapters || !formState.touchedFields.chapters[index] ? {} as any : formState.touchedFields.chapters[index];
    const deleteChapterFunc = () => {
      remove(index);
    }
    // console.log('heree touchedFieldsOnChapter', touchedFieldsOnChapter);

    if (!touchedFieldsOnChapter.name && !touchedFieldsOnChapter.chapters) {
      deleteChapterFunc();
    }
    else {
      confirm({ title: toDelete?.name || undefined, description: `Are you sure you want to delete the chapter including its content, sessions and quizzes?` })
        .then(deleteChapterFunc)
        .catch(() => { });;
    }
  }

  const addChapter = async () => {
    const newChapterId = uuidv4();
    await setAutoExpandLastChapterId(true);

    append({
      id: newChapterId,
      name: 'New chapter ' + (fields.length + 1),
      duration: '00:00:00',
      sessions: [
        {
          id: uuidv4(),
          name: stringifyNumber(1, true) + ' session',
          duration: '00:00:00',
          facilitatorNotes: '',
          facilitatorResources: [],
          content: []
        }
      ]
    });
  }
  useEffect(() => {
    if (autoExpandLastChapterId) {
      setExpandedChapterId(fields.length - 1);
      setAutoExpandLastChapterId(false);
    }
  }, [fields])
  const isLoading = false;

  const liveProgramsUsingTheCourseCount = countOfLiveProgramsUsingCourseTemplateQuery && countOfLiveProgramsUsingCourseTemplateQuery?.data && countOfLiveProgramsUsingCourseTemplateQuery.data > 0 ? countOfLiveProgramsUsingCourseTemplateQuery.data : undefined;

  return (
    isLoading ?
      <LoadingScreen />
      :
      <>
        <Stack rowGap={2}>
          {liveProgramsUsingTheCourseCount ?
            <Alert severity='warning'>Course content is locked for editing as there is currently {liveProgramsUsingTheCourseCount} program{(liveProgramsUsingTheCourseCount > 1 ? "s" : "")} using this template.<br /><br />Clone the template if you wish to make changes to chapters or sessions.</Alert>
            : undefined
          }
          {
            !fields || fields.length === 0 ?
              // <Alert severity="info">No chapters exist. <Button variant='text' onClick={() => addChapter()}>Add chapter</Button> to get started</Alert>
              <></>
              :
              <div>
                {(fields || []).map((field, index) => (
                  <CourseContentChapterAccordianItem
                    courseTemplateId={courseTemplateId}
                    key={field.id}
                    control={control}
                    chapterIndex={index}
                    isExpanded={expandedChapterId === index}
                    onDisplayToggle={onDisplayToggle}
                    onDeleteChapter={onDeleteChapter}
                  />
                ))}
              </div>
          }

          <Stack direction='row' alignItems='center' justifyContent={'center'}>
            {/* <Typography variant='h5'>Chapters</Typography> */}
            <Button variant={fields.length === 0 ? 'contained' : 'outlined'} color={'primary'} onClick={() => addChapter()}>Add chapter</Button>
          </Stack>
        </Stack>
      </>
  )
}