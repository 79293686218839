import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { appApi } from 'src/api/redux/app/appApi';
import { accountApi } from '../api/redux/account/accountApi'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  [accountApi.reducerPath]: accountApi.reducer,
  [appApi.reducerPath]: appApi.reducer,
});

export { rootPersistConfig, rootReducer };
