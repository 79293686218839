// form

import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MantineProvider } from '@mantine/core';
import { Link, RichTextEditor } from '@mantine/tiptap';
// import styled from '@emotion/styled';
// import { MantineProvider } from '@mantine/core';
// import { RichTextEditor } from '@mantine/rte';
// import { ToolbarControl } from '@mantine/rte/lib/components/Toolbar/controls';
import { FormControl, FormHelperText, FormLabel, useTheme } from '@mui/material';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

type IProps = {
  name: string;
  label?: string;
  helperText?: string;
  allowImages?: boolean;
  allowVideos?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
};

type Props = IProps;

// const StyledRichTextEditor = styled(RichTextEditor)`
//     & .mantine-RichTextEditor-root, .mantine-RichTextEditor-toolbar, .mantine, & .mantine-RichTextEditor-toolbarInner {
//         border-width: '5px'!important;
//         border-radius: '20px';
//     }
// `;

// const StyledRichTextEditor = styled(RichTextEditor)`
//     & .ql-container.ql-disabled {
//         border-width: 0!important;
//     }
// `;

export default function RHFRichTextEditor({ name, label, helperText, allowImages, allowVideos, readOnly, autoFocus, onBlur, ...other }: Props) {
  const formContext = useFormContext();
  const [valueState, setValueState] = useState(formContext.getValues(name));
  const materialUiTheme = useTheme();
  const [firstLoad, setFirstLoad] = useState(true);

  const handleChange = (value: string) => {
    setValueState(value.trim() === '<p><br></p>' || value.trim() === '<p></p>' ? '' : value);
    // formContext.setValue(name, value.trim() === '<p><br></p>' ? '' : value, { shouldTouch: false, shouldValidate: false, shouldDirty: false})
    // handleBlur();
  }
  const myRef = useRef<HTMLDivElement>(null)

  const handleBlur = () => {
    formContext.setValue(name, valueState, { shouldTouch: true, shouldValidate: true, shouldDirty: true });
    // formContext.trigger(name);
    onBlur && onBlur();
    // console.log('heree valueState', valueState)
  }

  // const controls: Extensions = [
  //   ['bold', 'italic', 'underline'],
  //   ['clean'],
  //   ['h1', 'h2', 'h3', 'h4'],
  // ];

  // if (allowImages && allowVideos) {
  //   controls.push(['image', 'video']);
  // }
  // else if (allowImages) {
  //   controls.push(['image']);
  // }
  // else if (allowVideos) {
  //   controls.push(['video']);
  // }
  // controls.push(['unorderedList', 'orderedList', 'link']);
  //   controls.push(['alignLeft', 'alignCenter', 'alignRight']);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
    ],
    editable: !readOnly,
    autofocus: autoFocus === undefined ? false : autoFocus,
    onBlur: () => handleBlur(),
    onUpdate: ({ editor }) => handleChange(editor.getHTML()),
    content: valueState,
    injectCSS: false,
  });

  useEffect(() => {
    if (editor?.isEditable === !readOnly || firstLoad) return;

    editor?.setEditable(!readOnly);
    if (!readOnly) {
      editor?.chain().focus().run();
      // setTimeout(() => {
      myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      // }, 400)
    }
  }, [readOnly])

  useEffect(() => {
    setFirstLoad(false);
  }, [])

  return (
    <Controller
      name={name}
      control={formContext.control}
      render={({ field, fieldState: { error } }) => {
        const helperTextComponent = error?.message && helperText ?
          <>
            <span style={{ display: 'block' }}>{helperText}</span>
            <span style={{ display: 'block' }}>{error?.message}</span>
          </>
          : (helperText ? helperText :
            (error?.message ? error?.message : undefined)
          )
        const isError = error?.message ? true : false;

        return (
          <FormControl fullWidth>
            <div id={`rte-editor-${name}`} ref={myRef}></div>
            <FormLabel sx={{ mb: 0.7 }}>{label}</FormLabel>

            <MantineProvider

              theme={{
                components: {
                  RichTextEditor: {
                    styles: () => ({
                      // root: { borderTopLeftRadius: materialUiTheme.shape.borderRadius, borderTopRightRadius: materialUiTheme.shape.borderRadius, borderWidth: isError ? '1px' : '0px', borderColor: isError ? materialUiTheme.palette.error.main : 'transparent' },
                      root: {
                        borderWidth: '1px',
                        borderColor: isError ? materialUiTheme.palette.error.main : materialUiTheme.palette.grey[300],
                        borderStyle: 'solid',
                        borderRadius: materialUiTheme.shape.borderRadius,
                      },
                      toolbar: {
                        // padding: materialUiTheme.spacing(1.3),
                        // borderWidth: '1px',
                        display: readOnly ? 'none' : undefined,
                        // borderStyle: 'solid',                        
                        border: 'none',
                        borderTopLeftRadius: materialUiTheme.shape.borderRadius,
                        borderTopRightRadius: materialUiTheme.shape.borderRadius,
                        // borderBottomWidth: '0px',
                        // borderColor: isError ? materialUiTheme.palette.error.main : materialUiTheme.palette.grey[300],
                        paddingBottom: '0px'
                      },
                      typographyStylesProvider: {
                        border: 'none',
                        marginBottom: '0px',
                        paddingLeft: '0px'
                      },
                      // controlsGroup: { marginRight: materialUiTheme.spacing(3) },
                      // control: { marginRight: materialUiTheme.spacing(0.4) },
                      // quillcontainer: { borderWidth: '1px', borderTopLeftRadius: materialUiTheme.shape.borderRadius, borderTopRightRadius: materialUiTheme.shape.borderRadius, borderColor: isError ? materialUiTheme.palette.error.main : undefined },
                      content: {
                        // padding: materialUiTheme.spacing(1.3),
                        // borderWidth: '1px',
                        // borderTopWidth: '0px',
                        // borderStyle: 'solid',
                        border: 'none',
                        borderBottomLeftRadius: materialUiTheme.shape.borderRadius,
                        borderBottomRightRadius: materialUiTheme.shape.borderRadius,
                        // borderColor: readOnly ? 'transparent' : (isError ? materialUiTheme.palette.error.main : materialUiTheme.palette.grey[300])
                      },
                      // quill: {
                      //     container: { borderWidth: '1px', borderTopLeftRadius: materialUiTheme.shape.borderRadius, borderTopRightRadius: materialUiTheme.shape.borderRadius, borderColor: isError ? materialUiTheme.palette.error.main : undefined },
                      // },
                      // ql: {
                      //     container: { borderWidth: '1px', borderTopLeftRadius: materialUiTheme.shape.borderRadius, borderTopRightRadius: materialUiTheme.shape.borderRadius, borderColor: isError ? materialUiTheme.palette.error.main : undefined },
                      //     editor: { borderWidth: '1px', borderTopLeftRadius: materialUiTheme.shape.borderRadius, borderTopRightRadius: materialUiTheme.shape.borderRadius, borderColor: isError ? materialUiTheme.palette.error.main : undefined },
                      // }
                    }),
                  }
                }
              }}
            >
              <RichTextEditor editor={editor}>
                <RichTextEditor.Toolbar sticky stickyOffset={60} display={readOnly ? 'none' : undefined}>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.ClearFormatting />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                  </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                {/* {editor && (
                  <BubbleMenu editor={editor}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Link />
                    </RichTextEditor.ControlsGroup>
                  </BubbleMenu>
                )} */}

                <RichTextEditor.Content />
              </RichTextEditor>
            </MantineProvider>

            {/* <StyledRichTextEditor
                // itemID={'editor' + name}
                // id={`editor-${name}`}
                itemID={`editor-${name}`}
                readOnly={readOnly}
                value={valueState}
                onBlurCapture={() => handleBlur()}
                onChange={(value) => handleChange(value)}
                // onKeyUp={(e) => { e.preventDefault(); e.stopPropagation(); }}
                // onKeyDown={(e) => { e.preventDefault(); e.stopPropagation(); }}
                // onKeyUpCapture={(e) => { e.preventDefault(); e.stopPropagation(); }}
                onKeyDownCapture={(e) => { e.stopPropagation(); }}
                
                controls={controls}
                autoCorrect={'on'}
                sticky={false}
                radius={materialUiTheme.shape.borderRadius as number}
              /> */}
            {
              error?.message || helperText ?
                <FormHelperText error={isError}>{helperTextComponent}</FormHelperText>
                : undefined
            }
          </FormControl>
        )
      }}
    />
  );
}
