import parse from 'autosuggest-highlight/parse';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import appSettings from 'src/appSettings';

import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}
const autocompleteService = { current: null };
const placesService = { current: null };

type IProps = {
  name: string;
  postcodeFieldName?: string;
  countryCodeFieldName?: string;
};

type Props = IProps & TextFieldProps;

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  place_id?: string;
  structured_formatting: StructuredFormatting;
}

export default function RHFAddressSelect({ name, postcodeFieldName, countryCodeFieldName, label, helperText, ...other }: Props) {
  const methods = useFormContext();
  //   const methods = useForm();

  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [resetCount, setResetCount] = React.useState<number>(0);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${appSettings.googleMapsApiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        300,
      ),
    [],
  );

  React.useEffect(() => {
    // console.log('heree useEffect', loaded, placesService.current, (window as any).google, postcodeFieldName, countryCodeFieldName)
    setTimeout(() => {
      if (!placesService.current && (window as any).google) {
        placesService.current = new (window as any).google.maps.places.PlacesService(document.createElement("div"));
      }
    }, 1500)
  }, [loaded]);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const getPlaceDetails = (place: PlaceType | null) => {
    // console.log('heree place', place, placesService.current);
    if (!placesService.current || !place || !place.place_id) {
      if (postcodeFieldName) methods.setValue(postcodeFieldName, '', { shouldTouch: true, shouldDirty: true, shouldValidate: true });
      if (countryCodeFieldName) methods.setValue(countryCodeFieldName, '', { shouldTouch: true, shouldDirty: true, shouldValidate: true });
      return;
    }

    (placesService.current as any).getDetails({
      placeId: place.place_id,
      fields: ["address_component"]
    }, (matched: any) => {

      // console.log('heree place matched', matched);
      if (postcodeFieldName) {
        var postCodeComponent = matched.address_components.find(function (a: any) { return a.types.indexOf('postal_code') > -1 });
        if (postCodeComponent) {
          // console.log('heree onChange postCodeComponent', postCodeComponent);
          methods.setValue(postcodeFieldName, postCodeComponent.long_name, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
        }
      }
      if (countryCodeFieldName) {
        var countryCodeComponent = matched.address_components.find(function (a: any) { return a.types.indexOf('country') > -1 });
        if (countryCodeComponent) {
          // console.log('heree onChange countryCodeComponent', countryCodeComponent);
          methods.setValue(countryCodeFieldName, countryCodeComponent.short_name, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
        }
      }
    })
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          id={`address-${field.name}`}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          fullWidth
          includeInputInList
          filterSelectedOptions
          freeSolo
          value={value?.description || field.value}
          //   isOptionEqualToValue={(op, val) => op.description === val}
          onChange={(event: any, newValue: PlaceType | null) => {
            // setOptions(newValue ? [newValue, ...options] : options);
            // setValue(newValue);
            // methods.setValue(name, newValue?.description, { shouldTouch: true, shouldDirty: true, shouldValidate: true });

            // console.log('heree5', field.name, newValue)
            if (newValue) {
              getPlaceDetails(newValue);
            }



            // field.onChange(newValue?.description)
          }}
          onInputChange={(event, newInputValue: string, reason) => {
            if (reason === 'clear') {
              field.onChange('');
              methods.setValue(name, '', { shouldTouch: true, shouldDirty: true, shouldValidate: true });

              // setValue(null);
              setResetCount(resetCount + 1);
              setInputValue('');
            } else {
              // methods.setValue(name, newInputValue, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
              field.onChange(newInputValue);
              setInputValue(newInputValue);
            }
          }}
          //   onReset={() => field.onChange(newValue?.description)}
          renderInput={(params) => {
            const helperTextComponent = error?.message && helperText ?
              <>
                <span style={{ display: 'block' }}>{error?.message}</span>
                <span style={{ display: 'block' }}>{helperText}</span>
              </>
              : (helperText ? helperText :
                (error?.message ? error?.message : undefined)
              )

            return (<TextField {...params} inputProps={{ ...params.inputProps, autoComplete: 'no' }} label={label} fullWidth helperText={helperTextComponent} error={!!error} />)
            // return (<TextField {...params} key={resetCount} inputProps={{ ...params.inputProps, autoComplete: 'no' }} label={label} fullWidth helperText={helperTextComponent} error={!!error} />)
          }}
          renderOption={(props, option: any) => {
            const matches =
              option.structured_formatting.main_text_matched_substrings || [];
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  {/* <Grid item sx={{ display: 'flex', width: 44 }}>
                  <Location sx={{ color: 'text.secondary' }} />
                </Grid> */}
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
}
