// sections
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { appApi } from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import {
    LearningCourseTemplateActions
} from 'src/sections/courses/templates/LearningCourseTemplateActions';
import LearningCourseTemplateOverviewDashboard from 'src/sections/courses/templates/LearningCourseTemplateOverviewDashboard';

// @mui
import { Button, Container, Menu } from '@mui/material';

// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
// hooks
import useSettings from '../../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function LearningCourseTemplateOverview() {
  const { themeStretch } = useSettings();
  const { id = '' } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { handleError } = useApiResponseHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLearningCourseTemplateQuery = appApi.endpoints.learningCourseTemplateGet.useQuery({
    id: id
  },
  {
    refetchOnMountOrArgChange: true
  });

  const currentLearningCourseTemplate = getLearningCourseTemplateQuery && !getLearningCourseTemplateQuery.isLoading && getLearningCourseTemplateQuery.data ? getLearningCourseTemplateQuery.data : undefined;

  const templateName = currentLearningCourseTemplate?.internalName || '';

  const onDataRefetch = () => {
    getLearningCourseTemplateQuery.refetch();
  }

  const handleDownloadContent = () => {
  }
  
  return (
    <Page title={templateName}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={templateName}
          links={[
            { name: 'Courses', href: PATH_DASHBOARD.master.courseTemplate.list },
            { name: templateName },
          ]}
          action={
            <>
              <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  variant="outlined"
                  sx={{ mr: 3 }}
                >
                  Actions
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                 {
                  <LearningCourseTemplateActions onDataRefetch={onDataRefetch} row={currentLearningCourseTemplate as any} handleCloseMenu={handleClose} handleDownloadContent={handleDownloadContent} />
                 }
                </Menu>
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.master.courseTemplate.edit(id)}
                startIcon={<Iconify icon={'eva:edit-fill'} />}
              >
                Edit
              </Button>
            </>
          }
        />        

        {
          (!getLearningCourseTemplateQuery?.isLoading && currentLearningCourseTemplate) ?        
        <LearningCourseTemplateOverviewDashboard template={currentLearningCourseTemplate} />
        : undefined }
      </Container>
    </Page>
  );
}
