const appNotificationTypeKeys = {
    tenant: {
        partner: {
            profile: {
                incomplete: 'Tenant.Partner.Profile.Incomplete'
            }
        },
        program : {
            onlineModulesIncomplete: "Tenant.Program.OnlineModules.Incomplete"
        }
    }
}

export default appNotificationTypeKeys;