import { Control, useFieldArray, useFormContext } from 'react-hook-form';
import {
    Activ8CoursesContentLearningCourseContent, Activ8CoursesTemplatesLearningCourseTemplateDto,
    appApi
} from 'src/api/redux/app/appApi';
import LoadingScreen from 'src/components/LoadingScreen';

import { Grid } from '@mui/material';

import {
    LearningCourseProgramEditableChapterItem
} from '../../LearningCourseProgramEditableChapterItem';
import { LearningProgramFormValuesSchema } from '../LearningCourseProgramWizardForm';

export type ILearningCourseProgramWizardFormDeliveryStepProps = {
  courseProgramId: string;
  courseTemplate: Activ8CoursesTemplatesLearningCourseTemplateDto;
  courseTemplateContent: Activ8CoursesContentLearningCourseContent;  
  control: Control<LearningProgramFormValuesSchema>;
}

// type LearningProgramSchemaType = InferType<typeof LearningProgramSchema>;

export default function LearningCourseProgramWizardFormDeliveryStep({ courseProgramId, courseTemplate, courseTemplateContent, control }: ILearningCourseProgramWizardFormDeliveryStepProps) {    
  const getCourseTemplateContent = appApi.endpoints.learningCourseTemplateGetTemplateContent.useQuery({ courseTemplateId: courseTemplate.id as string });
  const { fields, append, remove } = useFieldArray({
    control,
    name: `chapters`,    
    // shouldUnregister: false,
});

  const {isLoading} = getCourseTemplateContent;

  return (
      isLoading ?
        <LoadingScreen /> 
        :       
        <>
          <Grid container spacing={4}>      
              {
                (fields).map((chapter, index) => 
                  <LearningCourseProgramEditableChapterItem key={chapter.id} chapter={chapter} courseTemplate={courseTemplate} courseProgramId={courseProgramId} courseTemplateContent={courseTemplateContent} chapterIndex={index} control={control} />
                )
              }
          </Grid>
        </>
    )
}