// form
import { useFormContext } from 'react-hook-form';
// @mui
import { ListItemText, MenuItem, SelectProps } from '@mui/material';
import { appApi } from 'src/api/redux/app/appApi';
import RHFMultiSelect from './RHFMultiSelect';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  helperText?: React.ReactNode;
};

type Props = IProps & SelectProps;

export default function RHFPersonAccreditationSelect({ name, label, helperText, ...other }: Props) {
  const accreditationListQuery = appApi.endpoints.personAccreditationGetList.useQuery({ maxResultCount: 100 });
  const { control } = useFormContext();

  return (
    <RHFMultiSelect 
      disabled={accreditationListQuery.isLoading || accreditationListQuery.isError}
      name={name} 
      label={label || "Person accreditations"}
      helperText={helperText}
      getSelectedOptionText={(selected) => (accreditationListQuery?.data?.items || []).find(a => a.id === selected)?.description || selected}
    >
        {(accreditationListQuery?.data?.items || []).map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
          >
            <ListItemText primary={option.description} />
          </MenuItem>
        ))}
    </RHFMultiSelect>
  );
}
