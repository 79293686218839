import { template, upperFirst } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import {
  Activ8CoursesTemplatesLearningCourseTemplateDto, LearningCourseTemplateGetApiResponse
} from 'src/api/redux/app/appApi';
import { RHFSelect, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import RHFRichTextEditor from 'src/components/hook-form/RHFRichTextEditor';
import {
  LearningTemplateFormValuesSchema
} from 'src/sections/courses/templates/wizard/LearningCourseTemplateWizardForm';

import { Alert, Grid, Stack, Typography, useTheme } from '@mui/material';

import { LearningProgramFormValuesSchema } from '../LearningCourseProgramWizardForm';

type IProps = {
  isEdit: boolean;
  courseTemplate: Activ8CoursesTemplatesLearningCourseTemplateDto;
}

const STATUS_OPTIONS = ['Draft', 'Paused', 'Live'];

export default function LearningCourseTemplateWizardFormPublishingStep({ isEdit, courseTemplate }: IProps) {
  const { watch } = useFormContext<LearningProgramFormValuesSchema>();

  const isListedOnPartnerPublicWebsiteWatch = watch('isListedOnPartnerPublicWebsite');
  const statusWatch = watch('status');

  // const examplePartnerPortalUrl = !control.getFieldState('urlFriendlyName').error?.message && watch('urlFriendlyName') ? appSettings.selfUrl.replace('https://', 'global-cricket-association' + '.') + '/course/' + watch('urlFriendlyName') : undefined;

  return (
    <Stack direction={{ xs: 'column', md: 'column', lg: 'row' }} gap={8} alignItems={'flex-start'} justifyContent='space-between'>
      <Stack gap={2}>
        <Typography variant='h5'>Public details</Typography>
        <Stack gap={4}>
          <RHFTextField name="publicName" label="Public name" helperText="Public name of the program" />

          {/* <RHFTextField onChangeCapture={() => trigger('urlFriendlyName')} disabled={isEdit} name="urlFriendlyName" label="Partner URL friendly name" helperText={<><p>This name makes up the URL used to access the partner portal. Use acronyms to keep the URL friendly name short.</p><strong style={{ color: theme.palette.warning.dark }}>Friendly name cannot be changed after partner creation.</strong></>} 
          endAdornment={<InputAdornment position="end">{<small>{examplePartnerPortalUrl}</small>}</InputAdornment>} /> */}

          <RHFTextField name="publicShortDescription" label="Public short description" helperText="Short summary description of the program aim targeted at prospective participants or public websites" />
          <RHFRichTextEditor name="publicLongRichTextDescription" label="Public long description" helperText="Long description of the program aim targeted at prospective participants or public websites" />
        </Stack>
      </Stack>

      <Stack gap={2}>
        <Typography variant='h5'>Listing details</Typography>
        <Stack gap={6}>
          {/* {
            statusWatch != 'Cancelled' && statusWatch != 'Live' && statusWatch != 'Complete' ?             
            <RHFSelect name="status" label="Program status">
              {STATUS_OPTIONS.map((opt) => ({ id: opt, name: upperFirst(opt || '') })).map((option) => (
                <option
                  key={option.id}
                  value={option.id || ''}
                >
                  {option.name}
                </option>
              ))}
            </RHFSelect>
            : undefined
          } */}


          <Stack gap={2}>
            <RHFSwitch name="isListedOnPartnerPublicWebsite" label={<><Typography variant='body1' mb={2}>This course is viewable on your public website</Typography><Typography variant='caption'>If checked, this program will be published to public websites via one of the integrations via the Wordpress plugin or other API integrations</Typography></>} helperText="" />
            <Alert sx={{ ml: 6 }} severity={isListedOnPartnerPublicWebsiteWatch !== courseTemplate.isRecommendedForPartnerPublicWebsite ? 'warning' : 'success'}>
              {courseTemplate.isRecommendedForPartnerPublicWebsite ? 'This course is recommended to be viewable on your public website' : <p>This course <strong>is not recommended</strong> to be viewable on your public website</p>}
            </Alert>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}