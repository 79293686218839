import { formatPhoneNumber } from 'react-phone-number-input';
import {
    Activ8CoursesProgramsCoursesLearningCourseProgramDto,
    Activ8CoursesTemplatesLearningCourseTemplateDto, appApi
} from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import { fDateTime } from 'src/utils/formatTime';

import {
    Button, Card, CardActions, CardContent, Chip, Divider, Stack, Tooltip, Typography, useTheme
} from '@mui/material';

type Props = {
    template: Activ8CoursesTemplatesLearningCourseTemplateDto;
    program: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
    actions?: React.ReactElement;
};

export default function LearningCourseProgramDetailsCard({ template, program, actions }: Props) {
    const appUserContext = useAppUserContext();
    const theme = useTheme();
    const personAttributeGetListQuery = appApi.endpoints.personAttributeGetList.useQuery({ maxResultCount: 100 });
    const personAccreditationGetListQuery = appApi.endpoints.personAccreditationGetList.useQuery({ maxResultCount: 100 });
    const isFacilitatorOfProgram = program.facilitators && Boolean(program.facilitators.find(e => e.facilitatorIdentityUserId === appUserContext?.currentUser?.id));
    const isLoading = personAttributeGetListQuery.isLoading || personAccreditationGetListQuery.isLoading;
    const permissionChecker = usePermissionChecker();
    const canCreateEditPrograms = permissionChecker.has(permissionKeys.tenant.program.createUpdateDelete);

    // const assignedAttributes = personAttributeGetListQuery.isLoading ? [] : template.attributePreRequisites?.items?.map(e => { return { description: e.personAttribute?.description, id: e.personAttribute?.id, type: e.type}}) || [];
    // const assignedAccreditations = personAccreditationGetListQuery.isLoading ? [] : getAssignedAccreditationsToUserQuery?.data?.items?.map(e => { return { description: e.personAccreditation?.description, id: e.personAccreditation?.id}}) || [];

    const programStatusColor = program.status === 'Live' || program.status === 'Complete' ? 'success' : (program.status === 'Draft' ? 'info' : (program.status === 'Cancelled' ? 'error' : undefined));

    return (
        isLoading ? <LoadingScreen /> :
            <Card>
                <CardContent sx={{ p: 1.5 }}>
                    <>
                        <Stack spacing={1} alignItems='flex-start' divider={<Divider orientation="horizontal" flexItem />}>
                            <Stack spacing={1}>
                                <Typography variant='body1'>Program: {program.internalName}</Typography>
                                <Typography variant='body1'>Course: {template.publicName}</Typography>
                                {/* <Typography variant='body2'>Program code: <code>{program.programReferenceCode}</code></Typography> */}
                                <Tooltip title='Program code'><Typography variant='body2'><code>{program.programReferenceCode}</code></Typography></Tooltip>
                            </Stack>

                            <Typography variant='body2'>{program.publicShortDescription}</Typography>

                            <Tooltip title='Support contact'>
                                <Stack direction='column' justifyContent='space-between' alignItems='flex-start' sx={{ width: '100%' }}>
                                    <Button variant="text" href={'mailto:' + program.supportContactEmail} startIcon={<Iconify icon={'eva:email-outline'} />}>
                                        {program.supportContactEmail}
                                    </Button>

                                    <Button variant="text" href={'tel:' + program.supportContactPhone} startIcon={<Iconify icon={'eva:phone-outline'} />}>
                                        {formatPhoneNumber(program.supportContactPhone as string) || program.supportContactPhone}
                                    </Button>
                                </Stack>
                            </Tooltip>

                            {program.conductionAddress ?
                                <Button variant="text" target={'_blank'} href={'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(program.conductionAddress)} startIcon={<Iconify icon={'eva:pin-outline'} />}>
                                    {program.conductionAddress}
                                </Button> : undefined}

                            {/* <Tooltip placement={'top'} title='Accreditations'>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                                <Iconify sx={{ ml: 0.75, fontSize: 18 }} icon='eva:award-outline' />
                                {
                                    !template.postCompletionAccreditations || template.postCompletionAccreditations.length === 0 ? <Typography>No accreditations</Typography> :
                                        <Typography variant='body2'>{template.postCompletionAccreditations.map(a => a.personAccreditationId).join(', ')}</Typography>
                                }
                            </Stack>
                        </Tooltip>

                        <Tooltip placement={'top'} title='Attributes'>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                                <Iconify sx={{ ml: 0.75, fontSize: 18 }} icon='eva:color-palette-outline' />
                                {
                                    assignedAttributes.length === 0 ? <Typography>No attributes</Typography> :
                                        <Typography variant='body2'>{assignedAttributes.map(a => a.description).join(', ')}</Typography>
                                }
                            </Stack>
                        </Tooltip> */}

                            <Stack spacing={2} direction='column' sx={{ width: '100%', pt: 2 }}>
                                {
                                    canCreateEditPrograms ?
                                        <>
                                            <Typography variant="body2" color="text.secondary">
                                                Created {fDateTime(program.creationTime || new Date())} {` by ${program.creator?.name} ${program.creator?.surname}`}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Updated {program.lastModificationTime ? fDateTime(program.lastModificationTime || new Date()) : 'never'} {program.lastModifier ? ` by ${program.lastModifier?.name} ${program.lastModifier?.surname}` : undefined}
                                            </Typography>
                                        </>
                                        : undefined}

                                <Tooltip title={'This program is ' + program.status?.toLowerCase()}>
                                    <Chip color={programStatusColor} variant={'filled'} label={program.status} />
                                </Tooltip>
                                {
                                    program.status === 'Cancelled' && program.cancellationReasonMessage ?
                                        <div>
                                            <Typography variant='subtitle1' color='error' component={'div'}>
                                                Cancellation reason:
                                            </Typography>
                                            <Typography variant='subtitle2' color='error'>
                                                {program.cancellationReasonMessage}
                                            </Typography>
                                        </div>
                                        : undefined
                                }
                            </Stack>
                        </Stack>
                    </>
                </CardContent>
                {actions ?
                    <CardActions>
                        {actions}
                    </CardActions>
                    : undefined}
            </Card>
    )
}