import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import useFeatureChecker from 'src/hooks/useFeatureChecker';
import { ForbiddenIllustration } from '../assets';

// ----------------------------------------------------------------------

type FeatureBasedGuardProp = {
  featureNameKeys?: string[];
  children: React.ReactNode;
  injectFeedback?: boolean;
};

export default function FeatureBasedGuard({ featureNameKeys, injectFeedback, children }: FeatureBasedGuardProp) {
  const featureChecker = useFeatureChecker();  

  if (typeof featureNameKeys !== 'undefined' && !featureChecker.hasAny(featureNameKeys)) {
    if(!injectFeedback) return <></>;

    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    )
  }

  return <>{children}</>;
}
