import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Stepper(theme: Theme) {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider
        }
      }
    }
    // MuiStepLabel: {
    //   styleOverrides: {
    //     active: {
    //       fontWeight: 'bold',
    //       color: 'green'
    //     },
    //     label: {
    //       color: 'purple',
    //       active: {
    //         fontWeight: 'bold',
    //         color: 'green'
    //       }
    //     }
    //   }
    // }
  };
}
