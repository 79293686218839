// i18n

import './locales/i18n';
import 'react-image-lightbox/style.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import '@mantine/tiptap/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/core/styles.css';

import { User } from 'oidc-client-ts';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

import App from './App';
import appSettings from './appSettings';
import { PATH_AFTER_LOGIN } from './config';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { onFreshLogin } from './utils/impersonation';
import AuthGuard from './guards/AuthGuard';

// ----------------------------------------------------------------------
const oidcConfig: AuthProviderProps = {
  authority: appSettings.oAuthAuthority,
  client_id: appSettings.oAuthClientId,
  redirect_uri: appSettings.oAuthRedirectUrl,
  silent_redirect_uri: appSettings.oAuthRedirectUrl,
  response_type: appSettings.oAuthResponseType,
  scope: appSettings.oAuthScope,
  post_logout_redirect_uri: appSettings.oAuthRedirectUrl,
  loadUserInfo: true,
  revokeTokensOnSignout: true,
  monitorSession: true,
  checkSessionIntervalInSeconds: 60,
  includeIdTokenInSilentRenew: true,
  onSigninCallback(user: User) {
    console.log('signed in');
    onFreshLogin(user);
    window.history.replaceState(
      {},
      document.title,
      PATH_AFTER_LOGIN
    )
  },
  automaticSilentRenew: true,
};

const root = createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <StrictMode>
    <AuthProvider {...oidcConfig}>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
            <SettingsProvider>
              <CollapseDrawerProvider>
                {/* <AuthApp> */}
                <BrowserRouter>
                  <AuthGuard>
                    <App />
                  </AuthGuard>
                </BrowserRouter>
                {/* </AuthApp> */}
              </CollapseDrawerProvider>
            </SettingsProvider>
            {/* </LocalizationProvider> */}
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
