import { forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import appSettings from 'src/appSettings';
import useAppUserContext from 'src/hooks/useAppUserContext';

// @mui
import { Box, BoxProps } from '@mui/material';
import { usePageLifecycle } from 'src/hooks/usePageLifeycle';
import LoadingScreen from './LoadingScreen';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => {
  const appContext = useAppUserContext();
  const pageLifecycle = usePageLifecycle();

  const pageTitle = title + ' | ' + (appContext?.currentTenant?.name != null ? appContext?.currentTenant?.name : 'Master portal');

  if (pageLifecycle.isLoading || pageLifecycle.isNavigatingAway) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} | ${appSettings.tenancyDescription}`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  )
});

export default Page;
