import { useState } from 'react';
import {
    VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto
} from 'src/api/redux/account/accountApi';
import permissionKeys from 'src/appPermissionKeys';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import useImpersonation from 'src/utils/impersonation';

// @mui
import { Button, Checkbox, Chip, MenuItem, TableCell, TableRow } from '@mui/material';

// @types
import { UserManager } from '../../../../@types/user';
// components
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

type Props = {
  row: UserManager;
  currentUser: UserManager;
  appUserContext: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto | undefined;
  selected: boolean;
  hideRole?: boolean;
  allowUserActions: boolean;
  onEditRow: VoidFunction;
  onOverviewRow?: VoidFunction;
  onSelectRow?: VoidFunction;
  onDeleteRow: VoidFunction;  
  onDeactivateRow: VoidFunction;
  onActivateRow: VoidFunction;
};

export default function UserTableRow({
  row,
  currentUser,
  appUserContext,
  selected,
  hideRole,
  allowUserActions,
  onEditRow,
  onOverviewRow,
  onSelectRow,
  onDeleteRow,
  onDeactivateRow,
  onActivateRow,
}: Props) {
  const { name, lastName, roleNames, email, isActive, id, tenantId, isPartialUser } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const { impersonate } = useImpersonation();

  const permissionChecker = usePermissionChecker();
  const canImpersonate = permissionChecker.has(permissionKeys.tenant.identity.impersonateUser);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const triggerImpersonation = async () => {
    await impersonate(tenantId as string, row.id as string);
  }

  const isCurrentUser = id === currentUser?.id;
  const isHostTenant = !appUserContext?.currentTenant?.id;

  return (
    <TableRow hover selected={selected}>
      { onSelectRow ?
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} disabled={isCurrentUser} />
      </TableCell> : undefined }

      <TableCell align="left">
        <Button onClick={async () => { onOverviewRow ? await onOverviewRow() : await onEditRow() }} variant={'text'}>{name} {lastName || ''}</Button>
          {
            isCurrentUser ? <Chip label="You" size='small' color="secondary" variant='filled' /> : undefined
          }
      </TableCell>

      <TableCell align="left">{isPartialUser ? '' : email}</TableCell>

      { !isHostTenant && !hideRole ?
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {roleNames?.join(", ")}
        </TableCell>
        : undefined
      }

      <TableCell align="center">
        <Iconify
          icon={isActive ? 'eva:checkmark-circle-fill' : 'eva:slash-outline'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!isActive && { color: 'error.main' }),
          }}
        />
      </TableCell>

      {/* <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'banned' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell> */}
      
      { allowUserActions ?
        <TableCell align="right"><TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              { onOverviewRow ? 
                <MenuItem
                  onClick={() => {
                    onOverviewRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:file-text-fill'} />
                  Overview
                </MenuItem>
              : undefined}

              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>

              {
                !isActive ?
                  <MenuItem
                    disabled={isCurrentUser}
                    onClick={() => {                      
                      onActivateRow();                  
                      handleCloseMenu();
                    }}
                    sx={{ color: 'success.main' }}
                  >
                    <Iconify icon={'eva:unlock-outline'} />
                    Activate
                  </MenuItem>
                :
                  <MenuItem
                    disabled={isCurrentUser}
                    onClick={() => {
                      onDeactivateRow();                  
                      handleCloseMenu();                      
                    }}
                    sx={{ color: 'warning.main' }}
                  >
                    <Iconify icon={'eva:lock-outline'} />
                    Deactivate
                </MenuItem>
              }

              <MenuItem
                disabled={isCurrentUser}
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>  

              {canImpersonate ? 
              <MenuItem
                disabled={isCurrentUser}
                onClick={() => {
                  triggerImpersonation();
                  handleCloseMenu();
                }}
                sx={{ color: 'info.main' }}
              >
                <Iconify icon={'eva:unlock-outline'} />
                Impersonate
              </MenuItem> : undefined}      
            </>
          }
        /></TableCell> : <></> }
      
    </TableRow>
  );
}
