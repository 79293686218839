import { ReactNode } from 'react';
// @mui
import { Box, Link, Typography } from '@mui/material';
//
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';

// ----------------------------------------------------------------------

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string;
  moreLink?: string | string[];
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}: Props) {
  return (
    <Box sx={{ mb: 2, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
          {links && links.length > 0 ?
          <Breadcrumbs links={links} {...other} /> : undefined }
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      {
        (typeof moreLink === 'string' && moreLink) || typeof moreLink !== 'string' && moreLink.length > 0
        ?
          <Box sx={{ mt: 2 }}>
            {typeof moreLink === 'string' ? (
              <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
                {moreLink}
              </Link>
            ) : (
              moreLink.map((href) => (
                <Link
                  noWrap
                  key={href}
                  href={href}
                  variant="body2"
                  target="_blank"
                  rel="noopener"
                  sx={{ display: 'table' }}
                >
                  {href}
                </Link>
              ))
            )}
          </Box>
        :
        <></>
      }
    </Box>
  );
}
