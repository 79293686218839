// form

import { unionBy } from 'lodash-es';
import throttle from 'lodash-es/throttle';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UserOption } from 'src/@types/user';
import { appApi } from 'src/api/redux/app/appApi';

import {
  Autocomplete, Chip, Grid, ListItemText, MenuItem, SelectProps, TextField, Typography, useTheme
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import Iconify from '../Iconify';
import RHFMultiSelect from './RHFMultiSelect';

// ----------------------------------------------------------------------

type UserTypes = 'all' | 'participants' | 'facilitators';

type IProps = {
  name: string;
  helperText?: React.ReactNode;
  noOptionsText?: string;
  userTypes: UserTypes[];
  defaultSelectedUserIds?: string[];
  requiredAttributeIds?: string[];
  requiredAccreditationIds?: string[];
};

type Props = IProps & SelectProps;



export default function RHFUserMultiSelect({ name, label, helperText, userTypes, requiredAttributeIds, requiredAccreditationIds, noOptionsText, defaultSelectedUserIds, ...other }: Props) {
  const { setValue, getValues, control, watch } = useFormContext();
  const [options, setOptions] = useState<readonly UserOption[]>([]);
  const [selectedValues, setSelectedValues] = useState<UserOption[]>(watch(name) || []);
  // const [ defaultValues, setDefaultValues ] = useState<UserOption[]>([]);  
  const [filterText, setFilterText] = useState<string>('');
  const [facilitatorLazyQuery, facilitatorLazyQueryStatus] = appApi.endpoints.facilitatorGetList.useLazyQuery();
  const [participantLazyQuery, participantLazyQueryStatus] = appApi.endpoints.participantGetList.useLazyQuery();
  const theme = useTheme();

  const icon = <Iconify icon="eva:square-outline" fontSize='small' />;
  const checkedIcon = <Iconify icon="eva:checkmark-square-2-fill" fontSize='small' color={theme.palette.primary.main} />;

  useEffect(() => {
    setFilterText(''); // Trigger initial load of users
  }, [])

  const loadUsers = async (request: { findUserText: string }, callback: (results?: readonly UserOption[]) => void) => {
    let users: UserOption[] = [];

    // Faciliators
    if (userTypes.indexOf('all') > -1 || userTypes.indexOf('facilitators') > -1) {
      const facResult = await facilitatorLazyQuery({
        maxResultCount: 200,
        filter: request.findUserText,
        assignedPersonAttributeIds: requiredAttributeIds ? requiredAttributeIds : [],
        assignedPersonAccreditationIds: requiredAccreditationIds ? requiredAccreditationIds : [],
      });
      const facOptions = (facResult.data?.items || []).map(f => {
        return {
          label: f.name + ' ' + f.surname,
          id: f.id as string
        }
      });
      users = unionBy(users, facOptions, (a) => a.id);
    }

    // Participants
    if (userTypes.indexOf('all') > -1 || userTypes.indexOf('participants') > -1) {
      const participantResult = await participantLazyQuery({
        maxResultCount: 200,
        filter: request.findUserText,
        assignedPersonAttributeIds: requiredAttributeIds ? requiredAttributeIds : [],
        assignedPersonAccreditationIds: requiredAccreditationIds ? requiredAccreditationIds : [],
      });
      const parOptions = (participantResult.data?.items || []).map(f => {
        return {
          label: f.name + ' ' + f.surname,
          id: f.id as string
        }
      });
      users = unionBy(users, parOptions, (a) => a.id);
    }

    // console.log('heree users', users);
    callback(users);
  }

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { findUserText: string },
          callback: (results?: readonly UserOption[]) => void,
        ) => {
          loadUsers(
            request,
            callback,
          );
        },
        200,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    fetch({ findUserText: filterText }, (results?: readonly UserOption[]) => {
      if (active) {
        let newOptions: readonly UserOption[] = [];

        if (results) {
          newOptions = [...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [selectedValues, filterText, fetch]);

  // useEffect(() => {
  //   loadUsers();
  // }, []);

  // useEffect(() => {
  //   const hydrateUsers = async() =>{
  //     setOptions(await loadUsers());
  //   }
  //   hydrateUsers();
  // }, [filterText]);

  const isLoading = facilitatorLazyQueryStatus.isLoading || participantLazyQueryStatus.isLoading;

  // const getDefaultValues = async () => {
  //   const opts = await loadUsers();
  //   const vals = getValues(name).map((id: string) => {
  //     const matchedOpt = opts.find(a => a.id === id);
  //     return {
  //       id: id,
  //       label: matchedOpt ? matchedOpt.label : "(Invalid trainer)"
  //     }
  //   });

  //   console.log('heree setdef', opts, getValues(name), vals);
  //   setDefaultValues(vals)
  // }

  // useEffect(() => {
  //   getDefaultValues();    
  // }, []);


  // const defVal = watch(name).map((id: string) => {
  //   const matchedOpt = options.find(a => a.id === id);
  //   return {
  //     id: id,
  //     label: matchedOpt ? matchedOpt.label : "(Invalid trainer)"
  //   }
  // });

  return (
    <>
      {/* <code style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(options)}</code> */}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <Autocomplete
              multiple
              id={"user-search-" + name}
              onBlur={field.onBlur}
              // name={field.onChange}
              ref={field.ref}
              // getOptionLabel={(option) => {
              //   return option.label;
              // if(option?.label) return option.label;
              // console.log('heree getOptionLabel', option);
              // const matchedOpt = options.find(a => a.id === option.id);
              // return matchedOpt ? matchedOpt.label : "(Invalid trainer)";
              // }}
              onChange={(o, v) => {
                // console.log('heree onchange', o, v)
                // field.onChange(o)
                setValue(name, v || [], { shouldDirty: true, shouldValidate: true, shouldTouch: true });
                setSelectedValues(v);
              }}
              // defaultValue={defaultValues}
              value={selectedValues}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              filterSelectedOptions={false}
              disableCloseOnSelect
              loading={isLoading}
              // inputValue={filterText}
              onInputChange={(e, val) => setFilterText(val)}
              fullWidth
              noOptionsText={isLoading ? 'Loading...' : noOptionsText}
              // includeInputInList={false}
              // filterSelectedOptions={false}
              // value={filterText}
              isOptionEqualToValue={(op, val) => {
                // console.log('heree isOptionEqualToValue', val, op);
                // const optId = typeof op === 'string' ? op : op.id;
                // const valId = typeof val === 'string' ? val : val.id;
                // return optId === valId;

                return op.id === val.id;
              }}
              // onChange={(event: any, newValue: UserOption[] | null) => {
              // setOptions(newValue ? [newValue, ...options] : options);
              // setValue(newValue);

              // console.log('heree5', field.name, newValue)
              // methods.setValue(field.name, newValue?.description, { shouldValidate: false, shouldDirty: true, shouldTouch: true});
              //   field.onChange(newValue?.map(a => a.id) || []);
              // }}
              // onChange={(event: any, newValue: UserOption | null) => {
              // setOptions(newValue ? [newValue, ...options] : options);
              // setValue(newValue);

              // console.log('heree5', field.name, newValue)
              // methods.setValue(field.name, newValue?.description, { shouldValidate: false, shouldDirty: true, shouldTouch: true});
              // field.onChange(newValue?.id);
              // }}
              // onInputChange={(event, newInputValue, reason) => {
              //   // console.log('heree changed1', newInputValue, reason)
              //   if (reason === 'clear') {
              //     field.onChange('');
              //     // setValue(null);
              //     setResetCount(resetCount + 1);
              //     setInputValue('');
              //   } else {
              //     field.onChange(newInputValue);
              //     setInputValue(newInputValue);
              //   }
              // }}
              //   onReset={() => field.onChange(newValue?.description)}
              renderInput={(params) => {
                const helperTextComponent = error?.message && helperText ?
                  <>
                    <span style={{ display: 'block' }}>{error?.message}</span>
                    <span style={{ display: 'block' }}>{helperText}</span>
                  </>
                  : (helperText ? helperText :
                    (error?.message ? error?.message : undefined)
                  )

                return (
                  <>
                    <TextField // key={resetCount}
                      {...params} inputProps={{ ...params.inputProps, autoComplete: 'no' }} label={label} fullWidth helperText={helperTextComponent} error={!!error} placeholder={other.placeholder || 'Search for a user...'} />
                  </>
                )
              }}
              renderOption={(props, option, { selected }) => {
                // console.log('heree renderOption', option, options, props);
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )
              }}
              renderTags={(selectedVals, getTagProps) => {
                // const notFoundLabel = '(User not found)';
                // console.log('heree renderTags', selectedVals);
                // const matchedVals = selectedVals.map((v) => {
                //   return v.label ? v : (selectedValues.find(o => o.id === v.id) || options.find(o => o.id === v.id) || {id: v.id, label: notFoundLabel});
                // })

                return selectedVals.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    // color={!isLoading && option.label === notFoundLabel ? 'warning' : undefined}
                    label={option.label}
                    key={option.id}
                  // disabled={fixedOptions.indexOf(option) !== -1}
                  />
                ));
              }}
            />
          )
        }}
      />
    </>
  );
}
