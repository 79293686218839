import { sentenceCase } from 'change-case';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router';
import { UserManager } from 'src/@types/user';
import { appApi } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fDate } from 'src/utils/formatTime';

import { Alert, AlertTitle, CardActions, Chip, Divider, Link, Stack, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type Props = {
  user: UserManager;
  withName?: boolean;
  compact?: boolean;
  actions?: React.ReactElement;
  extraDetails?: React.ReactElement;
  forceType?: 'Participant' | 'Facilitator'
};

export default function UserDetailsCard({ user, withName, actions, extraDetails, forceType, compact }: Props) {
  const getAssignedAttributesToUserQuery = appApi.endpoints.personAttributeGetAssignmentsForUser.useQuery({ userId: user.id }, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const getAssignedAccreditationsToUserQuery = appApi.endpoints.personAccreditationGetAssignmentsForUser.useQuery({ userId: user.id }, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const personAttributeGetListQuery = appApi.endpoints.personAttributeGetList.useQuery({ maxResultCount: 100 });
  const personAccreditationGetListQuery = appApi.endpoints.personAccreditationGetList.useQuery({ maxResultCount: 100 });
  const navigate = useNavigate();
  const permissionChecker = usePermissionChecker();
  const canManageFacilitators = permissionChecker.has(permissionKeys.tenant.program.manageFacilitators);
  const canManageParticipants = permissionChecker.has(permissionKeys.tenant.program.manageParticipants);

  const isLoading = getAssignedAttributesToUserQuery.isLoading || getAssignedAccreditationsToUserQuery.isLoading || personAttributeGetListQuery.isLoading || personAccreditationGetListQuery.isLoading;

  const assignedAttributes = getAssignedAttributesToUserQuery.isLoading || personAttributeGetListQuery.isLoading ? [] : getAssignedAttributesToUserQuery?.data?.items?.map(e => { return { description: e.personAttribute?.description, id: e.personAttribute?.id } }) || [];
  const assignedAccreditations = getAssignedAccreditationsToUserQuery.isLoading || personAccreditationGetListQuery.isLoading ? [] : getAssignedAccreditationsToUserQuery?.data?.items?.map(e => { return { description: e.personAccreditation?.description, id: e.personAccreditation?.id } }) || [];

  const userOverviewLink = !forceType ? undefined :
    (forceType === 'Participant' && canManageParticipants ? PATH_DASHBOARD.tenant.participant.overview(user.id) : (canManageFacilitators ? PATH_DASHBOARD.tenant.facilitator.overview(user.id) : undefined));

  const isPartialUser = user && user.isPartialUser;

  return (
    <>
      {isLoading ? <LoadingScreen /> :
        <>
          <Card>
            <CardContent sx={{ p: 1.5 }}>
              <>
                {!withName ? undefined :
                  userOverviewLink ? <Link sx={{ cursor: 'pointer', mb: 2 }} onClick={() => { navigate(userOverviewLink as string) }} variant='h6'>{user?.name} {user?.lastName}</Link> : <Typography variant='h6'>{user?.name} {user?.lastName}</Typography>
                }
                <Stack spacing={0.7} alignItems='flex-start' sx={{ mb: 2 }} divider={<Divider orientation="horizontal" flexItem />}>
                  {
                    !isPartialUser ? <Button variant="text" href={'mailto:' + user.email} startIcon={<Iconify icon={'eva:email-outline'} />}>
                      {user.email}
                    </Button> : undefined
                  }


                  {user.phoneNumber ?
                    <Button variant="text" href={'tel:' + user.phoneNumber} startIcon={<Iconify icon={'eva:phone-outline'} />}>
                      {formatPhoneNumber(user.phoneNumber) || user.phoneNumber}
                    </Button> : undefined}

                  {user.address ?
                    <Button variant="text" target={'_blank'} href={'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(user.address)} startIcon={<Iconify icon={'eva:pin-outline'} />}>
                      {user.address}
                    </Button> : undefined}

                  {!compact ?
                    <Tooltip placement={'top'} title='Accreditations'>
                      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                        <div><Iconify sx={{ ml: 0.75, fontSize: 18 }} icon='eva:award-outline' /></div>
                        {
                          assignedAccreditations.length === 0 ? <Typography variant='body2'>No accreditations</Typography> :
                            <Typography variant='body2'>{assignedAccreditations.map(a => <div key={a.id}>{a.description}</div>)}</Typography>
                        }
                      </Stack>
                    </Tooltip> : undefined}

                  {!compact ? <Tooltip placement={'top'} title='Attributes'>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                      <div><Iconify sx={{ ml: 0.75, fontSize: 18 }} icon='eva:color-palette-outline' /></div>
                      {
                        assignedAttributes.length === 0 ? <Typography variant='body2'>No attributes</Typography> :
                          <Typography variant='body2'>{assignedAttributes.map(a => <div key={a.id}>{a.description}</div>)}</Typography>
                      }
                    </Stack>
                  </Tooltip> : undefined}
                </Stack>

                <Stack spacing={2} alignItems='center' divider={<Divider orientation="horizontal" flexItem />}>
                  <div>
                    <Typography variant="body2" color="text.secondary">
                      Member from {fDate(user.creationTime || new Date())}
                    </Typography>

                    {extraDetails}
                  </div>

                  {!compact && user.roleNames && user.roleNames.length > 0 ?
                    <>
                      {/* <Typography>Roles</Typography> */}
                      <Stack direction="row" spacing={1} alignItems='space-between'>
                        {
                          user.roleNames.map(r => <Chip key={r} label={sentenceCase(r)} variant="filled" />)
                        }
                      </Stack>
                    </>
                    : undefined}
                </Stack>
              </>
            </CardContent>
            {actions ?
              <CardActions>
                {actions}
              </CardActions>
              : undefined}
          </Card>

          {isPartialUser ? <Alert severity='warning' variant='outlined'><AlertTitle>{user.name} is a partial user</AlertTitle>No email set on profile so {user.name} cannot login</Alert> : undefined}
        </>
      }
    </>
  );
}
