import { groupBy } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import * as mime from 'mime-types';
import { useEffect, useState } from 'react';
import {
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesTemplatesPrepareResourceOperationResponse
} from 'src/api/redux/app/appApi';
import { UploadMultiFile } from 'src/components/upload';
import {
    audioMimeTypes, imageMimeTypes, videoMimeTypes
} from 'src/components/upload/UploadMultiFile';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';

import { BlockBlobClient } from '@azure/storage-blob';

interface IResourceContentManagerProps {
    onUploadSuccess?: (resource: Activ8CoursesContentLearningCourseContentResource) => Promise<void>;
    onUpdateMetadata?: (resource: Activ8CoursesContentLearningCourseContentResource) => Promise<void>;
    onDelete?: (resource: Activ8CoursesContentLearningCourseContentResource) => Promise<void>;
    prepareReadUrl?: (resource: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean) => Promise<string>;
    prepareUpload?: (file: File) => Promise<{ response: Activ8CoursesTemplatesPrepareResourceOperationResponse | null, fileId: string }>;
    defaultValue: Activ8CoursesContentLearningCourseContentResource[];
    acceptTypes?: { [key: string]: string[] };
    hideDropzone?: boolean;
    preload?: boolean;
    preventDownload?: boolean;
    direction?: 'row' | 'column';
    restrictedToMedia?: boolean;
    singleFile?: boolean;
    autoplay?: boolean;
}

export interface IResourceContentManagerFile extends Activ8CoursesContentLearningCourseContentResource {
    status: 'PreviouslyUploaded' | 'Uploaded' | 'PendingStart' | 'Uploading' | 'Failed';
    uploadProgressPercent?: number;
}

export function getFileTypeFromMimeType(mime: string): 'Generic' | 'Image' | 'Video' | 'Pdf' | 'Audio' {
    if (!mime) return 'Generic';

    mime = mime.toLocaleLowerCase();

    if (imageMimeTypes.find(e => e.toLowerCase() === mime)) return 'Image';
    if (videoMimeTypes.find(e => e.toLowerCase() === mime)) return 'Video';
    if (audioMimeTypes.find(e => e.toLowerCase() === mime)) return 'Audio';
    if (mime.endsWith('/pdf')) return 'Pdf';

    return 'Generic';
}

// https://github.com/Azure-Samples/AzureStorageSnippets/blob/master/blobs/howto/JavaScript/NodeJS-v12/dev-guide/upload-blob-from-stream.js
export function ResourceContentManager({ prepareUpload, onUploadSuccess, onUpdateMetadata, onDelete, defaultValue, prepareReadUrl, hideDropzone, restrictedToMedia, preventDownload, autoplay, direction, singleFile, preload }: IResourceContentManagerProps) {
    const [files, setFiles] = useState<IResourceContentManagerFile[]>([]);
    const [uploadProgressionIndex, setUploadProgressionIndex] = useState<number>(0);
    const [uploadProgressionInFlight, setUploadProgressionInFlight] = useState<boolean>(false);
    const [filePreparations, setFilePreparations] = useState<{ response: Activ8CoursesTemplatesPrepareResourceOperationResponse, fileId: string, fileInfo: IResourceContentManagerFile, blob: Blob }[]>([]);
    const { handleError } = useApiResponseHandler();
    const confirm = useConfirm();

    useEffect(() => {
        setFiles(
            (defaultValue || []).map((f) => ({
                ...f,
                status: 'PreviouslyUploaded'
            } as IResourceContentManagerFile)))
    }, []);

    const onDeleteRequest = async (file: IResourceContentManagerFile): Promise<void> => {
        if (!onDelete) return;

        const toDelete = files.find(e => e.id === file.id);
        if (!toDelete) return;

        confirm({ description: `Are you sure you want to delete ${(toDelete.description || toDelete.fileName)}?` })
            .then(async () => {
                onDelete(file).then(() => {
                    setFiles([...files.filter(e => e.id !== file.id)])
                });
            })
            .catch(() => { });
    }

    const onPreview = async (file: IResourceContentManagerFile): Promise<void> => {
        if (!prepareReadUrl) return;

        const url = await prepareReadUrl(file, false);
        window.open(url);
    }

    const onDownload = async (file: IResourceContentManagerFile): Promise<void> => {
        if (preventDownload || !prepareReadUrl) return;

        const url = await prepareReadUrl(file, true);
        window.open(url);
    }

    // eslint-disable-next-line arrow-body-style
    const mapToFileInfo = (response: Activ8CoursesTemplatesPrepareResourceOperationResponse | null, fileId: string, index: number, f: Blob): IResourceContentManagerFile => {
        const mimeType = mime.lookup(response?.properties?.fileName as string) || '';
        // console.log('heree mapToFileInfo', f);
        return {
            id: fileId as string,
            type: getFileTypeFromMimeType(mimeType),
            description: '',
            blobReference: response?.blobReference,
            fileName: response?.properties?.fileName,
            contentType: mimeType as string,
            fileExtension: (response?.properties?.fileName as string).substring((response?.properties?.fileName as string).lastIndexOf('.') + 1),
            fileSizeBytes: f.size,
            uploadProgressPercent: 0,
            status: 'PendingStart'
        }
    }

    useEffect(() => {
        // console.log('heree useEffect', filePreparations);
        const uploadData = async () => {
            if (!onUploadSuccess) return;

            const toUpload = filePreparations.filter(e => e && e.fileInfo && e.fileInfo.status === 'PendingStart').sort((a, b) => (a?.fileInfo.fileSizeBytes || -1) - (b?.fileInfo.fileSizeBytes || -1));
            if (toUpload.length === 0) return;
            const prepped = toUpload[0];

            const blobClient = new BlockBlobClient(`${prepped?.response?.blobReference}${prepped?.response?.blobToken}`);

            await blobClient.uploadData(prepped?.blob as Blob, {
                onProgress: async (progress) => {
                    const stat = { ...prepped?.fileInfo, status: 'Uploading', uploadProgressPercent: ((progress.loadedBytes / (prepped?.fileInfo?.fileSizeBytes as number)) * 100) };
                    if (stat.uploadProgressPercent >= 100) {
                        stat.status = 'Uploaded';
                        stat.uploadProgressPercent = 100;
                    }
                    //console.log('heree useEffect loop onProgress', stat);
                    setFileStatus([stat as any])
                },
                blockSize: 4 * 1024 * 1024, // 8MB
                blobHTTPHeaders: { blobContentType: prepped?.blob.type },
                tier: 'Hot',
                concurrency: 20,
                metadata: {
                    "_FileId": prepped?.response.properties?.fileId || '',
                    "_PartitionId": prepped?.response.properties?.partitionId || '',
                    "_FileCategory": prepped?.response.properties?.fileCategory || '',
                    "_EntityTypeName": prepped?.response?.properties?.entityTypeName || '',
                    "_EntityFriendlyId": prepped?.response?.properties?.entityFriendlyId || '',
                    "_IsPubliclyViewable": prepped?.response.properties?.isPubliclyViewable === true ? 'true' : 'false',
                    "_ThumbnailUrl": prepped?.response.properties?.thumbnailUrl || '',
                    "_FileName": prepped?.response.properties?.fileName || '',
                    "_ContentType": prepped?.response.properties?.contentType || '',
                    "_FileExtension": ('.' + prepped?.fileInfo.fileExtension) || '',
                },
                maxSingleShotSize: 100 * 1024 * 1024 // 100MB size and above will trigger concurrent upload
            });

            const newPreps = [...filePreparations.filter(e => e.fileId !== prepped.fileId)];
            prepped.fileInfo.status = 'Uploaded';
            prepped.fileInfo.uploadProgressPercent = 100;
            onUploadSuccess(prepped.fileInfo);
            newPreps.push(prepped);
            setFilePreparations(newPreps);
        }

        uploadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filePreparations])

    const onDrop = async (acceptedFiles: File[]): Promise<void> => {
        //console.log('heree onDrop 1', acceptedFiles);
        if (!prepareUpload) return;

        try {
            const prepared = await Promise.all(acceptedFiles.map(async (f, index) => {
                let p: { response: Activ8CoursesTemplatesPrepareResourceOperationResponse | null, fileId: string };
                try {
                    p = await prepareUpload(f);
                    if (!p.response) {
                        return null;
                    }
                }
                catch (error) {
                    handleError(error);
                    return null;
                }
                // console.log(p, f)
                const fileInfo = mapToFileInfo(p.response, p.fileId, index, f);
                return { response: p.response, fileId: p.fileId, fileInfo, blob: f as Blob };
            }));

            setFileStatus(prepared.filter(a => a?.fileInfo != null).map((a) => a?.fileInfo as IResourceContentManagerFile));
            setFilePreparations(prepared?.filter((a) => a !== undefined) as any);
        }
        catch (error) {
            console.error(error);
            handleError(error);
        }
    }

    const setFileStatus = (filesToSet: IResourceContentManagerFile[]) => {
        const fileList = [...files];

        for (let index = 0; index < filesToSet.length; index++) {
            const file = filesToSet[index];
            const existingFile = fileList.find((e) => e.id === file.id);
            if (!existingFile) {
                fileList.push({ ...file });
            }
            else {
                fileList.push({ ...file });
            }
        }

        const grouped = groupBy(fileList, (a) => a.id);
        const distinct = [];
        for (const key in grouped) {
            if (Object.prototype.hasOwnProperty.call(grouped, key)) {
                const element = grouped[key];
                // console.log('heree iterator', key, element.sort((a, b) => (a?.uploadProgressPercent || -1) - (b?.uploadProgressPercent || -1))[0])   
                distinct.push({ ...element.sort((a, b) => (b.status === 'Uploading' ? 100 : 0) + (b?.uploadProgressPercent || -1) - (a?.uploadProgressPercent || -1))[0] });
            }
        }

        // make distinct and sort by progress
        if (singleFile) {
            distinct.splice(0, distinct.length - 1);
        }
        setFiles(distinct);
    }

    return (
        <>
            <UploadMultiFile
                files={files}
                onDelete={onDelete ? onDeleteRequest : undefined}
                onUpdateMetadata={onUpdateMetadata}
                onDownload={preventDownload ? undefined : onDownload}
                onPreview={onPreview}
                hideDropzone={hideDropzone}
                direction={direction}
                autoplay={autoplay}
                preload={preload}
                prepareReadUrl={prepareReadUrl}
                restrictedToMedia={restrictedToMedia}
                singleFile={singleFile}
                onDrop={async (acceptedFiles: File[]) => { await onDrop(acceptedFiles) }}
            />
        </>
    )
}