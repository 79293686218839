import { DateTime } from 'luxon';
import {
    Activ8CoursesContentLearningCourseContentChapterSession,
    Activ8CoursesProgramsCoursesLearningCourseProgramSessionDto,
    Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionInfo
} from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';

import { Button, Chip, Stack, Tooltip, Typography } from '@mui/material';

export type ILearningCourseProgramChapterSessionItemProps = {
    contentSession: Activ8CoursesContentLearningCourseContentChapterSession;
    programSession: Activ8CoursesProgramsCoursesLearningCourseProgramSessionDto;
    participantSessionProgression: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentSessionProgressionInfo | undefined;
    sessionIndex: number;
    triggerShowSessionContentViewWizard: (chapterId: string, sessionId: string) => void;
}

export function LearningCourseProgramChapterSessionItem({ contentSession, programSession, sessionIndex, triggerShowSessionContentViewWizard, participantSessionProgression }: ILearningCourseProgramChapterSessionItemProps) {
    const sessionStartDateTime = programSession.sessionDate ? DateTime.fromISO(programSession.sessionDate + 'T' + programSession.sessionStartTime) : undefined;
    const sessionFinishDateTime = programSession.sessionDate ? DateTime.fromISO(programSession.sessionDate + 'T' + programSession.sessionFinishTime) : undefined;

    const sessionContentCompletionStatus = participantSessionProgression ? participantSessionProgression.progressionStatus : undefined;
    const hasOnlineModules = participantSessionProgression && sessionContentCompletionStatus !== 'NotRequired' && contentSession.content && contentSession.content.length > 0;
    let onlineModuleButtonText = ''
    switch (sessionContentCompletionStatus) {
        case 'NotStarted': onlineModuleButtonText = 'Modules'; break;
        case 'InProgress': onlineModuleButtonText = 'Modules'; break;
    }

    return (
        <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 4 }} alignItems={{ xs: undefined, md: 'center' }} justifyContent='space-between'>
            <Stack direction='column' gap={2}>
                <Stack direction='row' gap={4} alignItems='center' justifyContent='flex-start'>
                    <Typography variant='subtitle1'>
                        {contentSession.name}
                    </Typography>
                </Stack>
                {/* <Stack direction='column' gap={1} alignItems='flex-start' justifyContent='flex-start'>
                    <Tooltip title='Estimated duration of session'>
                        <Chip
                            icon={<Iconify icon="eva:clock-outline" />}
                            variant="outlined"
                            size="small"
                            // label={timespanToFriendlyText(contentSession.duration)}
                            label={isoTimespanToTotalMinutes(contentSession.duration as string) > 0 ? isoTimespanToFriendlyText(contentSession.duration as string) : 'None'}
                        />
                    </Tooltip>
                </Stack> */}
            </Stack>
            <Stack gap={2}>
                {
                    !programSession.sessionDate ?
                        <Typography variant='body2'>No session date</Typography>
                        :
                        <Stack direction='row' gap={4} alignItems='flex-start' justifyContent='flex-end'>
                            {sessionStartDateTime ? <Typography variant='body2'>{sessionStartDateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY, {}).replace(/, /gi, ' ')}</Typography> : <></>}
                            {sessionStartDateTime && sessionFinishDateTime ? <Typography variant='body2'>{sessionStartDateTime.toLocaleString(DateTime.TIME_SIMPLE)} - {sessionFinishDateTime.toLocaleString(DateTime.TIME_SIMPLE)}</Typography> : <></>}
                        </Stack>
                }
            </Stack>
            {
                !hasOnlineModules ? (participantSessionProgression ? <Tooltip title='No online modules for session'><Chip size='small' label='N/A' /></Tooltip> : undefined) :
                    <>
                        {sessionContentCompletionStatus === 'Complete' ? <Tooltip title='Online modules complete'><Chip onClick={() => triggerShowSessionContentViewWizard(programSession.learningCourseTemplateContentChapterId as string, programSession.learningCourseTemplateContentSessionId as string)} color='success' size='small' variant='outlined' label='Complete' /></Tooltip>
                            :
                            <Tooltip title='Complete online modules for this session'>
                                <Button color='primary' size='small' variant='contained' endIcon={<Iconify icon='eva:play-circle-outline' />} onClick={() => triggerShowSessionContentViewWizard(programSession.learningCourseTemplateContentChapterId as string, programSession.learningCourseTemplateContentSessionId as string)}>{onlineModuleButtonText}</Button>
                            </Tooltip>
                        }
                    </>
            }
        </Stack>
    )
}