import RHFPersonAccreditationSelect from 'src/components/hook-form/RHFPersonAccreditationSelect';
import RHFPersonAttributeSelect from 'src/components/hook-form/RHFPersonAttributeSelect';

import { Stack, Typography } from '@mui/material';

export default function LearningCourseTemplateWizardFormPrerequisitesStep() {
  return (
    <Stack direction={{xs: 'column', md: 'column', lg: 'row'}} gap={8} alignItems={'flex-start'} justifyContent='space-between'>
      <Stack gap={1}>
        <Typography variant='h5' gutterBottom>Prerequisites for facilitators</Typography>
        <Stack gap={4}>
          <RHFPersonAttributeSelect name="facilitatorAttributeIdsPreRequisites" helperText="Facilitators will not be able to deliver the course unless they have these attributes" />
          <RHFPersonAccreditationSelect name="facilitatorAccreditationIdsPreRequisites" helperText="Facilitators will not be able to deliver the course unless they have these accreditations" />
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Typography variant='h5' gutterBottom>Prerequisites for participants</Typography>
        <Stack gap={4}>
          <RHFPersonAccreditationSelect name="participantAccreditationIdsPreRequisites" helperText="Participants will not be able to undertake the course unless they have these attributes" />
        </Stack>
      </Stack>
    </Stack>
  )
}