// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import permissionKeys from 'src/appPermissionKeys';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------  
  {
    subheader: 'Courses',
    permissions: [permissionKeys.master.manageSettings],
    items: [
      {
        title: 'Courses',
        path: PATH_DASHBOARD.master.courseTemplate.list,
        icon: <Iconify icon={'eva:book-open-outline'}/>,
      },
    ],
  },
  {
    subheader: 'Programs',
    permissions: [permissionKeys.tenant.program.list],
    items: [
      {
        title: 'Programs',
        path: PATH_DASHBOARD.tenant.program.list,
        icon: <Iconify icon={'eva:book-open-outline'}/>,
      },
    ],
  },
  {
    subheader: 'Partners',
    permissions: [permissionKeys.master.partners],
    items: [
      {
        title: 'Partners',
        path: PATH_DASHBOARD.master.partner.list,
        icon: ICONS.user,
      },
    ],
  },
  {
    subheader: 'Participants',
    permissions: [permissionKeys.tenant.participant.manage],
    items: [
      {
        title: 'Participants',
        path: PATH_DASHBOARD.tenant.participant.list,
        icon: <Iconify icon={'eva:person-done-outline'}/>,
      },
    ],
  },
  {
    subheader: 'Facilitators',
    permissions: [permissionKeys.tenant.facilitator.manage],
    items: [
      {
        title: 'Facilitators',
        path: PATH_DASHBOARD.tenant.facilitator.list,
        icon: <Iconify icon={'eva:umbrella-outline'}/>,
      },
    ],
  },
    // ----------------------------------------------------------------------  
    {
      subheader: 'Manage',
      items: [
        {
          title: 'Manage',
          path: PATH_DASHBOARD.general.manageSettings,
          icon: <Iconify icon={'eva:settings-outline'}/>,
        },
      ],
    },
  // {
  //   subheader: 'Setup',
  //   items: [
  //     // USER
  //     {
  //       title: 'Settings',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: <Iconify icon={'eva:settings-outline'}/>,
  //       children: [S
  //         { title: 'Team', path: PATH_DASHBOARD.user.list },
  //         { title: 'Accreditations', path: PATH_DASHBOARD.master.person.accreditations.list, permissions: [permissionKeys.master.person.manageAccreditations] },
  //         { title: 'Atrributes', path: PATH_DASHBOARD.master.person.attributes.list, permissions: [permissionKeys.master.person.manageAttributes] },
  //         { title: 'Partner profile', path: PATH_DASHBOARD.tenant.partner.profile.edit, permissions: [permissionKeys.tenant.partner.profile.manage] },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
