import { useFormContext } from 'react-hook-form';
import { appApi } from 'src/api/redux/app/appApi';
import LoadingScreen from 'src/components/LoadingScreen';
import LearningCourseTemplateDetailsCard from 'src/sections/courses/templates/LearningCourseTemplateDetailsCard';

import { Alert, Button, Chip, Grid, Stack, Tooltip, useTheme } from '@mui/material';

import { LearningProgramFormValuesSchema } from '../LearningCourseProgramWizardForm';

type IProps = { 
  isEdit: boolean;
  onSelectCourseTemplateId: (learningCourseTemplateId: string) => void;
}

export default function LearningCourseProgramWizardFormCourseTypeStep({ onSelectCourseTemplateId, isEdit }: IProps) {
  const courseTemplateQuery = appApi.endpoints.learningCourseTemplateGetList.useQuery({maxResultCount: 100, statuses: [ 'Live', 'Paused' ], sorting: 'status desc, publicName asc' }, { refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const { watch } = useFormContext<LearningProgramFormValuesSchema>();
  const currentCourseTemplateIdWatch = watch('learningCourseTemplateId');
  const theme = useTheme();

  const { isLoading } = courseTemplateQuery;

  return (
    isLoading ?
      <LoadingScreen /> 
      :     
      !courseTemplateQuery.data || !courseTemplateQuery.data.items || courseTemplateQuery.data.items.length === 0 ?          
        <Alert severity='error'>You do not have any courses licensed.</Alert>
      :
      <Grid container spacing={4}>
        {
          courseTemplateQuery.data.items.map(t => 
            <Grid key={t.id} item xs={12} md={6} lg={4}>
              <LearningCourseTemplateDetailsCard selected={currentCourseTemplateIdWatch === t.id} faded={Boolean(currentCourseTemplateIdWatch) && currentCourseTemplateIdWatch !== t.id ? true : false} template={t} actions={
                <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ p: 1, width: '100%'}}>
                  <Button variant='contained' size='small' disabled={t.status !== 'Live' || isEdit || Boolean(currentCourseTemplateIdWatch)} onClick={() => onSelectCourseTemplateId(t.id as string)}>Select course</Button>
                  {t.status !== 'Live' ?
                  <Tooltip title={'This course is ' + t.status?.toLowerCase() + ' and is not available for new programs at this stage'}>
                    <Chip color={isEdit ? undefined : 'warning'} disabled={isEdit || Boolean(currentCourseTemplateIdWatch)} variant={isEdit ? 'outlined' : 'filled'} size='small' label={t.status} />
                  </Tooltip> : undefined }                  
                </Stack>
              } />
            </Grid>
          )
        }            
      </Grid>
  )
}