import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { Activ8PartnersPartnerProfileDetail, appApi } from 'src/api/redux/app/appApi';
import RHFAddressSelect from 'src/components/hook-form/RHFAddressSelect';
import RHFTimezoneSelect from 'src/components/hook-form/RHFTimezoneSelect';
import LoadingScreen from 'src/components/LoadingScreen';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box, Card, FormControlLabel, Grid, InputAdornment, Stack, Switch, Typography, useTheme
} from '@mui/material';

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Label from '../../../components/Label';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { fData } from '../../../utils/formatNumber';

// form











// @mui




// components

// routes

// utils


// ----------------------------------------------------------------------

type ExtraFormValuesProps = {

}

type FormValuesProps = ExtraFormValuesProps & Activ8PartnersPartnerProfileDetail;

type Props = {
  currentProfile?: Activ8PartnersPartnerProfileDetail;
  isEdit: boolean;
};

export default function PartnerProfileForm({
  currentProfile,
  isEdit,
}: Props) {

  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useApiResponseHandler();
  const [partnerProfilePost, partnerProfilePostStatus] = appApi.endpoints.partnerProfileSet.useMutation();
  const getProfileQuery = appApi.endpoints.partnerProfileGet.useQuery(undefined,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true
    });

  const isLoading = getProfileQuery.isLoading || partnerProfilePostStatus.isLoading;

  const PartnerProfileSchema =
    Yup.object().shape({
      publicWebsiteUrl: Yup.string().nullable().url("Must be a valid URL. Eg. https://your.website.com").required("Required"),
      publicWebsiteProgramBookUrl: Yup.string().nullable().url("Must be a valid URL. Eg. https://your.website.com").required("Required"),
      taxOrCompanyNumber: Yup.string().nullable().required("Required"),
      headOfficeAddress: Yup.string().nullable().required("Required"),
      ianaTimezone: Yup.string().nullable().required("Required"),
      keyDecisionMakerContactName: Yup.string().nullable().required("Required"),
      keyDecisionMakerContactEmail: Yup.string().nullable().email("Must be a valid email").required("Required"),
      accountsContactName: Yup.string().nullable().required("Required"),
      accountsContactEmail: Yup.string().nullable().email("Must be a valid email").required("Required"),
      marketingContactName: Yup.string().nullable().required("Required"),
      marketingContactEmail: Yup.string().nullable().email("Must be a valid email").required("Required"),
      participantSupportContactName: Yup.string().nullable().required("Required"),
      participantSupportContactEmail: Yup.string().nullable().email("Must be a valid email").required("Required"),
    })

  const defaultValues = useMemo(
    () => ({
      publicWebsiteUrl: currentProfile?.publicWebsiteUrl,
      publicWebsiteProgramBookUrl: currentProfile?.publicWebsiteProgramBookUrl,
      taxOrCompanyNumber: currentProfile?.taxOrCompanyNumber,
      headOfficeAddress: currentProfile?.headOfficeAddress,
      ianaTimezone: currentProfile?.ianaTimezone,
      keyDecisionMakerContactName: currentProfile?.keyDecisionMakerContactName,
      keyDecisionMakerContactEmail: currentProfile?.keyDecisionMakerContactEmail,
      accountsContactName: currentProfile?.accountsContactName,
      accountsContactEmail: currentProfile?.accountsContactEmail,
      marketingContactName: currentProfile?.marketingContactName,
      marketingContactEmail: currentProfile?.marketingContactEmail,
      participantSupportContactName: currentProfile?.participantSupportContactName,
      participantSupportContactEmail: currentProfile?.participantSupportContactEmail,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProfile]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(PartnerProfileSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (isEdit && currentProfile) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentProfile]);

  const onSubmit = async (data: FormValuesProps) => {
    let success = false;
    try {
      try {
        await partnerProfilePost({
          activ8PartnersProfilePartnerProfileDetailSetRequest: {
            ...data
          },
        }).unwrap();
        success = true;
      } catch (error) {
        handleError(error);
      }

      if (success) {
        await getProfileQuery.refetch();
        enqueueSnackbar('Updated!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading ? <LoadingScreen />
        :
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} alignItems="flex-end" textAlign="right">
              <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!isValid}>Update</LoadingButton>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} alignItems="flex-start">
                  <Typography variant="h6">Details</Typography>
                  <RHFTextField name="publicWebsiteUrl" label="Public website URL" />
                  <RHFTextField name="publicWebsiteProgramBookUrl" label="Public website program book URL" />
                  <RHFTextField name="taxOrCompanyNumber" label="Tax or company number" />
                  <RHFAddressSelect name="headOfficeAddress" label="Head office address" />
                  <RHFTimezoneSelect name="ianaTimezone" label="Primary time zone" />
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3} alignItems="flex-start">
                      <Typography variant="h6">Key decision maker contact</Typography>
                      <RHFTextField name="keyDecisionMakerContactName" label="Name" disableAutoComplete />
                      <RHFTextField name="keyDecisionMakerContactEmail" label="Email" disableAutoComplete />
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3} alignItems="flex-start">
                      <Typography variant="h6">Accounts contact</Typography>
                      <RHFTextField name="accountsContactName" label="Name" disableAutoComplete />
                      <RHFTextField name="accountsContactEmail" label="Email" disableAutoComplete />
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3} alignItems="flex-start">
                      <Typography variant="h6">Marketing contact</Typography>
                      <RHFTextField name="marketingContactName" label="Name" disableAutoComplete />
                      <RHFTextField name="marketingContactEmail" label="Email" disableAutoComplete />
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3} alignItems="flex-start">
                      <Typography variant="h6">Participant support contact</Typography>
                      <RHFTextField name="participantSupportContactName" label="Name" disableAutoComplete />
                      <RHFTextField name="participantSupportContactEmail" label="Email" disableAutoComplete />
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>}
    </>
  )
}
