import { Activ8CoursesTemplatesLearningCourseTemplateDto, appApi } from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';

import { Chip, Stack, Typography } from '@mui/material';

type IProps = {
    courseTemplate: Activ8CoursesTemplatesLearningCourseTemplateDto;
    compact?: boolean;
}

export function LearningCourseProgramFacilitatorPreRequisiteDetails({ courseTemplate, compact }: IProps) {
    const accreditationIds = courseTemplate.accreditationPreRequisites?.filter(e => e.type === 'Facilitator').map(e => e.personAccreditationId) || [];
    const attributeIds = courseTemplate.attributePreRequisites?.filter(e => e.type === 'Facilitator').map(e => e.personAttributeId) || [];
    const accreditationQuery = appApi.endpoints.personAccreditationGetList.useQuery({ maxResultCount: 100 });
    const attributeQuery = appApi.endpoints.personAttributeGetList.useQuery({ maxResultCount: 100 });

    const isLoading = accreditationQuery.isLoading || attributeQuery.isLoading;

    return (
        isLoading ?
            <LoadingScreen />
            :
            <Stack gap={compact ? 3: 6}>
                <Stack gap={1}>
                    {!compact ? <Typography variant='h5' gutterBottom>Required accreditations</Typography> : undefined}
                    {
                        accreditationIds.length === 0 ?
                            <Typography>
                                No accreditations are required for trainers to deliver this program.
                            </Typography>
                            :
                            <Stack gap={1}>
                                <Typography>
                                    The below accreditations are required for trainers to deliver this program.
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    {
                                        (accreditationQuery.data?.items || []).filter(e => accreditationIds.indexOf(e.id as string) > -1).map(accred => <Chip icon={<Iconify icon='eva:award-outline' />} key={accred.id} label={accred.description} />)
                                    }
                                </Stack>
                            </Stack>
                    }
                </Stack>

                <Stack gap={1}>
                    {!compact ? <Typography variant='h5' gutterBottom>Required attributes</Typography> : undefined }
                    {
                        attributeIds.length === 0 ?
                            <Typography>
                                No attributes are required for trainers to deliver this program.
                            </Typography>
                            :
                            <Stack gap={1}>
                                <Typography>
                                    The below attributes are required for trainers to deliver this program.
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    {
                                        (attributeQuery.data?.items || []).filter(e => attributeIds.indexOf(e.id as string) > -1).map(attr => <Chip icon={<Iconify icon='eva:color-palette-outline' />} key={attr.id} label={attr.description} />)
                                    }
                                </Stack>
                            </Stack>
                    }
                </Stack>
            </Stack>)
}