import { ReactNode, useEffect } from 'react';

// emotion
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
// @mui
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeRtlLayout({ children }: Props) {
  const theme = useTheme();

  useEffect(() => {
    document.dir = theme.direction;
  }, [theme.direction]);

  const cacheRtl = createCache({
    key: 'css',
  });

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
