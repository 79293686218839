import { appApi, VoloAbpIdentityIdentityUserDto } from 'src/api/redux/app/appApi';

import { MenuItem, TextField } from '@mui/material';

type Props = {
    initialValue: string;
    label?: string;
    includeAllOption: boolean;
    roleId?: string | undefined;
    onFilterUser?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSelect?: (selected: VoloAbpIdentityIdentityUserDto) => void;
  };
  
  export default function UserSelect({
    initialValue,
    onFilterUser,
    onSelect,
    label,
    roleId,
    includeAllOption
  }: Props) {
    const userQuery = appApi.endpoints.identityUserGetList.useQuery({
      maxResultCount: 100,
      roleId: roleId
    });
  
    return (
        <TextField
        fullWidth
        select
        label={label || "User"}
        value={initialValue}
        onChange={onFilterUser}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {includeAllOption ?
            <MenuItem
                key={'all'}
                value={'all'}
                sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
                }}
            >
                All
            </MenuItem>
          : undefined
        }
        {(userQuery.data?.items || []).map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            onClick={() => {
              if(onSelect) onSelect(option);
            }}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.name + ' ' + option.surname}
          </MenuItem>
        ))}
      </TextField>
    )
}