import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto,
  Activ8PersonAccreditationsPersonAccreditationDto, appApi
} from 'src/api/redux/app/appApi';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack } from '@mui/material';

import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';

// ----------------------------------------------------------------------

type ExtraFormValuesProps = {
  description: string
}

type FormValuesProps = ExtraFormValuesProps & Activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto;

type Props = {
  isEdit: boolean;
  currentAccreditation?: Activ8PersonAccreditationsPersonAccreditationDto;
};

export default function AccreditationNewEditForm({
  isEdit,
  currentAccreditation,
}: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useApiResponseHandler();
  const [accreditationCreatePost] = appApi.endpoints.personAccreditationCreate.useMutation();
  const [accreditationUpdatePost] = appApi.endpoints.personAccreditationUpdate.useMutation();
  const accreditationListQuery = appApi.endpoints.personAccreditationGetList.useQuery({
    maxResultCount: 100,
  });

  const NewAccreditationSchema =
    Yup.object().shape({
      description: Yup.string().required('Name is required'),
    })

  const defaultValues = useMemo(
    () => ({
      description: currentAccreditation?.description || ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAccreditation]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAccreditationSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (isEdit && currentAccreditation) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentAccreditation]);

  const onSubmit = async (data: FormValuesProps) => {
    let success = false;
    try {
      if (isEdit) {
        try {
          await accreditationUpdatePost({
            id: currentAccreditation?.id as string,
            activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto: {
              description: data.description || ''
            }
          }).unwrap();
          success = true;
        } catch (error) {
          handleError(error);
        }
      } else {
        try {
          await accreditationCreatePost({
            activ8PersonAccreditationsPersonAccreditationCreateOrUpdateDto: {
              description: data.description || ''
            }
          }).unwrap();
          success = true;
        } catch (error) {
          handleError(error);
        }
      }

      if (success) {
        await accreditationListQuery.refetch();
        navigate(PATH_DASHBOARD.master.person.accreditations.list);
        enqueueSnackbar(!isEdit ? 'Created!' : 'Updated!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} alignItems="flex-end">
          <RHFTextField name="description" label="Accreditation name" />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!isValid}>
            {!isEdit ? 'Create accreditation' : 'Update'}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
