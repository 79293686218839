const permissionKeys = {
    master: {
        partners: 'Saas.Tenants',
        manageSettings: 'Activ8.Host.Settings.Manage',
        person: {
            manageAttributes: "Activ8.Host.Person.Attribute.Manage",
            manageAccreditations: "Activ8.Host.Person.Accreditation.Manage",
        },
        identity: {
            impersonateTenant: 'Saas.Tenants.Impersonation'
        },
        course: {
            export: 'Activ8.Host.Course.Export'
        },
        shareFacilitatorsWithOtherPartners: 'Activ8.Host.Facililitator.ShareWithOtherPartners'
    },
    user: {
        export: 'Activ8.User.Export'
    },
    tenant: {
        identity: {
            impersonateUser: 'AbpIdentity.Users.Impersonation'
        },
        partner: {
            profile: {
                manage: 'Activ8.Partner.Profile.Manage'
            },
            admin: {
                setup: 'Activ8.Partner.Admin.Setup',
            },
            users: {
                manage: 'Activ8.Partner.Users.Manage',
                assignAccreditations: 'Activ8.Partner.Users.AssignAccreditations'
            }
        },
        participant: {
            manage: 'Activ8.Partner.Participant.Manage',
            export: 'Activ8.Partner.Participant.Export',
            import: 'Activ8.Partner.Participant.Import'
        },
        facilitator: {
            manage: 'Activ8.Partner.Facilitator.Manage',
            export: 'Activ8.Partner.Facilitator.Export'
        },
        program: {
            list: 'Activ8.Partner.Program.List',
            overview: 'Activ8.Partner.Program.Overview',
            createUpdateDelete: 'Activ8.Partner.Program.CreateUpdateDelete',
            downloadContent: 'Activ8.Partner.Program.ContentDownload',
            manageFacilitators: 'Activ8.Partner.Program.AssignFacilitators',
            manageParticipants: 'Activ8.Partner.Program.AssignParticipants',
            export: 'Activ8.Partner.Program.Export'
        }
    }
}

export default permissionKeys;