import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import FeatureBasedGuard from 'src/guards/FeatureBasedGuard';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
} as const;

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group) => (
          <FeatureBasedGuard featureNameKeys={group.features} key={group.subheader}>
            <PermissionBasedGuard permissionNameKeys={group.permissions}>
              <RoleBasedGuard roles={group.roles}>
                <Stack direction="row" flexShrink={0}>
                  {group.items.map((list) => (
                    <NavListRoot list={list} key={list.title + list.path} />
                  ))}
                </Stack>
              </RoleBasedGuard>
            </PermissionBasedGuard>
          </FeatureBasedGuard>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
