import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFDateField from 'src/components/hook-form/RHFDateField';
import RHFTimeField from 'src/components/hook-form/RHFTimeField';
// import RHFTimeRangeField from 'src/components/hook-form/RHFTimeRangeField';
import Iconify from 'src/components/Iconify';
import { isoTimespanToFriendlyText, isoTimespanToTotalMinutes } from 'src/utils/formatTime';

import { Alert, Chip, Stack, Tooltip, Typography } from '@mui/material';

import {
    ILearningCourseProgramEditableChapterItemProps
} from './LearningCourseProgramEditableChapterItem';
import {
    LearningProgramChapterSessionFormValuesSchema, LearningProgramFormValuesSchema
} from './wizard/LearningCourseProgramWizardForm';

export type ILearningCourseProgramEditableChapterSessionItemProps = {
    session: LearningProgramChapterSessionFormValuesSchema;
    sessionIndex: number;
} & ILearningCourseProgramEditableChapterItemProps

export function LearningCourseProgramEditableChapterSessionItem({ courseProgramId, courseTemplate, courseTemplateContent, chapter, chapterIndex, session, sessionIndex }: ILearningCourseProgramEditableChapterSessionItemProps) {
    const { watch, setValue, getFieldState, formState, trigger } = useFormContext<LearningProgramFormValuesSchema>();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [firstLoad, setFirstLoad] = useState(true);

    const sessionFieldName = `chapters.${chapterIndex}.sessions.${sessionIndex}` as 'chapters.0.sessions.0';
    const sessionDateFieldName = `${sessionFieldName}.sessionDate` as 'chapters.0.sessions.0.sessionDate';
    const sessionStartFieldName = `${sessionFieldName}.sessionStartTime` as 'chapters.0.sessions.0.sessionStartTime';
    const sessionFinishFieldName = `${sessionFieldName}.sessionFinishTime` as 'chapters.0.sessions.0.sessionFinishTime';

    // const timezoneWatch = watch('conductionIanaTimezone');
    const sessionDateWatch = watch(sessionDateFieldName);
    const sessionStartWatch = watch(sessionStartFieldName);
    const sessionFinishWatch = watch(sessionFinishFieldName);
    const sessionFinishFieldState = getFieldState(sessionFinishFieldName);

    const contentSession = (courseTemplateContent?.chapters || []).find(e => e.id === chapter.learningCourseTemplateContentChapterId)?.sessions?.find(e => e.id === session.learningCourseTemplateContentSessionId);

    useEffect(() => {
        // console.log('heree useEffect sessionDateWatch', sessionDateWatch);
        trigger(sessionFieldName, { shouldFocus: false })
        setTimeout(() => {
            refreshErrorMessages();
        }, 300)
    }, [sessionDateWatch, sessionStartWatch, sessionFinishWatch])

    const refreshErrorMessages = () => {
        const sessionErrors = get(formState.errors, `chapters[${chapterIndex}].sessions[${sessionIndex}]`);
        // console.log('heree useEffect setErrorMessages', formState.errors);
        if (sessionErrors?.message) {
            setErrorMessages([sessionErrors?.message as string]);
        }
        else {
            setErrorMessages([]);
        }
    }

    useEffect(() => {
        // console.log('heree chapterWatch1', sessionDateWatch, sessionStartWatch, sessionFinishWatch);
        if (firstLoad || !sessionDateWatch || !sessionStartWatch || (sessionFinishFieldState.isTouched && sessionFinishWatch)) return;
        // console.log('heree chapterWatch1', sessionStartWatch);

        // const contentChapter = (courseTemplateContent?.chapters || []).find(e => e.id === chapter.learningCourseTemplateContentChapterId);
        // const contentSession = (contentChapter?.sessions || []).find(e => e.id === session.learningCourseTemplateContentSessionId);

        // Auto set end time
        const mins = isoTimespanToTotalMinutes(contentSession?.duration as string);
        if (mins === 0) return;
        const newDateVal = DateTime.fromISO('2000-01-01T' + sessionStartWatch).plus({ minutes: isoTimespanToTotalMinutes(contentSession?.duration as string) });
        // console.log('heree chapterWatch', newDateVal);
        setValue(sessionFinishFieldName, newDateVal.toFormat('HH:mm:ss.0'), { shouldDirty: true, shouldTouch: false, shouldValidate: true });
    }, [sessionDateWatch, sessionStartWatch])

    useEffect(() => {
        setFirstLoad(false)
    }, [])

    return (
        <Stack direction='row' gap={4} alignItems='center' justifyContent='space-between'>
            {/* {JSON.stringify(getFieldState(sessionFieldName))} */}
            {/* <div>{sessionDateWatch}</div> */}
            <Stack direction='column' gap={2}>
                <Stack direction='row' gap={4} alignItems='center' justifyContent='flex-start'>
                    <Typography variant='subtitle1'>
                        {contentSession?.name}
                    </Typography>
                </Stack>
                <Stack direction='column' gap={1} alignItems='flex-start' justifyContent='flex-start'>
                    <Tooltip title='Estimated duration of session'>
                        <Chip
                            icon={<Iconify icon="eva:clock-outline" />}
                            variant="outlined"
                            size="small"
                            // label={timespanToFriendlyText(session.duration)}
                            label={isoTimespanToTotalMinutes(contentSession?.duration as string) > 0 ? isoTimespanToFriendlyText(contentSession?.duration as string) : 'None'}
                        />
                    </Tooltip>
                </Stack>
            </Stack>
            <Stack gap={2}>
                <Stack direction='row' gap={4} alignItems='flex-start' justifyContent='flex-end'>
                    {/* <DatesProvider settings={{ timezone: timezoneWatch }}> */}
                    <RHFDateField name={sessionDateFieldName} label='Date' clearable={true} />
                    <RHFTimeField name={sessionStartFieldName} label='Start' clearable={true} />
                    <RHFTimeField name={sessionFinishFieldName} label='Finish' clearable={true} />
                    {/* </DatesProvider> */}
                </Stack>
                {errorMessages && errorMessages.length > 0 ?
                    errorMessages.map((e, index) =>
                        <Alert key={index} severity='error'>
                            {e}
                        </Alert>)
                    : undefined}
            </Stack>
        </Stack>
    )
}