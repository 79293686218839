import { emptySplitApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    abpApiDefinitionGet: build.query<
      AbpApiDefinitionGetApiResponse,
      AbpApiDefinitionGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/api-definition`,
        params: { IncludeTypes: queryArg.includeTypes },
      }),
    }),
    abpApplicationConfigurationGet: build.query<
      AbpApplicationConfigurationGetApiResponse,
      AbpApplicationConfigurationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/application-configuration`,
        params: {
          IncludeLocalizationResources: queryArg.includeLocalizationResources,
        },
      }),
    }),
    abpApplicationLocalizationGet: build.query<
      AbpApplicationLocalizationGetApiResponse,
      AbpApplicationLocalizationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/application-localization`,
        params: {
          CultureName: queryArg.cultureName,
          OnlyDynamics: queryArg.onlyDynamics,
        },
      }),
    }),
    abpTenantFindTenantByName: build.query<
      AbpTenantFindTenantByNameApiResponse,
      AbpTenantFindTenantByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/multi-tenancy/tenants/by-name/${queryArg.name}`,
      }),
    }),
    abpTenantFindTenantById: build.query<
      AbpTenantFindTenantByIdApiResponse,
      AbpTenantFindTenantByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/abp/multi-tenancy/tenants/by-id/${queryArg.id}`,
      }),
    }),
    accountRegister: build.mutation<
      AccountRegisterApiResponse,
      AccountRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/register`,
        method: "POST",
        body: queryArg.voloAbpAccountRegisterDto,
      }),
    }),
    accountSendPasswordResetCode: build.mutation<
      AccountSendPasswordResetCodeApiResponse,
      AccountSendPasswordResetCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-password-reset-code`,
        method: "POST",
        body: queryArg.voloAbpAccountSendPasswordResetCodeDto,
      }),
    }),
    accountVerifyPasswordResetToken: build.mutation<
      AccountVerifyPasswordResetTokenApiResponse,
      AccountVerifyPasswordResetTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/verify-password-reset-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyPasswordResetTokenInput,
      }),
    }),
    accountResetPassword: build.mutation<
      AccountResetPasswordApiResponse,
      AccountResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/reset-password`,
        method: "POST",
        body: queryArg.voloAbpAccountResetPasswordDto,
      }),
    }),
    accountGetConfirmationState: build.query<
      AccountGetConfirmationStateApiResponse,
      AccountGetConfirmationStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/confirmation-state`,
        params: { id: queryArg.id },
      }),
    }),
    accountSendPhoneNumberConfirmationToken: build.mutation<
      AccountSendPhoneNumberConfirmationTokenApiResponse,
      AccountSendPhoneNumberConfirmationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-phone-number-confirmation-token`,
        method: "POST",
        body: queryArg.voloAbpAccountSendPhoneNumberConfirmationTokenDto,
      }),
    }),
    accountSendEmailConfirmationToken: build.mutation<
      AccountSendEmailConfirmationTokenApiResponse,
      AccountSendEmailConfirmationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-email-confirmation-token`,
        method: "POST",
        body: queryArg.voloAbpAccountSendEmailConfirmationTokenDto,
      }),
    }),
    accountVerifyEmailConfirmationToken: build.mutation<
      AccountVerifyEmailConfirmationTokenApiResponse,
      AccountVerifyEmailConfirmationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/verify-email-confirmation-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyEmailConfirmationTokenInput,
      }),
    }),
    accountConfirmPhoneNumber: build.mutation<
      AccountConfirmPhoneNumberApiResponse,
      AccountConfirmPhoneNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/confirm-phone-number`,
        method: "POST",
        body: queryArg.voloAbpAccountConfirmPhoneNumberInput,
      }),
    }),
    accountConfirmEmail: build.mutation<
      AccountConfirmEmailApiResponse,
      AccountConfirmEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/confirm-email`,
        method: "POST",
        body: queryArg.voloAbpAccountConfirmEmailInput,
      }),
    }),
    accountSetProfilePicture: build.mutation<
      AccountSetProfilePictureApiResponse,
      AccountSetProfilePictureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/profile-picture`,
        method: "POST",
        body: queryArg.body,
        params: { Type: queryArg["type"] },
      }),
    }),
    accountGetProfilePicture: build.query<
      AccountGetProfilePictureApiResponse,
      AccountGetProfilePictureApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/profile-picture/${queryArg.id}`,
      }),
    }),
    accountGetTwoFactorProviders: build.query<
      AccountGetTwoFactorProvidersApiResponse,
      AccountGetTwoFactorProvidersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/two-factor-providers`,
        params: { UserId: queryArg.userId, Token: queryArg.token },
      }),
    }),
    accountSendTwoFactorCode: build.mutation<
      AccountSendTwoFactorCodeApiResponse,
      AccountSendTwoFactorCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/send-two-factor-code`,
        method: "POST",
        body: queryArg.voloAbpAccountSendTwoFactorCodeInput,
      }),
    }),
    accountGetSecurityLogList: build.query<
      AccountGetSecurityLogListApiResponse,
      AccountGetSecurityLogListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/security-logs`,
        params: {
          StartTime: queryArg.startTime,
          EndTime: queryArg.endTime,
          ApplicationName: queryArg.applicationName,
          Identity: queryArg.identity,
          Action: queryArg.action,
          UserName: queryArg.userName,
          ClientId: queryArg.clientId,
          CorrelationId: queryArg.correlationId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    accountVerifyAuthenticatorCode: build.mutation<
      AccountVerifyAuthenticatorCodeApiResponse,
      AccountVerifyAuthenticatorCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/verify-authenticator-code`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyAuthenticatorCodeInput,
      }),
    }),
    accountResetAuthenticator: build.mutation<
      AccountResetAuthenticatorApiResponse,
      AccountResetAuthenticatorApiArg
    >({
      query: () => ({
        url: `/api/account/reset-authenticator`,
        method: "POST",
      }),
    }),
    accountHasAuthenticator: build.query<
      AccountHasAuthenticatorApiResponse,
      AccountHasAuthenticatorApiArg
    >({
      query: () => ({ url: `/api/account/has-authenticator-key` }),
    }),
    accountGetAuthenticatorInfo: build.query<
      AccountGetAuthenticatorInfoApiResponse,
      AccountGetAuthenticatorInfoApiArg
    >({
      query: () => ({ url: `/api/account/authenticator-info` }),
    }),
    accountGetProfilePictureFile: build.query<
      AccountGetProfilePictureFileApiResponse,
      AccountGetProfilePictureFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/profile-picture-file/${queryArg.id}`,
      }),
    }),
    accountRecaptcha: build.query<
      AccountRecaptchaApiResponse,
      AccountRecaptchaApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/recaptcha-validate`,
        params: { captchaResponse: queryArg.captchaResponse },
      }),
    }),
    accountExternalProviderGetAll: build.query<
      AccountExternalProviderGetAllApiResponse,
      AccountExternalProviderGetAllApiArg
    >({
      query: () => ({ url: `/api/account/external-provider` }),
    }),
    accountExternalProviderGetByName: build.query<
      AccountExternalProviderGetByNameApiResponse,
      AccountExternalProviderGetByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/external-provider/by-name`,
        params: { TenantId: queryArg.tenantId, Name: queryArg.name },
      }),
    }),
    styles: build.query<StylesApiResponse, StylesApiArg>({
      query: () => ({ url: `/assets/dynamic/styles.css` }),
    }),
    dynamicClaimsRefresh: build.mutation<
      DynamicClaimsRefreshApiResponse,
      DynamicClaimsRefreshApiArg
    >({
      query: () => ({
        url: `/api/account/dynamic-claims/refresh`,
        method: "POST",
      }),
    }),
    accountLogin: build.mutation<AccountLoginApiResponse, AccountLoginApiArg>({
      query: (queryArg) => ({
        url: `/api/account/login`,
        method: "POST",
        body: queryArg.voloAbpAccountPublicWebAreasAccountControllersModelsUserLoginInfo,
      }),
    }),
    accountLinkLogin: build.mutation<
      AccountLinkLoginApiResponse,
      AccountLinkLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/linkLogin`,
        method: "POST",
        body: queryArg.voloAbpAccountPublicWebAreasAccountControllersModelsLinkUserLoginInfo,
      }),
    }),
    accountLogout: build.query<AccountLogoutApiResponse, AccountLogoutApiArg>({
      query: () => ({ url: `/api/account/logout` }),
    }),
    accountCheckPassword: build.mutation<
      AccountCheckPasswordApiResponse,
      AccountCheckPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/checkPassword`,
        method: "POST",
        body: queryArg.voloAbpAccountPublicWebAreasAccountControllersModelsUserLoginInfo,
      }),
    }),
    profileGet: build.query<ProfileGetApiResponse, ProfileGetApiArg>({
      query: () => ({ url: `/api/account/my-profile` }),
    }),
    profileUpdate: build.mutation<
      ProfileUpdateApiResponse,
      ProfileUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/my-profile`,
        method: "PUT",
        body: queryArg.activ8ControllersUpdateProfileDtoWithExtraPropertiesWrite,
      }),
    }),
    profileChangePassword: build.mutation<
      ProfileChangePasswordApiResponse,
      ProfileChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/my-profile/change-password`,
        method: "POST",
        body: queryArg.voloAbpAccountChangePasswordInput,
      }),
    }),
    profileGetTwoFactorEnabled: build.query<
      ProfileGetTwoFactorEnabledApiResponse,
      ProfileGetTwoFactorEnabledApiArg
    >({
      query: () => ({ url: `/api/account/my-profile/two-factor-enabled` }),
    }),
    profileSetTwoFactorEnabled: build.mutation<
      ProfileSetTwoFactorEnabledApiResponse,
      ProfileSetTwoFactorEnabledApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/my-profile/set-two-factor-enabled`,
        method: "POST",
        params: { enabled: queryArg.enabled },
      }),
    }),
    profileCanEnableTwoFactor: build.query<
      ProfileCanEnableTwoFactorApiResponse,
      ProfileCanEnableTwoFactorApiArg
    >({
      query: () => ({ url: `/api/account/my-profile/can-enable-two-factor` }),
    }),
    profileGetTimezones: build.query<
      ProfileGetTimezonesApiResponse,
      ProfileGetTimezonesApiArg
    >({
      query: () => ({ url: `/api/account/my-profile/timezones` }),
    }),
    accountSessionGetList: build.query<
      AccountSessionGetListApiResponse,
      AccountSessionGetListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/sessions`,
        params: {
          Device: queryArg.device,
          ClientId: queryArg.clientId,
          Sorting: queryArg.sorting,
          SkipCount: queryArg.skipCount,
          MaxResultCount: queryArg.maxResultCount,
          ExtraProperties: queryArg.extraProperties,
        },
      }),
    }),
    accountSessionGet: build.query<
      AccountSessionGetApiResponse,
      AccountSessionGetApiArg
    >({
      query: (queryArg) => ({ url: `/api/account/sessions/${queryArg.id}` }),
    }),
    accountSessionRevoke: build.mutation<
      AccountSessionRevokeApiResponse,
      AccountSessionRevokeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/sessions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    identityLinkUserLink: build.mutation<
      IdentityLinkUserLinkApiResponse,
      IdentityLinkUserLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/link`,
        method: "POST",
        body: queryArg.voloAbpAccountLinkUserInput,
      }),
    }),
    identityLinkUserUnlink: build.mutation<
      IdentityLinkUserUnlinkApiResponse,
      IdentityLinkUserUnlinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/unlink`,
        method: "POST",
        body: queryArg.voloAbpAccountUnLinkUserInput,
      }),
    }),
    identityLinkUserIsLinked: build.mutation<
      IdentityLinkUserIsLinkedApiResponse,
      IdentityLinkUserIsLinkedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/is-linked`,
        method: "POST",
        body: queryArg.voloAbpAccountIsLinkedInput,
      }),
    }),
    identityLinkUserGenerateLinkToken: build.mutation<
      IdentityLinkUserGenerateLinkTokenApiResponse,
      IdentityLinkUserGenerateLinkTokenApiArg
    >({
      query: () => ({
        url: `/api/account/link-user/generate-link-token`,
        method: "POST",
      }),
    }),
    identityLinkUserVerifyLinkToken: build.mutation<
      IdentityLinkUserVerifyLinkTokenApiResponse,
      IdentityLinkUserVerifyLinkTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/verify-link-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyLinkTokenInput,
      }),
    }),
    identityLinkUserGenerateLinkLoginToken: build.mutation<
      IdentityLinkUserGenerateLinkLoginTokenApiResponse,
      IdentityLinkUserGenerateLinkLoginTokenApiArg
    >({
      query: () => ({
        url: `/api/account/link-user/generate-link-login-token`,
        method: "POST",
      }),
    }),
    identityLinkUserVerifyLinkLoginToken: build.mutation<
      IdentityLinkUserVerifyLinkLoginTokenApiResponse,
      IdentityLinkUserVerifyLinkLoginTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/link-user/verify-link-login-token`,
        method: "POST",
        body: queryArg.voloAbpAccountVerifyLinkLoginTokenInput,
      }),
    }),
    identityLinkUserGetAllList: build.query<
      IdentityLinkUserGetAllListApiResponse,
      IdentityLinkUserGetAllListApiArg
    >({
      query: () => ({ url: `/api/account/link-user` }),
    }),
    identityUserDelegationGetDelegatedUsers: build.query<
      IdentityUserDelegationGetDelegatedUsersApiResponse,
      IdentityUserDelegationGetDelegatedUsersApiArg
    >({
      query: () => ({ url: `/api/account/user-delegation/delegated-users` }),
    }),
    identityUserDelegationGetMyDelegatedUsers: build.query<
      IdentityUserDelegationGetMyDelegatedUsersApiResponse,
      IdentityUserDelegationGetMyDelegatedUsersApiArg
    >({
      query: () => ({ url: `/api/account/user-delegation/my-delegated-users` }),
    }),
    identityUserDelegationGetActiveDelegations: build.query<
      IdentityUserDelegationGetActiveDelegationsApiResponse,
      IdentityUserDelegationGetActiveDelegationsApiArg
    >({
      query: () => ({ url: `/api/account/user-delegation/active-delegations` }),
    }),
    identityUserDelegationGetUserLookup: build.query<
      IdentityUserDelegationGetUserLookupApiResponse,
      IdentityUserDelegationGetUserLookupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/user-delegation/user-lookup`,
        params: { UserName: queryArg.userName },
      }),
    }),
    identityUserDelegationDelegateNewUser: build.mutation<
      IdentityUserDelegationDelegateNewUserApiResponse,
      IdentityUserDelegationDelegateNewUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/user-delegation/delegate-new-user`,
        method: "POST",
        body: queryArg.voloAbpAccountDelegateNewUserInput,
      }),
    }),
    identityUserDelegationDeleteDelegation: build.mutation<
      IdentityUserDelegationDeleteDelegationApiResponse,
      IdentityUserDelegationDeleteDelegationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/user-delegation/delete-delegation`,
        method: "POST",
        params: { id: queryArg.id },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accountApi };
export type AbpApiDefinitionGetApiResponse =
  /** status 200 Success */ VoloAbpHttpModelingApplicationApiDescriptionModel;
export type AbpApiDefinitionGetApiArg = {
  includeTypes?: boolean;
};
export type AbpApplicationConfigurationGetApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDtoRead;
export type AbpApplicationConfigurationGetApiArg = {
  includeLocalizationResources?: boolean;
};
export type AbpApplicationLocalizationGetApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationDto;
export type AbpApplicationLocalizationGetApiArg = {
  cultureName: string;
  onlyDynamics?: boolean;
};
export type AbpTenantFindTenantByNameApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto;
export type AbpTenantFindTenantByNameApiArg = {
  name: string;
};
export type AbpTenantFindTenantByIdApiResponse =
  /** status 200 Success */ VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto;
export type AbpTenantFindTenantByIdApiArg = {
  id: string;
};
export type AccountRegisterApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentityUserDtoRead;
export type AccountRegisterApiArg = {
  voloAbpAccountRegisterDto: VoloAbpAccountRegisterDto;
};
export type AccountSendPasswordResetCodeApiResponse =
  /** status 200 Success */ void;
export type AccountSendPasswordResetCodeApiArg = {
  voloAbpAccountSendPasswordResetCodeDto: VoloAbpAccountSendPasswordResetCodeDto;
};
export type AccountVerifyPasswordResetTokenApiResponse =
  /** status 200 Success */ boolean;
export type AccountVerifyPasswordResetTokenApiArg = {
  voloAbpAccountVerifyPasswordResetTokenInput: VoloAbpAccountVerifyPasswordResetTokenInput;
};
export type AccountResetPasswordApiResponse = /** status 200 Success */ void;
export type AccountResetPasswordApiArg = {
  voloAbpAccountResetPasswordDto: VoloAbpAccountResetPasswordDto;
};
export type AccountGetConfirmationStateApiResponse =
  /** status 200 Success */ VoloAbpAccountIdentityUserConfirmationStateDto;
export type AccountGetConfirmationStateApiArg = {
  id?: string;
};
export type AccountSendPhoneNumberConfirmationTokenApiResponse =
  /** status 200 Success */ void;
export type AccountSendPhoneNumberConfirmationTokenApiArg = {
  voloAbpAccountSendPhoneNumberConfirmationTokenDto: VoloAbpAccountSendPhoneNumberConfirmationTokenDto;
};
export type AccountSendEmailConfirmationTokenApiResponse =
  /** status 200 Success */ void;
export type AccountSendEmailConfirmationTokenApiArg = {
  voloAbpAccountSendEmailConfirmationTokenDto: VoloAbpAccountSendEmailConfirmationTokenDto;
};
export type AccountVerifyEmailConfirmationTokenApiResponse =
  /** status 200 Success */ boolean;
export type AccountVerifyEmailConfirmationTokenApiArg = {
  voloAbpAccountVerifyEmailConfirmationTokenInput: VoloAbpAccountVerifyEmailConfirmationTokenInput;
};
export type AccountConfirmPhoneNumberApiResponse =
  /** status 200 Success */ void;
export type AccountConfirmPhoneNumberApiArg = {
  voloAbpAccountConfirmPhoneNumberInput: VoloAbpAccountConfirmPhoneNumberInput;
};
export type AccountConfirmEmailApiResponse = /** status 200 Success */ void;
export type AccountConfirmEmailApiArg = {
  voloAbpAccountConfirmEmailInput: VoloAbpAccountConfirmEmailInput;
};
export type AccountSetProfilePictureApiResponse =
  /** status 200 Success */ void;
export type AccountSetProfilePictureApiArg = {
  type?: VoloAbpAccountProfilePictureType;
  body: {
    ImageContent?: Blob;
  };
};
export type AccountGetProfilePictureApiResponse =
  /** status 200 Success */ VoloAbpAccountProfilePictureSourceDto;
export type AccountGetProfilePictureApiArg = {
  id: string;
};
export type AccountGetTwoFactorProvidersApiResponse =
  /** status 200 Success */ string[];
export type AccountGetTwoFactorProvidersApiArg = {
  userId: string;
  token: string;
};
export type AccountSendTwoFactorCodeApiResponse =
  /** status 200 Success */ void;
export type AccountSendTwoFactorCodeApiArg = {
  voloAbpAccountSendTwoFactorCodeInput: VoloAbpAccountSendTwoFactorCodeInput;
};
export type AccountGetSecurityLogListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type AccountGetSecurityLogListApiArg = {
  startTime?: string;
  endTime?: string;
  applicationName?: string;
  identity?: string;
  action?: string;
  userName?: string;
  clientId?: string;
  correlationId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type AccountVerifyAuthenticatorCodeApiResponse =
  /** status 200 Success */ VoloAbpAccountVerifyAuthenticatorCodeDto;
export type AccountVerifyAuthenticatorCodeApiArg = {
  voloAbpAccountVerifyAuthenticatorCodeInput: VoloAbpAccountVerifyAuthenticatorCodeInput;
};
export type AccountResetAuthenticatorApiResponse =
  /** status 200 Success */ void;
export type AccountResetAuthenticatorApiArg = void;
export type AccountHasAuthenticatorApiResponse =
  /** status 200 Success */ boolean;
export type AccountHasAuthenticatorApiArg = void;
export type AccountGetAuthenticatorInfoApiResponse =
  /** status 200 Success */ VoloAbpAccountAuthenticatorInfoDto;
export type AccountGetAuthenticatorInfoApiArg = void;
export type AccountGetProfilePictureFileApiResponse =
  /** status 200 Success */ Blob;
export type AccountGetProfilePictureFileApiArg = {
  id: string;
};
export type AccountRecaptchaApiResponse = /** status 200 Success */ void;
export type AccountRecaptchaApiArg = {
  captchaResponse?: string;
};
export type AccountExternalProviderGetAllApiResponse =
  /** status 200 Success */ VoloAbpAccountExternalProvidersExternalProviderDto;
export type AccountExternalProviderGetAllApiArg = void;
export type AccountExternalProviderGetByNameApiResponse =
  /** status 200 Success */ VoloAbpAccountExternalProvidersExternalProviderItemWithSecretDto;
export type AccountExternalProviderGetByNameApiArg = {
  tenantId?: string;
  name?: string;
};
export type StylesApiResponse = unknown;
export type StylesApiArg = void;
export type DynamicClaimsRefreshApiResponse = /** status 200 Success */ void;
export type DynamicClaimsRefreshApiArg = void;
export type AccountLoginApiResponse =
  /** status 200 Success */ VoloAbpAccountPublicWebAreasAccountControllersModelsAbpLoginResultRead;
export type AccountLoginApiArg = {
  voloAbpAccountPublicWebAreasAccountControllersModelsUserLoginInfo: VoloAbpAccountPublicWebAreasAccountControllersModelsUserLoginInfo;
};
export type AccountLinkLoginApiResponse =
  /** status 200 Success */ VoloAbpAccountPublicWebAreasAccountControllersModelsAbpLoginResultRead;
export type AccountLinkLoginApiArg = {
  voloAbpAccountPublicWebAreasAccountControllersModelsLinkUserLoginInfo: VoloAbpAccountPublicWebAreasAccountControllersModelsLinkUserLoginInfo;
};
export type AccountLogoutApiResponse = /** status 200 Success */ void;
export type AccountLogoutApiArg = void;
export type AccountCheckPasswordApiResponse =
  /** status 200 Success */ VoloAbpAccountPublicWebAreasAccountControllersModelsAbpLoginResultRead;
export type AccountCheckPasswordApiArg = {
  voloAbpAccountPublicWebAreasAccountControllersModelsUserLoginInfo: VoloAbpAccountPublicWebAreasAccountControllersModelsUserLoginInfo;
};
export type ProfileGetApiResponse =
  /** status 200 Success */ VoloAbpAccountProfileDtoRead;
export type ProfileGetApiArg = void;
export type ProfileUpdateApiResponse =
  /** status 200 Success */ VoloAbpAccountProfileDtoRead;
export type ProfileUpdateApiArg = {
  activ8ControllersUpdateProfileDtoWithExtraPropertiesWrite: Activ8ControllersUpdateProfileDtoWithExtraPropertiesWrite;
};
export type ProfileChangePasswordApiResponse = /** status 200 Success */ void;
export type ProfileChangePasswordApiArg = {
  voloAbpAccountChangePasswordInput: VoloAbpAccountChangePasswordInput;
};
export type ProfileGetTwoFactorEnabledApiResponse =
  /** status 200 Success */ boolean;
export type ProfileGetTwoFactorEnabledApiArg = void;
export type ProfileSetTwoFactorEnabledApiResponse =
  /** status 200 Success */ void;
export type ProfileSetTwoFactorEnabledApiArg = {
  enabled?: boolean;
};
export type ProfileCanEnableTwoFactorApiResponse =
  /** status 200 Success */ boolean;
export type ProfileCanEnableTwoFactorApiArg = void;
export type ProfileGetTimezonesApiResponse =
  /** status 200 Success */ VoloAbpNameValue[];
export type ProfileGetTimezonesApiArg = void;
export type AccountSessionGetListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySessionDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type AccountSessionGetListApiArg = {
  device?: string;
  clientId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
  extraProperties?: {
    [key: string]: any;
  };
};
export type AccountSessionGetApiResponse =
  /** status 200 Success */ VoloAbpIdentityIdentitySessionDto;
export type AccountSessionGetApiArg = {
  id: string;
};
export type AccountSessionRevokeApiResponse = /** status 200 Success */ void;
export type AccountSessionRevokeApiArg = {
  id: string;
};
export type IdentityLinkUserLinkApiResponse = /** status 200 Success */ void;
export type IdentityLinkUserLinkApiArg = {
  voloAbpAccountLinkUserInput: VoloAbpAccountLinkUserInput;
};
export type IdentityLinkUserUnlinkApiResponse = /** status 200 Success */ void;
export type IdentityLinkUserUnlinkApiArg = {
  voloAbpAccountUnLinkUserInput: VoloAbpAccountUnLinkUserInput;
};
export type IdentityLinkUserIsLinkedApiResponse =
  /** status 200 Success */ boolean;
export type IdentityLinkUserIsLinkedApiArg = {
  voloAbpAccountIsLinkedInput: VoloAbpAccountIsLinkedInput;
};
export type IdentityLinkUserGenerateLinkTokenApiResponse =
  /** status 200 Success */ string;
export type IdentityLinkUserGenerateLinkTokenApiArg = void;
export type IdentityLinkUserVerifyLinkTokenApiResponse =
  /** status 200 Success */ boolean;
export type IdentityLinkUserVerifyLinkTokenApiArg = {
  voloAbpAccountVerifyLinkTokenInput: VoloAbpAccountVerifyLinkTokenInput;
};
export type IdentityLinkUserGenerateLinkLoginTokenApiResponse =
  /** status 200 Success */ string;
export type IdentityLinkUserGenerateLinkLoginTokenApiArg = void;
export type IdentityLinkUserVerifyLinkLoginTokenApiResponse =
  /** status 200 Success */ boolean;
export type IdentityLinkUserVerifyLinkLoginTokenApiArg = {
  voloAbpAccountVerifyLinkLoginTokenInput: VoloAbpAccountVerifyLinkLoginTokenInput;
};
export type IdentityLinkUserGetAllListApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountLinkUserDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityLinkUserGetAllListApiArg = void;
export type IdentityUserDelegationGetDelegatedUsersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetDelegatedUsersApiArg = void;
export type IdentityUserDelegationGetMyDelegatedUsersApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetMyDelegatedUsersApiArg = void;
export type IdentityUserDelegationGetActiveDelegationsApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetActiveDelegationsApiArg = void;
export type IdentityUserDelegationGetUserLookupApiResponse =
  /** status 200 Success */ VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserLookupDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull;
export type IdentityUserDelegationGetUserLookupApiArg = {
  userName?: string;
};
export type IdentityUserDelegationDelegateNewUserApiResponse =
  /** status 200 Success */ void;
export type IdentityUserDelegationDelegateNewUserApiArg = {
  voloAbpAccountDelegateNewUserInput: VoloAbpAccountDelegateNewUserInput;
};
export type IdentityUserDelegationDeleteDelegationApiResponse =
  /** status 200 Success */ void;
export type IdentityUserDelegationDeleteDelegationApiArg = {
  id?: string;
};
export type VoloAbpHttpModelingMethodParameterApiDescriptionModel = {
  name?: string | null;
  typeAsString?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any | null;
};
export type VoloAbpHttpModelingReturnValueApiDescriptionModel = {
  type?: string | null;
  typeSimple?: string | null;
};
export type VoloAbpHttpModelingInterfaceMethodApiDescriptionModel = {
  name?: string | null;
  parametersOnMethod?:
    | VoloAbpHttpModelingMethodParameterApiDescriptionModel[]
    | null;
  returnValue?: VoloAbpHttpModelingReturnValueApiDescriptionModel;
};
export type VoloAbpHttpModelingControllerInterfaceApiDescriptionModel = {
  type?: string | null;
  name?: string | null;
  methods?: VoloAbpHttpModelingInterfaceMethodApiDescriptionModel[] | null;
};
export type VoloAbpHttpModelingParameterApiDescriptionModel = {
  nameOnMethod?: string | null;
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any | null;
  constraintTypes?: string[] | null;
  bindingSourceId?: string | null;
  descriptorName?: string | null;
};
export type VoloAbpHttpModelingActionApiDescriptionModel = {
  uniqueName?: string | null;
  name?: string | null;
  httpMethod?: string | null;
  url?: string | null;
  supportedVersions?: string[] | null;
  parametersOnMethod?:
    | VoloAbpHttpModelingMethodParameterApiDescriptionModel[]
    | null;
  parameters?: VoloAbpHttpModelingParameterApiDescriptionModel[] | null;
  returnValue?: VoloAbpHttpModelingReturnValueApiDescriptionModel;
  allowAnonymous?: boolean | null;
  implementFrom?: string | null;
};
export type VoloAbpHttpModelingControllerApiDescriptionModel = {
  controllerName?: string | null;
  controllerGroupName?: string | null;
  isRemoteService?: boolean;
  isIntegrationService?: boolean;
  apiVersion?: string | null;
  type?: string | null;
  interfaces?:
    | VoloAbpHttpModelingControllerInterfaceApiDescriptionModel[]
    | null;
  actions?: {
    [key: string]: VoloAbpHttpModelingActionApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpModelingModuleApiDescriptionModel = {
  rootPath?: string | null;
  remoteServiceName?: string | null;
  controllers?: {
    [key: string]: VoloAbpHttpModelingControllerApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpModelingPropertyApiDescriptionModel = {
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isRequired?: boolean;
  minLength?: number | null;
  maxLength?: number | null;
  minimum?: string | null;
  maximum?: string | null;
  regex?: string | null;
};
export type VoloAbpHttpModelingTypeApiDescriptionModel = {
  baseType?: string | null;
  isEnum?: boolean;
  enumNames?: string[] | null;
  enumValues?: any[] | null;
  genericArguments?: string[] | null;
  properties?: VoloAbpHttpModelingPropertyApiDescriptionModel[] | null;
};
export type VoloAbpHttpModelingApplicationApiDescriptionModel = {
  modules?: {
    [key: string]: VoloAbpHttpModelingModuleApiDescriptionModel;
  } | null;
  types?: {
    [key: string]: VoloAbpHttpModelingTypeApiDescriptionModel;
  } | null;
};
export type VoloAbpHttpRemoteServiceValidationErrorInfo = {
  message?: string | null;
  members?: string[] | null;
};
export type VoloAbpHttpRemoteServiceErrorInfo = {
  code?: string | null;
  message?: string | null;
  details?: string | null;
  data?: {
    [key: string]: any;
  } | null;
  validationErrors?: VoloAbpHttpRemoteServiceValidationErrorInfo[] | null;
};
export type VoloAbpHttpRemoteServiceErrorResponse = {
  error?: VoloAbpHttpRemoteServiceErrorInfo;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto =
  {
    texts?: {
      [key: string]: string;
    } | null;
    baseResources?: string[] | null;
  };
export type VoloAbpLocalizationLanguageInfo = {
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
};
export type VoloAbpLocalizationLanguageInfoRead = {
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
  twoLetterISOLanguageName?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto = {
  calendarAlgorithmType?: string | null;
  dateTimeFormatLong?: string | null;
  shortDatePattern?: string | null;
  fullDateTimePattern?: string | null;
  dateSeparator?: string | null;
  shortTimePattern?: string | null;
  longTimePattern?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto = {
  displayName?: string | null;
  englishName?: string | null;
  threeLetterIsoLanguageName?: string | null;
  twoLetterIsoLanguageName?: string | null;
  isRightToLeft?: boolean;
  cultureName?: string | null;
  name?: string | null;
  nativeName?: string | null;
  dateTimeFormat?: VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto;
};
export type VoloAbpNameValue = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto =
  {
    values?: {
      [key: string]: {
        [key: string]: string;
      };
    } | null;
    resources?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto;
    } | null;
    languages?: VoloAbpLocalizationLanguageInfo[] | null;
    currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
    defaultResourceName?: string | null;
    languagesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
    languageFilesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDtoRead =
  {
    values?: {
      [key: string]: {
        [key: string]: string;
      };
    } | null;
    resources?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto;
    } | null;
    languages?: VoloAbpLocalizationLanguageInfoRead[] | null;
    currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
    defaultResourceName?: string | null;
    languagesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
    languageFilesMap?: {
      [key: string]: VoloAbpNameValue[];
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto =
  {
    grantedPolicies?: {
      [key: string]: boolean;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto =
  {
    values?: {
      [key: string]: string | null;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto = {
  isAuthenticated?: boolean;
  id?: string | null;
  tenantId?: string | null;
  impersonatorUserId?: string | null;
  impersonatorTenantId?: string | null;
  impersonatorUserName?: string | null;
  impersonatorTenantName?: string | null;
  userName?: string | null;
  name?: string | null;
  surName?: string | null;
  email?: string | null;
  emailVerified?: boolean;
  phoneNumber?: string | null;
  phoneNumberVerified?: boolean;
  roles?: string[] | null;
  sessionId?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto =
  {
    values?: {
      [key: string]: string | null;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto =
  {
    enabledFeatures?: string[] | null;
  };
export type VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto = {
  isEnabled?: boolean;
};
export type VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto = {
  id?: string | null;
  name?: string | null;
  isAvailable?: boolean;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone = {
  timeZoneName?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone = {
  timeZoneId?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone = {
  iana?: VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone;
  windows?: VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto = {
  timeZone?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto = {
  kind?: string | null;
};
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto =
  {
    name?: string | null;
    resource?: string | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto =
  {
    isAvailable?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto =
  {
    onGet?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto;
    onCreate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto;
    onUpdate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto =
  {
    isVisible?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto =
  {
    isVisible?: boolean;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto =
  {
    url?: string | null;
    resultListPropertyName?: string | null;
    displayPropertyName?: string | null;
    valuePropertyName?: string | null;
    filterParamName?: string | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto =
  {
    onTable?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto;
    onCreateForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
    onEditForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
    lookup?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto =
  {
    typeSimple?: string | null;
    config?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto =
  {
    type?: string | null;
    typeSimple?: string | null;
    displayName?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto;
    api?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto;
    ui?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto;
    attributes?:
      | VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto[]
      | null;
    configuration?: {
      [key: string]: any;
    } | null;
    defaultValue?: any | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto =
  {
    properties?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto;
    } | null;
    configuration?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto =
  {
    entities?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto;
    } | null;
    configuration?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto =
  {
    name?: string | null;
    value?: any | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto =
  {
    fields?:
      | VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto[]
      | null;
    localizationResource?: string | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto =
  {
    modules?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto;
    } | null;
    enums?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto =
  {
    localization?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto;
    auth?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto;
    setting?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto;
    currentUser?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto;
    features?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto;
    globalFeatures?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto;
    multiTenancy?: VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto;
    currentTenant?: VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto;
    timing?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto;
    clock?: VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto;
    objectExtensions?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto;
    extraProperties?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDtoRead =
  {
    localization?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDtoRead;
    auth?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto;
    setting?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto;
    currentUser?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto;
    features?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto;
    globalFeatures?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto;
    multiTenancy?: VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto;
    currentTenant?: VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto;
    timing?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto;
    clock?: VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto;
    objectExtensions?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto;
    extraProperties?: {
      [key: string]: any;
    } | null;
  };
export type VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationDto =
  {
    resources?: {
      [
        key: string
      ]: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto;
    } | null;
    currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
  };
export type VoloAbpAspNetCoreMvcMultiTenancyFindTenantResultDto = {
  success?: boolean;
  tenantId?: string | null;
  name?: string | null;
  normalizedName?: string | null;
  isActive?: boolean;
};
export type VoloAbpIdentityIdentityUserDto = {
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  shouldChangePasswordOnNextLogin?: boolean;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  lastPasswordChangeTime?: string | null;
  isExternal?: boolean;
};
export type VoloAbpIdentityIdentityUserDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  id?: string;
  creationTime?: string;
  creatorId?: string | null;
  lastModificationTime?: string | null;
  lastModifierId?: string | null;
  isDeleted?: boolean;
  deleterId?: string | null;
  deletionTime?: string | null;
  tenantId?: string | null;
  userName?: string | null;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  emailConfirmed?: boolean;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  supportTwoFactor?: boolean;
  twoFactorEnabled?: boolean;
  isActive?: boolean;
  lockoutEnabled?: boolean;
  isLockedOut?: boolean;
  lockoutEnd?: string | null;
  shouldChangePasswordOnNextLogin?: boolean;
  concurrencyStamp?: string | null;
  roleNames?: string[] | null;
  accessFailedCount?: number;
  lastPasswordChangeTime?: string | null;
  isExternal?: boolean;
};
export type VoloAbpAccountRegisterDto = {
  userName: string;
  emailAddress: string;
  password: string;
  appName: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
  captchaResponse?: string | null;
};
export type VoloAbpAccountRegisterDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  emailAddress: string;
  password: string;
  appName: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
  captchaResponse?: string | null;
};
export type VoloAbpAccountSendPasswordResetCodeDto = {
  email: string;
  appName: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
};
export type VoloAbpAccountVerifyPasswordResetTokenInput = {
  userId?: string;
  resetToken: string;
};
export type VoloAbpAccountResetPasswordDto = {
  userId?: string;
  resetToken: string;
  password: string;
};
export type VoloAbpAccountIdentityUserConfirmationStateDto = {
  emailConfirmed?: boolean;
  phoneNumberConfirmed?: boolean;
};
export type VoloAbpAccountSendPhoneNumberConfirmationTokenDto = {
  userId: string;
  phoneNumber?: string | null;
};
export type VoloAbpAccountSendEmailConfirmationTokenDto = {
  appName: string;
  userId: string;
  returnUrl?: string | null;
  returnUrlHash?: string | null;
};
export type VoloAbpAccountVerifyEmailConfirmationTokenInput = {
  userId: string;
  token: string;
};
export type VoloAbpAccountConfirmPhoneNumberInput = {
  userId: string;
  token: string;
};
export type VoloAbpAccountConfirmEmailInput = {
  userId: string;
  token: string;
};
export type VoloAbpAccountProfilePictureType = "None" | "Gravatar" | "Image";
export type VoloAbpAccountProfilePictureSourceDto = {
  type?: VoloAbpAccountProfilePictureType;
  source?: string | null;
  fileContent?: string | null;
};
export type VoloAbpAccountSendTwoFactorCodeInput = {
  userId: string;
  provider: string;
  token: string;
};
export type VoloAbpIdentityIdentitySecurityLogDto = {
  id?: string;
  tenantId?: string | null;
  applicationName?: string | null;
  identity?: string | null;
  action?: string | null;
  userId?: string | null;
  userName?: string | null;
  tenantName?: string | null;
  clientId?: string | null;
  correlationId?: string | null;
  clientIpAddress?: string | null;
  browserInfo?: string | null;
  creationTime?: string;
  extraProperties?: {
    [key: string]: any | null;
  } | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySecurityLogDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentitySecurityLogDto[] | null;
    totalCount?: number;
  };
export type VoloAbpAccountVerifyAuthenticatorCodeDto = {
  recoveryCodes?: string[] | null;
};
export type VoloAbpAccountVerifyAuthenticatorCodeInput = {
  code?: string | null;
};
export type VoloAbpAccountAuthenticatorInfoDto = {
  key?: string | null;
  uri?: string | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderSettingsProperty = {
  name?: string | null;
  value?: string | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderItemDto = {
  name?: string | null;
  enabled?: boolean;
  properties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderDto = {
  providers?: VoloAbpAccountExternalProvidersExternalProviderItemDto[] | null;
};
export type VoloAbpAccountExternalProvidersExternalProviderItemWithSecretDto = {
  success?: boolean;
  name?: string | null;
  enabled?: boolean;
  properties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
  secretProperties?:
    | VoloAbpAccountExternalProvidersExternalProviderSettingsProperty[]
    | null;
};
export type VoloAbpAccountPublicWebAreasAccountControllersModelsLoginResultType =

    | "Success"
    | "InvalidUserNameOrPassword"
    | "NotAllowed"
    | "LockedOut"
    | "RequiresTwoFactor"
    | "NotLinked";
export type VoloAbpAccountPublicWebAreasAccountControllersModelsAbpLoginResult =
  {
    result?: VoloAbpAccountPublicWebAreasAccountControllersModelsLoginResultType;
  };
export type VoloAbpAccountPublicWebAreasAccountControllersModelsAbpLoginResultRead =
  {
    result?: VoloAbpAccountPublicWebAreasAccountControllersModelsLoginResultType;
    description?: string | null;
  };
export type VoloAbpAccountPublicWebAreasAccountControllersModelsUserLoginInfo =
  {
    userNameOrEmailAddress: string;
    password: string;
    rememberMe?: boolean;
    tenantId?: string | null;
  };
export type VoloAbpAccountPublicWebAreasAccountControllersModelsLinkUserLoginInfo =
  {
    linkUserId: string;
    linkTenantId?: string | null;
  };
export type VoloAbpAccountProfileDto = {
  userName?: string | null;
  email?: string | null;
  emailConfirmed?: boolean;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  isExternal?: boolean;
  hasPassword?: boolean;
  supportsMultipleTimezone?: boolean;
  timezone?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpAccountProfileDtoRead = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName?: string | null;
  email?: string | null;
  emailConfirmed?: boolean;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  isExternal?: boolean;
  hasPassword?: boolean;
  supportsMultipleTimezone?: boolean;
  timezone?: string | null;
  concurrencyStamp?: string | null;
};
export type Activ8ControllersUpdateProfileDtoWithExtraPropertiesWrite = {
  extraProperties?: {
    [key: string]: any;
  } | null;
  userName: string;
  email?: string | null;
  name?: string | null;
  surname?: string | null;
  phoneNumber?: string | null;
  timezone?: string | null;
  concurrencyStamp?: string | null;
};
export type VoloAbpAccountChangePasswordInput = {
  currentPassword?: string | null;
  newPassword: string;
};
export type VoloAbpIdentityIdentitySessionDto = {
  id?: string;
  sessionId?: string | null;
  isCurrent?: boolean;
  device?: string | null;
  deviceInfo?: string | null;
  tenantId?: string | null;
  tenantName?: string | null;
  userId?: string;
  userName?: string | null;
  clientId?: string | null;
  ipAddresses?: string[] | null;
  signedIn?: string;
  lastAccessed?: string | null;
};
export type VoloAbpApplicationDtosPagedResultDto601VoloAbpIdentityIdentitySessionDto20VoloAbpIdentityProApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpIdentityIdentitySessionDto[] | null;
    totalCount?: number;
  };
export type VoloAbpAccountLinkUserInput = {
  userId?: string;
  tenantId?: string | null;
  token: string;
};
export type VoloAbpAccountUnLinkUserInput = {
  userId?: string;
  tenantId?: string | null;
};
export type VoloAbpAccountIsLinkedInput = {
  userId?: string;
  tenantId?: string | null;
};
export type VoloAbpAccountVerifyLinkTokenInput = {
  userId: string;
  tenantId?: string | null;
  token: string;
};
export type VoloAbpAccountVerifyLinkLoginTokenInput = {
  userId: string;
  tenantId?: string | null;
  token: string;
};
export type VoloAbpAccountLinkUserDto = {
  targetUserId?: string;
  targetUserName?: string | null;
  targetTenantId?: string | null;
  targetTenantName?: string | null;
  directlyLinked?: boolean;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpAccountLinkUserDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAccountLinkUserDto[] | null;
  };
export type VoloAbpAccountUserDelegationDto = {
  id?: string;
  userName?: string | null;
  startTime?: string;
  endTime?: string;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserDelegationDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAccountUserDelegationDto[] | null;
  };
export type VoloAbpAccountUserLookupDto = {
  id?: string;
  userName?: string | null;
};
export type VoloAbpApplicationDtosListResultDto601VoloAbpAccountUserLookupDto20VoloAbpAccountProPublicApplicationContracts20Version831020CultureNeutral20PublicKeyTokenNull =
  {
    items?: VoloAbpAccountUserLookupDto[] | null;
  };
export type VoloAbpAccountDelegateNewUserInput = {
  targetUserId?: string;
  startTime: string;
  endTime: string;
};
export const {
  useAbpApiDefinitionGetQuery,
  useAbpApplicationConfigurationGetQuery,
  useAbpApplicationLocalizationGetQuery,
  useAbpTenantFindTenantByNameQuery,
  useAbpTenantFindTenantByIdQuery,
  useAccountRegisterMutation,
  useAccountSendPasswordResetCodeMutation,
  useAccountVerifyPasswordResetTokenMutation,
  useAccountResetPasswordMutation,
  useAccountGetConfirmationStateQuery,
  useAccountSendPhoneNumberConfirmationTokenMutation,
  useAccountSendEmailConfirmationTokenMutation,
  useAccountVerifyEmailConfirmationTokenMutation,
  useAccountConfirmPhoneNumberMutation,
  useAccountConfirmEmailMutation,
  useAccountSetProfilePictureMutation,
  useAccountGetProfilePictureQuery,
  useAccountGetTwoFactorProvidersQuery,
  useAccountSendTwoFactorCodeMutation,
  useAccountGetSecurityLogListQuery,
  useAccountVerifyAuthenticatorCodeMutation,
  useAccountResetAuthenticatorMutation,
  useAccountHasAuthenticatorQuery,
  useAccountGetAuthenticatorInfoQuery,
  useAccountGetProfilePictureFileQuery,
  useAccountRecaptchaQuery,
  useAccountExternalProviderGetAllQuery,
  useAccountExternalProviderGetByNameQuery,
  useStylesQuery,
  useDynamicClaimsRefreshMutation,
  useAccountLoginMutation,
  useAccountLinkLoginMutation,
  useAccountLogoutQuery,
  useAccountCheckPasswordMutation,
  useProfileGetQuery,
  useProfileUpdateMutation,
  useProfileChangePasswordMutation,
  useProfileGetTwoFactorEnabledQuery,
  useProfileSetTwoFactorEnabledMutation,
  useProfileCanEnableTwoFactorQuery,
  useProfileGetTimezonesQuery,
  useAccountSessionGetListQuery,
  useAccountSessionGetQuery,
  useAccountSessionRevokeMutation,
  useIdentityLinkUserLinkMutation,
  useIdentityLinkUserUnlinkMutation,
  useIdentityLinkUserIsLinkedMutation,
  useIdentityLinkUserGenerateLinkTokenMutation,
  useIdentityLinkUserVerifyLinkTokenMutation,
  useIdentityLinkUserGenerateLinkLoginTokenMutation,
  useIdentityLinkUserVerifyLinkLoginTokenMutation,
  useIdentityLinkUserGetAllListQuery,
  useIdentityUserDelegationGetDelegatedUsersQuery,
  useIdentityUserDelegationGetMyDelegatedUsersQuery,
  useIdentityUserDelegationGetActiveDelegationsQuery,
  useIdentityUserDelegationGetUserLookupQuery,
  useIdentityUserDelegationDelegateNewUserMutation,
  useIdentityUserDelegationDeleteDelegationMutation,
} = injectedRtkApi;
