import { kebabCase } from 'change-case';
// sections
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Activ8CoursesTemplatesLearningCourseTemplateStatus, appApi
} from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useAppUserContext from 'src/hooks/useAppUserContext';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import {
  LearningCourseTemplateTableRow, LearningCourseTemplateTableToolbar
} from 'src/sections/courses/templates/list';
import DropDownMenuButton from 'src/sections/shared/DropDownMenuButton';

// @mui
import {
  Alert, Box, Button, Card, Container, IconButton, MenuItem, Stack, Table, TableBody,
  TableContainer, TablePagination, Tooltip, Typography
} from '@mui/material';

// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import {
  TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions
} from '../../../components/table';
// hooks
import useSettings from '../../../hooks/useSettings';
import useTable, { emptyRows } from '../../../hooks/useTable';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'draft', 'paused', 'live'];

// ----------------------------------------------------------------------

export default function LearningCourseTemplateList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'internalName' });

  const { themeStretch } = useSettings();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const permissionChecker = usePermissionChecker();
  const appUserContext = useAppUserContext();
  const hasExportPermission = permissionChecker.has(permissionKeys.master.course.export);
  const [triggerTemplateExportPost] = appApi.endpoints.dataExportTriggerCourseTemplateExportEmail.useMutation();
  const [triggerProgramExportPost] = appApi.endpoints.dataExportTriggerCourseProgramExportEmail.useMutation();
  const [triggerParticipantExportPost] = appApi.endpoints.dataExportTriggerCourseProgramParticipantExportEmail.useMutation();
  const [getCourseContentPackageDownloadUrlQuery] = appApi.endpoints.learningCourseTemplateGenerateUrlForDownloadCourseContent.useLazyQuery();

  const triggerExport = async (type: 'participants in programs' | 'course programs' | 'course templates') => {
    confirm({
      description:
        <Stack gap={3}>
          <Typography variant='body1'>{`Are you sure you want to trigger an export?`}</Typography>
          <Alert severity='info'>An email will be sent to {appUserContext?.currentUser?.email} in a few minutes which will contain the export of {type}.</Alert>
        </Stack>
    })
      .then(async () => {
        try {
          switch (type) {
            case 'participants in programs': await triggerParticipantExportPost(undefined).unwrap(); break;
            case 'course programs': await triggerProgramExportPost(undefined).unwrap(); break;
            case 'course templates': await triggerTemplateExportPost(undefined).unwrap(); break;
          }

          enqueueSnackbar('Export triggered!');
        }
        catch (error) {
          handleError(error);
        }
      })
      .catch(() => { });;
  }

  const navigate = useNavigate();

  const { handleError } = useApiResponseHandler();

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterRoleName, setFilterRoleName] = useState('all');
  const [filterLicensedTenantId, setFilterLicensedTenantId] = useState('all');

  const [learningCourseTemplateDeletePost] = appApi.endpoints.learningCourseTemplateDelete.useMutation();
  const learningCourseTemplateGetListQuery = appApi.endpoints.learningCourseTemplateGetList.useQuery({
    sorting: (orderBy || 'internalName') + ' ' + (order || 'asc'),
    skipCount: page * rowsPerPage,
    maxResultCount: rowsPerPage,
    filterText: filterName,
    licensedToTenantIds: filterLicensedTenantId && filterLicensedTenantId !== '' && filterLicensedTenantId !== 'all' ? [filterLicensedTenantId] : undefined,
    enrollmentAllowedForIdentityRoleNames: filterRoleName && filterRoleName !== '' && filterRoleName !== 'all' ? [filterRoleName] : undefined,
    statuses: filterStatus === 'all' ? undefined : [filterStatus as Activ8CoursesTemplatesLearningCourseTemplateStatus]
  },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true
    });

  const tableData = learningCourseTemplateGetListQuery.data?.items || [];

  const onDataRefetch = () => {
    learningCourseTemplateGetListQuery.refetch();
  }

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(event.target.value);
    setPage(0);
  };

  const handleFilterRoleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRoleName(event.target.value);
    setPage(0);
  };

  const handleFilterLicensedTenantId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterLicensedTenantId(event.target.value);
    setPage(0);
  };

  const handleDeleteRows = (selected: string[]) => {
    confirm({ description: `Are you sure you want to delete the selected course${selected.length > 1 ? 's' : ''}?` })
      .then(async () => {
        try {
          const promises = selected.map(id => learningCourseTemplateDeletePost({ id: id }).unwrap());
          await Promise.all(promises);
          enqueueSnackbar(`Course${selected.length > 1 ? 's' : ''} deleted`);
          setSelected([]);
          learningCourseTemplateGetListQuery.refetch();
        }
        catch (error) {
          handleError(error);
        }
      })
      .catch(() => { });;
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.master.courseTemplate.edit(kebabCase(id)));
  };

  const handleDownloadContent = async (id: string) => {
    try {
      const downloadQueryResponse = await getCourseContentPackageDownloadUrlQuery({ courseTemplateId: id }).unwrap();
      window.open((downloadQueryResponse?.blobReference || '') + (downloadQueryResponse?.blobToken || ''));
    }
    catch (err) {
      handleError(err);
    }
  };

  const handleOverviewRow = (id: string) => {
    handleEditRow(id);
    // navigate(PATH_DASHBOARD.master.courseTemplate.overview(kebabCase(id)));
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !tableData || tableData.length === 0;

  const totalCount = (learningCourseTemplateGetListQuery?.data?.totalCount || 0)

  const TABLE_HEAD = [
    { id: 'internalName', label: 'Name', align: 'left' },
    { id: 'courseReferenceCode', label: 'Code', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'enrollmentAllowedForIdentityRoleName', label: 'Applicable to', align: 'left' },
    { id: 'creationTime', label: 'Created', align: 'left' },
    { id: 'lastModificationTime', label: 'Updated', align: 'left' },
    { id: '', label: '', align: '' }
  ];

  return (
    <Page title="Courses">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Courses"
          links={[]}
          action={
            <>
              {
                !hasExportPermission ? undefined :
                  <DropDownMenuButton label='Export' variant="text" sx={{ mr: 4 }} startIcon={<Iconify icon={'eva:cloud-download-outline'} />}>
                    <MenuItem onClick={() => triggerExport('course templates')} disableRipple>
                      Templates
                    </MenuItem>
                    <MenuItem onClick={() => triggerExport('course programs')} disableRipple>
                      Programs
                    </MenuItem>
                    <MenuItem onClick={() => triggerExport('participants in programs')} disableRipple>
                      Participant metrics
                    </MenuItem>
                  </DropDownMenuButton>
              }
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.master.courseTemplate.new}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                New course
              </Button>
            </>
          }
        />

        <Card>
          <LearningCourseTemplateTableToolbar
            filterName={filterName}
            filterStatus={filterStatus}
            filterRole={filterRoleName}
            filterLicensedTenantId={filterLicensedTenantId}
            onFilterName={handleFilterName}
            onFilterStatus={handleFilterStatus}
            onFilterLicensedTenantId={handleFilterLicensedTenantId}
            onFilterRole={handleFilterRoleName}
            optionsStatus={STATUS_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={totalCount}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      !checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'} >
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalCount}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id || '')
                    )
                  }
                />

                <TableBody>
                  {tableData
                    .map((row) => (
                      <LearningCourseTemplateTableRow
                        key={row.id}
                        row={row}
                        onDataRefetch={onDataRefetch}
                        selected={selected.includes(row.id || '')}
                        onSelectRow={() => onSelectRow(row.id || '')}
                        onEditRow={() => handleEditRow(row.id || '')}
                        handleDownloadContent={() => handleDownloadContent(row.id || '')}
                        onOverviewRow={() => handleOverviewRow(row.id || '')}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}