import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { appApi } from 'src/api/redux/app/appApi';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert, Box, Button, Card, CardHeader, Container, Link, Stack, TextField, Typography, useTheme
} from '@mui/material';

import appSettings from '../../../appSettings';

// ----------------------------------------------------------------------

export default function ApiIntegrationSettings() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const apiSettings =
    appApi.endpoints.apiIntegrationGetIntegrationSettings.useQuery(undefined, {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true
    });

  return (
    apiSettings.isLoading ? <LoadingScreen /> :
      <Page title="API integration">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading="API integration settings"
            links={[
              { name: 'Manage', href: PATH_DASHBOARD.general.manageSettings },
              { name: 'API integration' },
            ]}
          />

          <Stack gap={2}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} alignItems="flex-start" justifyContent={'flex-start'}>
                <Typography variant="h6">API connection</Typography>
                <TextField fullWidth value={appSettings.apiUrl} disabled label="API URL" />
                <TextField fullWidth value={apiSettings?.data?.publicApiKey} disabled label="Public API key" />
              </Stack>
            </Card>

            <Card sx={{ p: 3 }}>
              <Stack spacing={3} alignItems="flex-start" justifyContent={'flex-start'}>
                <Typography variant="h6">Wordpress plugin - Register interest</Typography>
                <Stack spacing={3} alignItems="flex-start" justifyContent={'flex-start'} sx={{ mb: 2 }}>
                  <Typography>This Wordpress plugin will allow you to insert a "Register interest" form for prospective participants to select a upcoming program to register their interest for.</Typography>
                  <ol style={{ marginLeft: theme.spacing(7) }}>
                    <li>Download the Wordpress plugin from the button below and install on your site by navigating to "Plugins" on the left menu, then clicking "add plugin".</li>
                    <li>Select the plugin file downloaded in the previous step and click "Install now".</li>
                    <li>Activate the plugin</li>
                    <li>Navigate on the left Wordpress menu to "Settings", then "LMS - Register Interest".</li>
                    <li>Copy the Public API key and API URL from above and click on "Save".</li>
                    <li>Use the shortcode <code>[lms_register_interest_form]</code> on a page where you would like the "register interest" form to be shown.</li>
                  </ol>
                  <Link variant='button' color='primary' href="https://actv8aueapublic.blob.core.windows.net/dist/wordpress/register-interest/latest/lms-register-interest.zip" target="_blank">Download plugin</Link>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Container>
      </Page>
  );
}