import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Activ8CoursesTemplatesLearningCourseTemplateDto } from 'src/api/redux/app/appApi';
import RHFUserMultiSelect from 'src/components/hook-form/RHFUserMultiSelect';
import { PATH_DASHBOARD } from 'src/routes/paths';

import { Alert, Button, Grid, Stack, Typography } from '@mui/material';

import {
    LearningCourseProgramFacilitatorPreRequisiteDetails
} from '../../LearningCourseProgramFacilitatorPreRequisiteDetails';
import {
    LearningCourseProgramParticipantPreRequisiteDetails
} from '../../LearningCourseProgramParticipantPreRequisiteDetails';
import { LearningProgramFormValuesSchema } from '../LearningCourseProgramWizardForm';

type IProps = {
  courseTemplate: Activ8CoursesTemplatesLearningCourseTemplateDto;
  isEdit: boolean;
  courseProgramId: string;
}

export default function LearningCourseProgramWizardFormPreRequisitesStep({ courseTemplate, isEdit, courseProgramId }: IProps) {
  // const { watch } = useFormContext<LearningProgramFormValuesSchema>();
  
  const navigate = useNavigate();

  // const assignFacilitatorIdentityUserIdsWatch = watch('assignFacilitatorIdentityUserIds');

  return (
    <Grid container spacing={4}>
      {/* <Grid item xs={12} md={6}>
        <Stack gap={1}>
          <Typography variant='h5' gutterBottom>{isEdit ? 'Trainers assigned to conduct this program' : 'Assign trainers to conduct this program'}</Typography>
          <Stack gap={2}>
            {
              isEdit ?
              <>
                {
                  assignFacilitatorIdentityUserIdsWatch && assignFacilitatorIdentityUserIdsWatch.length > 0 ?
                    <Stack gap={2}>
                      <ul style={{marginLeft: 30}}>
                        {assignFacilitatorIdentityUserIdsWatch.map(a => <li key={a.id}><Button variant='text' onClick={() => navigate(PATH_DASHBOARD.tenant.facilitator.overview(a.id))}>{a.label}</Button></li>)}
                      </ul>
                      <Button variant='outlined' onClick={() => navigate(PATH_DASHBOARD.tenant.program.overview(courseProgramId))}>Manage trainers</Button>
                    </Stack>
                  : 
                  <Alert severity='info' action={<Button size='small' variant='contained' onClick={() => navigate(PATH_DASHBOARD.tenant.program.overview(courseProgramId))}>Assign trainers</Button>}>
                    No trainers have been assigned to this course
                  </Alert>
                }
              </>
              :
              <RHFUserMultiSelect 
                name="assignFacilitatorIdentityUserIds" 
                userTypes={['facilitators']} 
                placeholder='Search for a qualified trainer...' 
                noOptionsText='No qualified trainers were found'
                requiredAttributeIds={courseTemplate.attributePreRequisites?.filter(a => a.type == 'Facilitator').map(a => a.personAttributeId as string) || []}
                requiredAccreditationIds={courseTemplate.accreditationPreRequisites?.filter(a => a.type == 'Facilitator').map(a => a.personAccreditationId as string) || []}
              />
            }
          </Stack>
        </Stack>
      </Grid> */}

      <Grid item xs={12} md={6}>
        <Stack gap={2}>
          <Typography variant='h5'>Facilitators</Typography>
          <LearningCourseProgramFacilitatorPreRequisiteDetails compact courseTemplate={courseTemplate} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap={2}>
          <Typography variant='h5'>Participants</Typography>
          <LearningCourseProgramParticipantPreRequisiteDetails compact courseTemplate={courseTemplate} />
        </Stack>
      </Grid>
    </Grid>
  )
}