import {
    Activ8CoursesContentLearningCourseContentBlock,
    Activ8CoursesContentQuizResultsLearningCourseContentQuizResult
} from 'src/api/redux/app/appApi';
import { RHFCheckbox } from 'src/components/hook-form';

import { Stack, Typography } from '@mui/material';

type LearningCourseProgramSessionContentQuizBlockViewProps = {
    contentBlock: Activ8CoursesContentLearningCourseContentBlock;
    currentQuizQuestionIndex: number;
    onQuizOptionSelectToggle: (optionId: string, isSelected: boolean) => void;
    quizResult: Activ8CoursesContentQuizResultsLearningCourseContentQuizResult | undefined;
}

export function LearningCourseProgramSessionContentQuizBlockView({ contentBlock, currentQuizQuestionIndex, onQuizOptionSelectToggle, quizResult }: LearningCourseProgramSessionContentQuizBlockViewProps) {
    const question = (contentBlock.quiz?.questions || [])[currentQuizQuestionIndex];
    const questionResult = question && quizResult && quizResult.questions && quizResult.questions.length > 0 ? quizResult.questions.find(q => q.learningCourseContentQuizQuestionId === question.id) : undefined;
    const correctQuestionMultipleChoiceOptionIds = contentBlock.quiz?.isAnswerFeedbackShown === true && questionResult && questionResult.isCompleted && questionResult.multipleChoiceAnswers ? questionResult.multipleChoiceAnswers.filter(e => e.isCorrectAnswer).map(e => e.learningCourseContentQuizQuestionMultipleChoiceOptionId) : [];

    return (
        <Stack gap={2}>
            <Typography variant='body1'>{question.questionText}</Typography>

            <Stack gap={1}>
                {
                    (question.choiceOptions || []).map(c => {
                        const selectedAnswer = !questionResult ? undefined : questionResult.multipleChoiceAnswers?.find(a => a.learningCourseContentQuizQuestionMultipleChoiceOptionId === c.id && a.wasSelectedByUser);
                        let answerResult: 'correct' | 'incorrect' | 'missed' | undefined = undefined;
                        if (questionResult && questionResult.isCompleted) {
                            if (selectedAnswer && correctQuestionMultipleChoiceOptionIds.indexOf(c.id) > -1) {
                                answerResult = 'correct';
                            }
                            else if (!selectedAnswer && correctQuestionMultipleChoiceOptionIds.indexOf(c.id) > -1) {
                                answerResult = 'missed';
                            }
                            else if (!selectedAnswer && correctQuestionMultipleChoiceOptionIds.indexOf(c.id) === -1) {
                                answerResult = undefined;
                            }
                            else {
                                answerResult = 'incorrect';
                            }
                        }
                        return <RHFCheckbox key={(c.id as string) + (answerResult)} disabled={questionResult?.isCompleted === true || Boolean(selectedAnswer) || answerResult != undefined} feedbackResult={answerResult} onChange={(e, checked) => onQuizOptionSelectToggle(c.id as string, checked)} name={'option_checked_' + c.id} label={c.answerText} />
                    }
                    )
                }
            </Stack>

            {/* {correctAnswers && correctAnswers.length > 0 ?
                <div>
                    <Typography variant='body1'>Nice try!</Typography>
                    {correctAnswers.length === 1 ?
                        <div>
                            <Typography variant='body1'>The correct answer is:</Typography>
                            <Typography variant='body2'>{correctAnswers[0].answerText}</Typography>
                        </div>
                        :
                        <div>
                            <Typography variant='body1'>The correct answers are:</Typography>
                            <Stack gap={2}>
                                {correctAnswers.map(c => <Typography key={c.id} variant='body2'>{c.answerText}</Typography>)}
                            </Stack>
                        </div>
                    }
                </div>
                : undefined} */}

        </Stack>
    )
}