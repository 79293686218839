// form

import { forwardRef, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CountryOption, { parsePhoneNumber } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import {
  Autocomplete, Box, FormHelperText, Popper, TextField, TextFieldProps
} from '@mui/material';

const customStyles = () => ({
  popper: {
    width: "fit-content"
  }
});


// ----------------------------------------------------------------------

type IProps = {
  name: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  disableAutoComplete?: boolean;
};

type Props = IProps & TextFieldProps;

const defaultCountryCode = 'AU' as any;

export default function RHFPhoneField({ name, endAdornment, startAdornment, helperText, disableAutoComplete, ...other }: Props) {
  const { control, getFieldState, getValues, watch } = useFormContext();
  const styles = customStyles();
  const [defaultCountryValue, setDefaultCountryValue] = useState(defaultCountryCode);
  const countryOrder = ['AU', 'GB', 'US', 'FR', 'ES', 'IT', 'DE', 'CA', 'IN', '|', '...'];

  const { error } = getFieldState(name);

  const helperTextComponent = error?.message && helperText ?
    <>
      <span style={{ display: 'block' }}>{error?.message}</span>
      <span style={{ display: 'block' }}>{helperText}</span>
    </>
    : (helperText ? helperText :
      (error?.message ? <>{error?.message}</> : undefined)
    )

  const countryMarginBottom = error?.message && helperText ? '20px' :
    (helperText ? '15px' :
      (error?.message ? '15px' : '0px')
    )

  const textInput = forwardRef((props: any, ref: any) => {
    // console.log('heree focus', props, textInput);

    // props.onFocus = () => textInput.

    return <TextField
      // InputProps={{ startAdornment: startAdornment, endAdornment: endAdornment }}
      // name={field.name} 
      // value={field.value}           
      // onBlur={(e) => field.onBlur()}
      // inputProps={{...other.inputProps, autoComplete: disableAutoComplete ? 'no' : undefined}} 
      fullWidth
      error={!!error}
      helperText={helperTextComponent}
      FormHelperTextProps={{ component: 'div' } as any}
      // value={field.value}
      // name={field.name}
      // defaultValue={field.value}
      // value={props.value}
      // ref={ref} 
      inputProps={{ ref: ref }}
      InputProps={{
        ...props,
        onChange: (e) => {
          // console.log('heree onchange phone', e)
          props.onChange(e);
        },
        // onChangeCapture: (e) => {
        //   console.log('heree onChangeCapture', e);
        // }
      }}
      label={other.label || 'Phone number'}
    // {...props}
    />;
  });

  const PopperMy = function (props: any) {
    return <Popper {...props} style={styles.popper} placement="bottom-start" />;
  };

  const getDefaultCountryValue = () => {
    const uVal = getValues(name);
    // console.log('heree parsed', uVal);

    if (!uVal) return { label: 'Australia', value: defaultCountryCode };

    const parsedNum = parsePhoneNumber(uVal);
    if (!parsedNum) return { label: 'Australia', value: defaultCountryCode };

    // console.log('heree parsed', parsedNum);

    return { label: (en as any)[parsedNum.country as string] as string, value: parsedNum?.country };
  }

  useEffect(() => {
    setDefaultCountryValue(getDefaultCountryValue());
  }, [])

  // defaultCountryValue()

  const countrySelect = forwardRef((props: any, ref: any) => <Autocomplete
    // {...props}          
    options={props.options.filter((o: any) => o.value && o.label)}
    // disablePortal
    defaultValue={defaultCountryValue}
    disableClearable={true}
    // onSelect={(e) => props.onChange(e.val)}
    // sx={{ width: 140, mr: 1, mb: countryMarginBottom }}
    style={{ width: 140, marginRight: '10px', marginBottom: countryMarginBottom }}
    filterOptions={(opts, { inputValue }) => !inputValue ? opts : opts.filter((x: any) => x.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1 || x.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1).slice(0, 30)}
    isOptionEqualToValue={(op: any, val: any) => {
      // console.log('heree isOptionEqualToValue', val, op);
      // if(!op) return true;
      const matchVal = typeof val === 'string' ? val : val.value;
      return op.value === matchVal;
    }}
    onChange={(e, newVal: any) => props.onChange(newVal.value)}
    autoHighlight
    getOptionLabel={(option: any) => typeof option === 'string' ? option : (option.value || '')}
    PopperComponent={PopperMy}
    renderOption={(props, option: any) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
          alt=""
        />
        {option.label}
      </Box>
    )}
    renderInput={(params) => (
      <TextField
        label="Country"
        tabIndex={props.tabIndex}
        // value={props.value}             
        // defaultValue={defaultCountryValue()}
        {...params}
        inputProps={{
          ...params.inputProps,
          // defaultValue: defaultCountryValue(),          
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
        // InputProps={{defaultValue:(value ? parsePhoneNumber(value)?.country : undefined)}}
        ref={ref}
      />
    )}
  // defaultValue={defaultCountryValue()}
  />)

  return (
    // <Controller
    //   name={name}
    //   control={control}
    //   render={({ field, fieldState: { error } }) => {
    //     const helperTextComponent = error?.message && helperText ? 
    //                 <>
    //                   <span style={{display: 'block'}}>{error?.message}</span>
    //                   <span style={{display: 'block'}}>{helperText}</span>
    //                 </>
    //                 : (helperText ? helperText : 
    //                   (error?.message ? <>{error?.message}</> : undefined)
    //                   )

    //     return (
    //       <TextField InputProps={{ startAdornment: startAdornment, endAdornment: endAdornment }} {...field} inputProps={{...other.inputProps, autoComplete: disableAutoComplete ? 'no' : undefined}} fullWidth error={!!error} {...other} helperText={helperTextComponent}  FormHelperTextProps={{ component: 'div'} as any} />
    //     )
    //   }}
    // />

    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <PhoneInputWithCountry
            defaultCountry={defaultCountryCode}
            addInternationalOption={false}
            countryOptionsOrder={countryOrder as any[]}
            name={name}
            international
            style={{ width: '100%' }}
            control={control}
            countryCallingCodeEditable={false}
            focusInputOnCountrySelection={true}
            displayInitialValueAsLocalNumber={false}
            // flagComponent={}
            flagUrl='https://flagcdn.com/w20/{XX}.png'
            inputComponent={textInput}
            countrySelectComponent={countrySelect}
          />
        )
      }}
    />
  );
}
