import { kebabCase } from 'change-case';
import { useNavigate } from 'react-router';
import { Activ8FacilitatorsFacilitatorDto, appApi } from 'src/api/redux/app/appApi';
import useCurrentUser from 'src/hooks/useCurrentUser';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fDateTime } from 'src/utils/formatTime';
import { mapIdentityUser } from 'src/utils/mapUser';

import {
  Card, CardHeader, Chip, Divider, Grid, Link, Stack, Tooltip, Typography
} from '@mui/material';

import UserDetailsCard from '../@dashboard/user/cards/UserDetailsCard';
// import UserParentDetailsCard from '../@dashboard/user/cards/UserParentDetailsCard';
import { UserMembershipInfoCard } from '../shared/UserMembershipInfoCard';

type Props = {
  facilitator: Activ8FacilitatorsFacilitatorDto;
  onRefetch: () => void;
};

export default function FacilitatorOverviewDashboard({ facilitator, onRefetch }: Props) {
  const activeProgramsQuery = appApi.endpoints.learningCourseProgramFacilitatorGetList.useQuery({ facilitatorIdentityUserIds: [facilitator.id as string], includeFacilitatorDetails: false, includeProgramDetails: true, programStatuses: ['Live', 'Draft'] }, { refetchOnReconnect: true, refetchOnFocus: true, refetchOnMountOrArgChange: true });
  const pastProgramsQuery = appApi.endpoints.learningCourseProgramFacilitatorGetList.useQuery({ facilitatorIdentityUserIds: [facilitator.id as string], includeFacilitatorDetails: false, includeProgramDetails: true, programStatuses: ['Complete', 'Cancelled'] }, { refetchOnReconnect: true, refetchOnFocus: true, refetchOnMountOrArgChange: true });

  const navigate = useNavigate();
  const viewCourseProgram = (programId: string) => {
    navigate(PATH_DASHBOARD.tenant.program.overview(kebabCase(programId)));
  }

  const isLoading = activeProgramsQuery.isLoading || pastProgramsQuery.isLoading;

  const currentUser = useCurrentUser();
  const user = mapIdentityUser(facilitator);
  const isSuperAdmin = !Boolean(currentUser.tenantId);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Stack direction='column' spacing={2} >
          <UserDetailsCard user={user} />
          {/* <UserParentDetailsCard user={user} /> */}
          <UserMembershipInfoCard onRefetch={onRefetch} facilitator={facilitator} />
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 1.4 }}>
              <CardHeader title={'Current programs'} sx={{ mb: 0, p: 0, px: 1.5, py: 1 }} />
              <Stack sx={{ width: '100%', p: 1, pl: 2 }} spacing={1} divider={<Divider orientation="horizontal" flexItem />}>
                {
                  activeProgramsQuery?.data?.items && activeProgramsQuery?.data?.items.length > 0 ?
                    (activeProgramsQuery?.data?.items || []).map(p => {
                      const programStatusColor = p.learningCourseProgram?.status === 'Live' || p.learningCourseProgram?.status === 'Complete' ? 'success' : (p.learningCourseProgram?.status === 'Draft' ? 'info' : (p.learningCourseProgram?.status === 'Cancelled' ? 'error' : undefined));

                      return (
                        <Stack key={p.id} direction='row' justifyContent={'space-between'} alignItems='flex-start'>
                          <Stack key={p.id} direction='row' gap={1} justifyContent={'flex-start'} alignItems='flex-start'>
                            <code>{p.learningCourseProgram?.programReferenceCode}</code>
                            {isSuperAdmin ? <Typography variant='body2'>{p.learningCourseProgram?.publicName}</Typography> : <Link href='#' onClick={() => viewCourseProgram(p.learningCourseProgram?.id as string)}><Typography variant='body2'>{p.learningCourseProgram?.publicName}</Typography></Link>}
                          </Stack>
                          <Stack key={p.id} direction='row' gap={2} justifyContent={'flex-end'} alignItems='flex-end'>
                            <Tooltip title={'Date assigned to the program '}>
                              <Typography variant='body2'>{fDateTime(p.creationTime as string)}</Typography>
                            </Tooltip>
                            <Tooltip title={'This program is ' + p.learningCourseProgram?.status?.toLowerCase()}>
                              <Chip size='small' color={programStatusColor} variant={'filled'} label={p.learningCourseProgram?.status} />
                            </Tooltip>
                          </Stack>
                        </Stack>
                      )
                    }
                    ) : <Typography sx={{ textAlign: 'center' }}>No current programs</Typography>
                }
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ p: 1.4 }}>
              <CardHeader title={'Past programs'} sx={{ mb: 0, p: 0, px: 1.5, py: 1 }} />
              <Stack sx={{ width: '100%', p: 1, pl: 2 }} spacing={1} divider={<Divider orientation="horizontal" flexItem />}>
                {
                  pastProgramsQuery?.data?.items && pastProgramsQuery?.data?.items.length > 0 ?
                    (pastProgramsQuery?.data?.items || []).map(p => {
                      const programStatusColor = p.learningCourseProgram?.status === 'Live' || p.learningCourseProgram?.status === 'Complete' ? 'success' : (p.learningCourseProgram?.status === 'Draft' ? 'info' : (p.learningCourseProgram?.status === 'Cancelled' ? 'error' : undefined));

                      return (
                        <Stack key={p.id} direction='row' justifyContent={'space-between'} alignItems='flex-start'>
                          <Stack key={p.id} direction='row' gap={1} justifyContent={'flex-start'} alignItems='flex-start'>
                            <code>{p.learningCourseProgram?.programReferenceCode}</code>
                            {isSuperAdmin ? <Typography variant='body2'>{p.learningCourseProgram?.publicName}</Typography> : <Link href='#' onClick={() => viewCourseProgram(p.learningCourseProgram?.id as string)}><Typography variant='body2'>{p.learningCourseProgram?.publicName}</Typography></Link>}
                          </Stack>
                          <Stack key={p.id} direction='row' gap={2} justifyContent={'flex-end'} alignItems='flex-end'>
                            <Tooltip title={'Date assigned to the program '}>
                              <Typography variant='body2'>{fDateTime(p.creationTime as string)}</Typography>
                            </Tooltip>
                            <Tooltip title={'This program is ' + p.learningCourseProgram?.status?.toLowerCase()}>
                              <Chip color={programStatusColor} size='small' variant={'outlined'} label={p.learningCourseProgram?.status} />
                            </Tooltip>
                          </Stack>
                        </Stack>
                      )
                    }
                    ) : <Typography sx={{ textAlign: 'center' }}>No past programs</Typography>
                }
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
