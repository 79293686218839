import { useAuth } from 'react-oidc-context';
import { UserManager } from 'src/@types/user';
import { accountApi } from 'src/api/redux/account/accountApi';

import useAppUserContext from './useAppUserContext';
import { useEffect } from 'react';
import { usePageLifecycle } from './usePageLifeycle';

export default function useCurrentUser() {
  const auth = useAuth();
  const appUserContext = useAppUserContext();
  const pageLifecycle = usePageLifecycle();
  const [profileQueryLazyQuery, profileLazyQueryStatus] = accountApi.endpoints.profileGet.useLazyQuery({ refetchOnReconnect: true, refetchOnFocus: true });

  useEffect(() => {
    if (auth.isAuthenticated && !pageLifecycle.isLoading && !pageLifecycle.isNavigatingAway) {
      profileQueryLazyQuery();
    }
  }, [auth.isAuthenticated, pageLifecycle.isNavigatingAway, pageLifecycle.isLoading])

  // console.log('heree auth', auth)

  const profile = profileLazyQueryStatus;

  return {
    id: auth.user?.profile.sub,
    userName: profile.currentData?.userName,
    avatarUrl: undefined,
    name: profile.currentData?.name,
    lastName: profile.currentData?.surname,
    email: profile.currentData?.email,
    phoneNumber: profile.currentData?.phoneNumber,
    address: profile.currentData?.extraProperties ? profile.currentData?.extraProperties['claims.adddress'] : null,
    roleNames: [],
    isActive: true,
    lockoutEnabled: true,
    tenantId: appUserContext?.currentTenant?.id,
    concurrencyStamp: profile.currentData?.concurrencyStamp,
    extraProperties: profile.currentData?.extraProperties,
    creationTime: undefined,
    organizationUnitIds: [],
    postcode: profile.currentData?.extraProperties ? profile.currentData?.extraProperties['Postcode'] : null,
    countryCode: profile.currentData?.extraProperties ? profile.currentData?.extraProperties['CountryCode'] : null,
    isPartialUser: profile.currentData?.extraProperties ? profile.currentData?.extraProperties['IsPartialUser'] === true : null
  } as UserManager;
}
