import { sentenceCase } from 'change-case';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}


var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

export function stringifyNumber(n: number, uppercaseFirstLetter: boolean): string {
  if (n < 20) return uppercaseFirstLetter ? sentenceCase(special[n]) : special[n];
  if (n%10 === 0) return uppercaseFirstLetter ? sentenceCase(deca[Math.floor(n/10)-2] + 'ieth') : deca[Math.floor(n/10)-2] + 'ieth';
  return uppercaseFirstLetter ? sentenceCase(deca[Math.floor(n/10)-2] + 'y-' + special[n%10]) : deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
}