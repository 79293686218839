// @mui
import { Box, Button, Paper, Typography, useTheme } from '@mui/material';



// ----------------------------------------------------------------------

interface IProps {
  restrictedToMedia?: boolean;
  singleFile?: boolean;
}

export default function BlockContent({restrictedToMedia, singleFile}: IProps) {
  const theme = useTheme();

  const pluralChar = singleFile ? '' : 's';

  return (
    <Box sx={{ width: '100%' }}>
        <Paper variant="outlined" sx={{ p: 1, textAlign: 'center', borderWidth: 3, borderStyle: 'dotted', borderColor: theme.palette.grey[400] }}>
          <Typography variant='body2'>Drop {restrictedToMedia ? 'media file' + pluralChar : 'file' + pluralChar} here or <Button variant='text'>browse device</Button></Typography>
        </Paper>
    </Box>
  );
}
