// @mui
import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import useLocales from '../../../hooks/useLocales';
// type
import { NavSectionProps } from '../type';
//
import FeatureBasedGuard from 'src/guards/FeatureBasedGuard';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <FeatureBasedGuard featureNameKeys={group.features} key={group.subheader}>
          <PermissionBasedGuard permissionNameKeys={group.permissions}>
            <RoleBasedGuard roles={group.roles}>
              <List disablePadding sx={{ px: 2 }}>
                {/* <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {translate(group.subheader)}
            </ListSubheaderStyle> */}

                {group.items.map((list) => (
                  <NavListRoot key={list.title + list.path} list={list} isCollapse={isCollapse} />
                ))}
              </List>
            </RoleBasedGuard>
          </PermissionBasedGuard>
        </FeatureBasedGuard>
      ))}
    </Box>
  );
}
