import { upperFirst } from 'lodash-es';
import { appApi } from 'src/api/redux/app/appApi';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import RHFRichTextEditor from 'src/components/hook-form/RHFRichTextEditor';
import LoadingScreen from 'src/components/LoadingScreen';

import { Grid, Stack, Typography } from '@mui/material';

export default function LearningCourseTemplateWizardFormDetailsStep() {
  const roleQuery = appApi.endpoints.learningCourseTemplateGetRoleNamesApplicableToCourseAssignments.useQuery();

  const { isLoading } = roleQuery;
  // const isLoading = roleQuery.isLoading;

  return (
    isLoading ?
      <LoadingScreen />
      :
      <Stack rowGap={6}>
        <Stack rowGap={2}>
          <Typography variant='h5'>Master details</Typography>
          <Stack rowGap={4}>
            <RHFTextField name="internalName" label="Internal name" helperText="Title of the course that is viewable in master portal only" />
            <RHFSelect name="enrollmentAllowedForIdentityRoleName" label="Applicable to" helperText="Course can be undertaken by this role only">
              {(roleQuery?.data?.items || []).map((opt) => ({ id: opt, name: upperFirst(opt || '') })).map((option) => (
                <option
                  key={option.id}
                  value={option.id || ''}
                >
                  {option.name}
                </option>
              ))}
            </RHFSelect>
          </Stack>
        </Stack>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack rowGap={2}>
              <Typography variant='h5'>Partner details</Typography>
              <Stack rowGap={4}>
                <RHFTextField name="partnerShortDescription" label="Short description" helperText="Title of the course aim targeted at partners" />
                <RHFRichTextEditor name="partnerLongRichTextDescription" label="Detailed description" helperText="Long description of the course aim targeted at partners" />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack rowGap={2}>
              <Typography variant='h5'>Facilitator details</Typography>
              <Stack rowGap={4}>
                <RHFTextField name="facilitatorShortDescription" label="Short description" helperText="Title of the course aim targeted at facilitators" />
                <RHFRichTextEditor name="facilitatorLongRichTextDescription" label="Detailed description" helperText="Long description of the course aim targeted at facilitators" />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
  )
}