import queryString from 'query-string';
import getLoggedInUser from 'src/utils/getLoggedInUser';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import appSettings from '../../../appSettings';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: fetchBaseQuery(
    {
      baseUrl: appSettings.oAuthAuthority,
      prepareHeaders(headers, api) {
        const user = getLoggedInUser();
        if (user && user?.access_token) {
          headers.set('Authorization', `Bearer ${user?.access_token}`);
        }
        headers.set('Connection', 'keep-alive');

        const impersonatingTenantId = localStorage.getItem('impersonate_tenant_id');
        if (impersonatingTenantId) {
          headers.set('X-TenantId', impersonatingTenantId);
        }
        return headers;
      },
      paramsSerializer(params) {
        return queryString.stringify(params);
      },
    }),
  endpoints: () => ({}),
})