import { ElementType, lazy, Suspense } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, useLocation, useRoutes, useSearchParams } from 'react-router-dom';
// components
import useCurrentUser from 'src/hooks/useCurrentUser';
import LearningCourseProgramList from 'src/pages/courses/programs/LearningCourseProgramList';
import LearningCourseProgramOverview from 'src/pages/courses/programs/LearningCourseProgramOverview';
import LearningCourseProgramWizard from 'src/pages/courses/programs/LearningCourseProgramWizard';
import LearningCourseTemplateList from 'src/pages/courses/templates/LearningCourseTemplateList';
import LearningCourseTemplateOverview from 'src/pages/courses/templates/LearningCourseTemplateOverview';
import LearningCourseTemplateWizard from 'src/pages/courses/templates/LearningCourseTemplateWizard';
import FacilitatorCreate from 'src/pages/facilitators/FacilitatorCreate';
import FacilitatorList from 'src/pages/facilitators/FacilitatorList';
import FacilitatorOverview from 'src/pages/facilitators/FacilitatorOverview';
import ParticipantCreate from 'src/pages/participants/ParticipantCreate';
import ParticipantList from 'src/pages/participants/ParticipantList';
import ParticipantOverview from 'src/pages/participants/ParticipantOverview';
import PartnerProfile from 'src/pages/partners/PartnerProfile';
import ManagementTiles from 'src/pages/setup';
import ActiveCampaignIntegrationSettings from 'src/pages/setup/integrations/ActiveCampaign';
import ApiIntegrationSettings from 'src/pages/setup/integrations/Api';
import AccreditationCreate from 'src/pages/setup/person/accreditations/PersonAccreditationCreate';
import AccreditationList from 'src/pages/setup/person/accreditations/PersonAccreditationList';
import AttributeCreate from 'src/pages/setup/person/attributes/PersonAttributeCreate';
import AttributeList from 'src/pages/setup/person/attributes/PersonAttributeList';

import LoadingScreen from '../components/LoadingScreen';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [searchParams, setSearchParams] = useSearchParams();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useCurrentUser();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const auth = useAuth();

  const isDashboard = pathname.includes('/dashboard') && user !== undefined && user != null;

  if (!auth.isAuthenticated || !user.isActive) {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    const currentRedirectCount = searchParams.get('rc') ? (parseInt(searchParams.get('rc') as string) + 1) : 1;
    if (currentRedirectCount <= 5) {
      updatedSearchParams.set('rc', currentRedirectCount.toString());

      // setSearchParams(updatedSearchParams.toString());
      // window.location.reload();

      // get current url path and add search params
      const currentPath = window.location.pathname;
      const currentSearch = updatedSearchParams.toString();
      const newUrl = `${currentPath}?${currentSearch}`;

      window.location.href = newUrl;
      return <LoadingScreen />
      // return <Navigate to={newUrl} replace />;
    }
  }

  return (
    <Suspense fallback={isDashboard ? <></> : <LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'app',
      element: (
        <DashboardLayout />
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <GeneralApp /> },
        { path: 'manage', element: <ManagementTiles /> },
        {
          path: 'user',
          children: [
            { path: 'list', index: true, element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'partner',
          children: [
            { path: 'list', element: <PartnerList />, index: true },
            { path: 'new', element: <PartnerCreate /> },
            { path: ':id/edit', element: <PartnerCreate /> },
            { path: 'profile/edit', element: <PartnerProfile /> },
          ],
        },
        {
          path: 'course',
          children: [
            {
              path: 'template',
              children: [
                { path: 'list', element: <LearningCourseTemplateList />, index: true },
                { path: 'new', element: <LearningCourseTemplateWizard /> },
                { path: ':id/edit', element: <LearningCourseTemplateWizard /> },
                { path: ':id/overview', element: <LearningCourseTemplateOverview /> },
              ],
            },
          ],
        },
        {
          path: 'participant',
          children: [
            { path: 'list', element: <ParticipantList />, index: true },
            { path: 'new', element: <ParticipantCreate /> },
            { path: ':id/edit', element: <ParticipantCreate /> },
            { path: ':id/overview', element: <ParticipantOverview /> },
          ],
        },
        {
          path: 'facilitator',
          children: [
            { path: 'list', element: <FacilitatorList />, index: true },
            { path: 'new', element: <FacilitatorCreate /> },
            { path: ':id/edit', element: <FacilitatorCreate /> },
            { path: ':id/overview', element: <FacilitatorOverview /> },
          ],
        },
        {
          path: 'program',
          children: [
            { path: 'list', element: <LearningCourseProgramList />, index: true },
            { path: 'new', element: <LearningCourseProgramWizard /> },
            { path: ':id/edit', element: <LearningCourseProgramWizard /> },
            { path: ':id/overview', element: <LearningCourseProgramOverview /> },
          ],
        },
        {
          path: 'setup',
          children: [
            {
              path: 'attributes',
              children: [
                { path: 'list', element: <AttributeList />, index: true },
                { path: 'new', element: <AttributeCreate /> },
                { path: ':id/edit', element: <AttributeCreate /> },
              ],
            },
            {
              path: 'accreditations',
              children: [
                { path: 'list', element: <AccreditationList />, index: true },
                { path: 'new', element: <AccreditationCreate /> },
                { path: ':id/edit', element: <AccreditationCreate /> },
              ],
            },
            {
              path: 'integrations',
              children: [
                { path: 'active-campaign', element: <ActiveCampaignIntegrationSettings />, index: true },
                { path: 'api', element: <ApiIntegrationSettings />, index: true },
              ],
            }
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: (user != undefined && user != null ? <Navigate to={PATH_AFTER_LOGIN} replace /> : undefined),
    // },
    {
      path: '/',
      element:
        <Navigate to="/app" replace />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// PARTNER
const PartnerList = Loadable(lazy(() => import('../pages/partners/PartnerList')));
const PartnerCreate = Loadable(lazy(() => import('../pages/partners/PartnerCreate')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
