import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { appApi } from 'src/api/redux/app/appApi';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Card, Container, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function ActiveCampaignIntegrationSettings() {
  const { themeStretch } = useSettings();

  return (
    <Page title="ActiveCampaign integration">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="ActiveCampaign settings"
          links={[
            { name: 'Manage', href: PATH_DASHBOARD.general.manageSettings },
            { name: 'ActiveCampaign' },
          ]}
        />

        <ActiveCampaignIntegrationSettingsForm />
      </Container>
    </Page>
  );
}

type FormValuesProps = {
  apiUrl: string;
  apiKey: string;
};

function ActiveCampaignIntegrationSettingsForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useApiResponseHandler();
  const confirm = useConfirm();
  const [integrationCurrentlyRunning, setIntegrationCurrentlyRunning] = useState(false);

  const [activeCampaignSettingsPost] =
    appApi.endpoints.activeCampaignIntegrationSetSettings.useMutation();
  const activeCampaignSettingsQuery =
    appApi.endpoints.activeCampaignIntegrationGetSettings.useQuery(undefined, {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    });

  const IntegrationSettingsSchema = Yup.object().shape({
    apiUrl: Yup.string().url('Must be a valid URL').required('Required'),
    apiKey: Yup.string().required('Required'),
  });

  const defaultValues = {
    apiUrl: '',
    apiKey: '',
  };

  const methods = useForm({
    resolver: yupResolver(IntegrationSettingsSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, isValid, isDirty },
  } = methods;

  useEffect(() => {
    methods.setValue('apiUrl', activeCampaignSettingsQuery.data?.apiUrl || '');
    methods.setValue('apiKey', activeCampaignSettingsQuery.data?.apiKey || '');
    setIntegrationCurrentlyRunning(Boolean(activeCampaignSettingsQuery.data?.apiUrl && activeCampaignSettingsQuery.data?.apiKey));
  }, [activeCampaignSettingsQuery])

  const apiUrlWatch = watch('apiUrl');

  const handleDisableIntegration = () => {
    confirm({
      description: `Are you sure you want to disable ActiveCampaign? Make sure you know what you are doing!`,
    }).then(async () => {
      try {
        await activeCampaignSettingsPost({
          activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto: {
            apiKey: '',
            apiUrl: '',
          },
        }).unwrap();
        setValue('apiKey', '');
        setValue('apiUrl', '');
        activeCampaignSettingsQuery.refetch();
        enqueueSnackbar('ActiveCampaign integration has been disabled');
      } catch (error) {
        handleError(error);
      }
    })
      .catch(() => { });;
  };

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await activeCampaignSettingsPost({
        activ8IntegrationsActiveCampaignActiveCampaignIntegrationSettingsDto: {
          apiKey: data.apiKey,
          apiUrl: data.apiUrl,
        },
      }).unwrap();
      reset();
      activeCampaignSettingsQuery.refetch();
      enqueueSnackbar('ActiveCampaign reported the provided details are correct. Settings saved!');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    activeCampaignSettingsQuery.isLoading ? <LoadingScreen /> :
      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} alignItems="flex-end" sx={{ mb: 2 }}>
            <Alert severity="info" sx={{ width: '100%' }}>
              <p>
                Your API URL and key can be found in your ActiveCampaign account on the Settings page
                under the "Developer" tab.
              </p>
              <p>
                <Button
                  variant="text"
                  href="https://help.activecampaign.com/hc/en-us/articles/207317590-Getting-started-with-the-API"
                  target="_new"
                >
                  More information
                </Button>
              </p>
            </Alert>

            <RHFTextField
              name="apiUrl"
              label="API URL"
              disableAutoComplete
              placeholder="Example: https://yourcompanyname54323.activehosted.com"
            />

            <RHFTextField
              name="apiKey"
              type="password"
              label="API key"
              disableAutoComplete
              placeholder="Example: 2c0a92a269b448a7b91d141a70cd205c6683c1e04cd44490a9f2bb7364237845"
            />
          </Stack>
          <Stack sx={{ mt: 2 }} spacing={8} direction='row' alignItems='flex-start' justifyContent={'flex-end'}>
            {
              apiUrlWatch && integrationCurrentlyRunning ?
                <div>
                  <LoadingButton
                    color="error"
                    sx={{ align: 'left' }}
                    variant="outlined"
                    onClick={() => handleDisableIntegration()}
                    loading={isSubmitting}
                  >
                    Disable integration
                  </LoadingButton>
                </div> : undefined
            }

            <div>
              <LoadingButton
                sx={{ align: 'right' }}
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid || !isDirty}
              >
                Save
              </LoadingButton>
            </div>
          </Stack>
        </FormProvider>
      </Card>
  );
}
