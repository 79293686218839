// form

import { isArray } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Box, Chip, FormControl, FormHelperText, InputLabel, OutlinedInput, Select, SelectProps
} from '@mui/material';

// @mui


// ----------------------------------------------------------------------

type IProps = {
  name: string;
  children: any;
  preventMultiple?: boolean;
  helperText?: React.ReactNode;
  getSelectedOptionText?: (opt: any) => string
};

type Props = IProps & SelectProps;

export default function RHFMultiSelect({ name, children, label, helperText, preventMultiple, getSelectedOptionText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <InputLabel id={`input-label-${name}`}>{label}</InputLabel>
          <Select
            {...field}
            multiple={!preventMultiple}
            error={!!error}
            label={label}
            labelId={`input-label-${name}`}
            // input={<OutlinedInput label="Chip" />}
            // renderValue={(selected) => selected.join(', ')}   
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {
                  isArray(selected) ? selected.map((value: any) => (
                    <Chip key={value} label={getSelectedOptionText ? getSelectedOptionText(value) : value} />
                  ))
                    : <Chip label={getSelectedOptionText ? getSelectedOptionText(selected) : selected} />
                }
              </Box>
            )}
            {...other}
          >
            {children}
          </Select>
          {
            error?.message && helperText ?
              <FormHelperText error sx={{ px: 2 }}>
                <>
                  <span style={{ display: 'block' }}>{error?.message}</span>
                  <span style={{ display: 'block' }}>{helperText}</span>
                </>
              </FormHelperText>
              : (helperText ? <FormHelperText sx={{ pb: 1 }}>{helperText}</FormHelperText> :
                (error?.message ? <FormHelperText sx={{ pb: 1 }} error>{error?.message}</FormHelperText> : undefined)
              )
          }
        </FormControl>
      )}
    />
  );
}
