import { MenuItem, TextField } from "@mui/material";
import { appApi } from "src/api/redux/app/appApi";

type Props = {
    initialValue: string;
    label?: string;
    includeAllOption: boolean;
    onFilterRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  
  export default function CourseUserRoleSelect({
    initialValue,
    onFilterRole,
    label,
    includeAllOption,
  }: Props) {
    const roleQuery = appApi.endpoints.learningCourseTemplateGetRoleNamesApplicableToCourseAssignments.useQuery();
  
    return (
        <TextField
        fullWidth
        select
        label={label || "Role"}
        value={initialValue}
        onChange={onFilterRole}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {includeAllOption ?
            <MenuItem
                key={'all'}
                value={'all'}
                sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
                }}
            >
                All
            </MenuItem>
          : undefined
        }
        {(roleQuery.data?.items || []).map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
    )
}