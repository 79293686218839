import {
    Activ8CoursesContentLearningCourseContentChapter,
    Activ8CoursesProgramsCoursesLearningCourseProgramDto,
    Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfo
} from 'src/api/redux/app/appApi';

import { Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';

import { LearningCourseProgramChapterSessionItem } from './LearningCourseProgramChapterSessionItem';

export type ILearningCourseProgramChapterItemProps = {
    contentChapter: Activ8CoursesContentLearningCourseContentChapter;
    courseProgram: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
    chapterIndex: number;
    participantProgression: Activ8CoursesProgramsProgressionParticipantToLearningCourseProgramAssignmentProgressionInfo | undefined; 
    triggerShowSessionContentViewWizard: (chapterId: string, sessionId: string) => void;
}

export function LearningCourseProgramChapterItem({ courseProgram, contentChapter, chapterIndex, triggerShowSessionContentViewWizard, participantProgression}: ILearningCourseProgramChapterItemProps) {
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent sx={{ width: '100%' }}>
                    <Stack direction='column' rowGap={2}>
                        <Stack direction='row' rowGap={2} justifyContent='space-between' alignItems='flex-start'>
                            <Typography variant='h6'>
                                {contentChapter.name}
                            </Typography>
                        </Stack>

                        <Stack direction='column' rowGap={2} divider={<Divider orientation="horizontal" flexItem />} justifyContent='space-between' alignItems='flex-start' sx={{ ml: 2 }}>
                            {
                                (contentChapter.sessions || []).map((contentSession, index) => {
                                    const programSession = (courseProgram?.sessions || []).find(e => e.learningCourseTemplateContentSessionId === contentSession.id);                                    
                                    const participantSessionProgression = participantProgression ? (participantProgression.sessions || []).find(e => e.learningCourseTemplateContentChapterId == contentChapter.id && e.learningCourseTemplateContentSessionId == contentSession.id) : undefined;

                                    if (programSession) {
                                        return (
                                            <div key={contentSession.id} style={{ width: '100%' }}>
                                                <LearningCourseProgramChapterSessionItem programSession={programSession} contentSession={contentSession} sessionIndex={index} triggerShowSessionContentViewWizard={triggerShowSessionContentViewWizard} participantSessionProgression={participantSessionProgression} />
                                            </div>
                                        )
                                    }
                                    else {
                                        return (<>Session not found</>)
                                    }
                                })
                            }
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}