import Iconify from 'src/components/Iconify';
import { ColorSchema } from 'src/theme/palette';

import { Box, Card, CardProps, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.darker,
}));

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 120,
  height: 120,
  opacity: 0.07,
  position: 'absolute',
  right: theme.spacing(-3),
  color: theme.palette.common.black,
}));

// ----------------------------------------------------------------------

interface Props extends CardProps {
  icon: string;
  title: string;
  description: string;
  actions?: React.ReactNode;
  color?: ColorSchema;
}

export default function GenericDashboardTile({
  title,
  description,
  actions,
  icon,
  color,
  onClick,
  ...other
}: Props) {
  const theme = useTheme();

  return (
    <RootStyle
      sx={{
        bgcolor: color ? theme.palette[color].main : theme.palette.grey[300],
        minHeight: '142px',
        cursor: onClick ? 'pointer': undefined
      }}
      onClick={onClick}
      {...other}
    >
      <Box sx={{ ml: 0, color: 'common.black' }}>
        <Typography variant="h6" sx={{ opacity: 0.82 }}>{title}</Typography>
        <Typography variant="body2" sx={{ opacity: 0.82 }}>
          {description}
        </Typography>
      </Box>
      <IconStyle icon={icon} />
    </RootStyle>
  );
}
