import { useState } from 'react';
import { Activ8CoursesProgramsCoursesLearningCourseProgramDto } from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import ParticipantImportDialog from 'src/sections/participant/ParticipantImportDialog';
import { fDateTime } from 'src/utils/formatTime';

// @mui
import {
  Button, Checkbox, MenuItem, TableCell, TableRow, Tooltip, Typography
} from '@mui/material';

// components
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { LearningCourseProgramActions } from '../LearningCourseProgramActions';

// ----------------------------------------------------------------------

interface Props {
  row: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
  selected: boolean;
  hideRole?: boolean;
  onEditRow: VoidFunction;
  onOverviewRow?: VoidFunction;
  onSelectRow: VoidFunction;
  onDataRefetch: VoidFunction;
};

export default function LearningCourseProgramTableRow({
  row,
  selected,
  hideRole,
  onEditRow,
  onOverviewRow,
  onSelectRow,
  onDataRefetch
}: Props) {
  const { internalName, publicName, status, creationTime, lastModificationTime, conductionAddress, programReferenceCode, id } = row;

  const permissionChecker = usePermissionChecker();
  const hasProgramCrudPermission = permissionChecker.has(permissionKeys.tenant.program.createUpdateDelete);

  const hasImportPermission = permissionChecker.has(permissionKeys.tenant.participant.import);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  let statusIcon: string = '';
  let statusIconColor: string = '';
  switch (status) {
    case 'Live':
      statusIcon = 'eva:play-circle-fill';
      statusIconColor = 'success.main';
      break;
    case 'Draft':
      statusIcon = 'eva:edit-outline';
      statusIconColor = 'info.main';
      break;
    case 'Cancelled':
      statusIcon = 'eva:stop-circle-outline';
      statusIconColor = 'error.main';
      break;
    case 'Complete':
      statusIcon = 'eva:checkmark-circle-fill';
      statusIconColor = 'primary.main';
      break;
  }

  return (
    <TableRow hover selected={selected}>
      {hasImportPermission && isImportDialogOpen ? <ParticipantImportDialog assignToProgram={row} isOpen={isImportDialogOpen} onClose={() => setIsImportDialogOpen(false)} /> : undefined}

      {!hasProgramCrudPermission ? undefined :
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>}

      <TableCell align="left">
        <Button onClick={async () => { onOverviewRow ? await onOverviewRow() : await onEditRow() }} variant={'text'}><Typography variant='body2' sx={{ maxWidth: '260px' }} noWrap>{hasProgramCrudPermission ? internalName : publicName}</Typography></Button>
      </TableCell>

      <TableCell align="left">{programReferenceCode}</TableCell>

      <Tooltip title={status as string} arrow>
        <TableCell align="left">
          <Iconify
            icon={statusIcon}
            sx={{
              width: 20,
              height: 20,
              color: statusIconColor
            }}
          />
        </TableCell>
      </Tooltip>

      <TableCell align="left">{conductionAddress}</TableCell>
      {/* <Tooltip arrow title={fDateTime(creationTime || new Date())}><TableCell align="left"><>{fToNow(creationTime || new Date())}</></TableCell></Tooltip>
      <Tooltip arrow title={fDateTime(lastModificationTime || new Date())}><TableCell align="left"><>{fToNow(lastModificationTime || new Date())}</></TableCell></Tooltip> */}

      {!hasProgramCrudPermission ? undefined : <TableCell align="left"><>{creationTime ? fDateTime(creationTime) : ''}</></TableCell>}
      {!hasProgramCrudPermission ? undefined : <TableCell align="left"><>{lastModificationTime ? fDateTime(lastModificationTime) : ''}</></TableCell>}

      {!hasProgramCrudPermission ? undefined :
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                {onOverviewRow ?
                  <MenuItem
                    onClick={() => {
                      onOverviewRow();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'eva:file-text-fill'} />
                    Overview
                  </MenuItem>
                  : undefined}


                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>

                <LearningCourseProgramActions onImport={hasImportPermission ? () => { setIsImportDialogOpen(true); handleCloseMenu() } : undefined} row={row} handleCloseMenu={handleCloseMenu} onDataRefetch={onDataRefetch} />
              </>
            }
          />
        </TableCell>}
    </TableRow>
  );
}
