import { useLocation, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
// sections
import { useSnackbar } from 'notistack';
import { Activ8CoursesTemplatesLearningCourseTemplateCreateDto, Activ8CoursesTemplatesLearningCourseTemplateUpdateDto, appApi } from 'src/api/redux/app/appApi';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import LearningCourseTemplateWizardForm, { LearningTemplateFormValuesSchema } from 'src/sections/courses/templates/wizard/LearningCourseTemplateWizardForm';
import uuidv4 from 'src/utils/uuidv4';

// ----------------------------------------------------------------------

export default function LearningCourseTemplateWizard() {
  const { pathname } = useLocation();
  const { id = uuidv4() } = useParams();
  const isEdit = pathname.includes('/edit');
  const { handleError } = useApiResponseHandler();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getLearningCourseTemplateQuery = isEdit ? appApi.endpoints.learningCourseTemplateGet.useQuery({
    id: id
  },
  {
    refetchOnMountOrArgChange: true
  }) : undefined;

  const getLearningCourseTemplateContentQuery = isEdit ? appApi.endpoints.learningCourseTemplateGetTemplateContent.useQuery({
    courseTemplateId: id
  },
  {
    refetchOnMountOrArgChange: true
  }) : undefined;

  const [ createCourseTemplatePost ] = appApi.endpoints.learningCourseTemplateCreate.useMutation({});
  const [ updateCourseTemplatePost ] = appApi.endpoints.learningCourseTemplateUpdate.useMutation({});

  const templateName = getLearningCourseTemplateQuery?.data?.internalName || '';
  let pageTitle = !isEdit ? 'Create course' : templateName;

  const links = [
    { name: 'Courses', href: PATH_DASHBOARD.master.courseTemplate.list },
  ];

  if(isEdit){
    // links.push({ name: templateName, href: PATH_DASHBOARD.master.courseTemplate.overview(id) })
    pageTitle = 'Edit course';
  }

  links.push({ name: pageTitle, href: '' });

  const isLoading = !isEdit ? false : (getLearningCourseTemplateQuery?.isLoading || getLearningCourseTemplateContentQuery?.isLoading);

  const courseTemplateState = isEdit ?
  (isLoading ? undefined :
  {
    internalName: getLearningCourseTemplateQuery?.data?.internalName,
    publicName: getLearningCourseTemplateQuery?.data?.publicName,
    publicShortDescription: getLearningCourseTemplateQuery?.data?.publicShortDescription,
    status: getLearningCourseTemplateQuery?.data?.status,
    isRecommendedForPartnerPublicWebsite: getLearningCourseTemplateQuery?.data?.isRecommendedForPartnerPublicWebsite,
    enrollmentAllowedForIdentityRoleName: getLearningCourseTemplateQuery?.data?.enrollmentAllowedForIdentityRoleName,
    partnerShortDescription: getLearningCourseTemplateQuery?.data?.partnerShortDescription,
    facilitatorShortDescription: getLearningCourseTemplateQuery?.data?.facilitatorShortDescription,
    //bannerImage: getLearningCourseTemplateQuery?.data?.bannerImage,
    publicLongRichTextDescription: getLearningCourseTemplateQuery?.data?.publicLongRichTextDescription,
    partnerLongRichTextDescription: getLearningCourseTemplateQuery?.data?.partnerLongRichTextDescription,
    facilitatorLongRichTextDescription: getLearningCourseTemplateQuery?.data?.facilitatorLongRichTextDescription,
    // participantWelcomeEmailTemplate getLearningCourseTemplateQuery?.data?.participantWelcomeEmailTemplate,
    facilitatorAccreditationIdsPreRequisites: getLearningCourseTemplateQuery?.data?.accreditationPreRequisites?.filter(a => a.type === 'Facilitator').map(a => a.personAccreditationId) || [],
    participantAccreditationIdsPreRequisites: getLearningCourseTemplateQuery?.data?.accreditationPreRequisites?.filter(a => a.type === 'Participant').map(a => a.personAccreditationId) || [],
    facilitatorAttributeIdsPreRequisites: getLearningCourseTemplateQuery?.data?.attributePreRequisites?.filter(a => a.type === 'Facilitator').map(a => a.personAttributeId),
    postCompletionAccreditationsIds: getLearningCourseTemplateQuery?.data?.postCompletionAccreditations?.map(a => a.personAccreditationId) || [],
    licensedTenantIds: (getLearningCourseTemplateQuery?.data?.tenantLicenses || []).map(a => a.licensedToTenantId),
    chapters: getLearningCourseTemplateContentQuery?.data?.chapters?.map(c => ({
        ...c,
        duration: (c.duration as string) || '00:00:00',
        sessions: c.sessions?.map(s => ({      
          ...s,
          duration: (s.duration as string) || '00:00:00'
        })) || [],
      })) || [],
    // AccreditationPreRequisite: getLearningCourseTemplateQuery?.data?.AccreditationPreRequisite,
    // AttributePreRequisite: getLearningCourseTemplateQuery?.data?.AttributePreRequisite,
    // PostCompletionAccreditation: getLearningCourseTemplateQuery?.data?.PostCompletionAccreditation,
  } as LearningTemplateFormValuesSchema)
  : 
  {
    internalName: '',
    publicName: '',
    publicShortDescription: '',
    status: 'Draft',
    isRecommendedForPartnerPublicWebsite: false,
    enrollmentAllowedForIdentityRoleName: 'participant',
    partnerShortDescription: '',
    facilitatorShortDescription: '',
    //bannerImag '',
    publicLongRichTextDescription: '',
    partnerLongRichTextDescription: '',
    facilitatorLongRichTextDescription: '',
    // participantWelcomeEmailTemplate: '',
    facilitatorAccreditationIdsPreRequisites: [],
    participantAccreditationIdsPreRequisites: [],
    facilitatorAttributeIdsPreRequisites: [], 
    postCompletionAccreditationsIds: [],
    licensedTenantIds: [],
    chapters: [],
    // AccreditationPreRequisites
    // AttributePreRequisites
    // PostCompletionAccreditations
  } as LearningTemplateFormValuesSchema;

  function convertFormToApiRequestBody(formValues: LearningTemplateFormValuesSchema): Activ8CoursesTemplatesLearningCourseTemplateCreateDto | Activ8CoursesTemplatesLearningCourseTemplateUpdateDto {
    return  {
      content: {
        chapters: (formValues.chapters?.map(c => ({
          ...c,
          duration: (c.duration || '00:00:00') as any,
          sessions: c.sessions?.map(s => ({      
            ...s,
            duration: (s.duration || '00:00:00') as any,
            content: (
              s.content?.map(con => ({
                ...con,
                quiz: con.quiz as any
              }))
            )
          })) || [],
        }))) || []
      },
      internalName: formValues.internalName,
      publicName: formValues.publicName,
      publicShortDescription: formValues.publicShortDescription,
      status: formValues.status,
      isRecommendedForPartnerPublicWebsite: formValues.isRecommendedForPartnerPublicWebsite,
      enrollmentAllowedForIdentityRoleName: formValues.enrollmentAllowedForIdentityRoleName,
      partnerShortDescription: formValues.partnerShortDescription,
      facilitatorShortDescription: formValues.facilitatorShortDescription,
      bannerImage: {},
      publicLongRichTextDescription: formValues.publicLongRichTextDescription,
      partnerLongRichTextDescription: formValues.partnerLongRichTextDescription,
      facilitatorLongRichTextDescription: formValues.facilitatorLongRichTextDescription,
      participantWelcomeEmailTemplate: '',
      // accreditationPreRequisites: [], //formValues.facilitatorAccreditationIdsPreRequisites,
      // attributePreRequisites: [], //formValues.attributePreRequisites,
      // postCompletionAccreditations: [], //formValues.postCompletionAccreditations,
      licensedTenantIds: formValues.licensedTenantIds as string[],
      facilitatorAccreditationIdsPreRequisites: formValues.facilitatorAccreditationIdsPreRequisites as string[],
      participantAccreditationIdsPreRequisites: formValues.participantAccreditationIdsPreRequisites as string[],
      facilitatorAttributeIdsPreRequisites: formValues.facilitatorAttributeIdsPreRequisites as string[],
      postCompletionAccreditationsIds: formValues.postCompletionAccreditationsIds as string[],
    }
  }

  const onSubmit = async (template: LearningTemplateFormValuesSchema): Promise<void> => {
    try {
      if(isEdit){
        await updateCourseTemplatePost({ activ8CoursesTemplatesLearningCourseTemplateUpdateDto: convertFormToApiRequestBody(template), id: id }).unwrap();
      }
      else{
        await createCourseTemplatePost({ activ8CoursesTemplatesLearningCourseTemplateCreateDto: { ...convertFormToApiRequestBody(template), newId: id } }).unwrap();
      }

      getLearningCourseTemplateQuery?.refetch();
      getLearningCourseTemplateContentQuery?.refetch();
    }
    catch(error){
      handleError(error);
      return;
    }

    navigate(PATH_DASHBOARD.master.courseTemplate.list);
    enqueueSnackbar(!isEdit ? 'Created!' : 'Updated!');          
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={'xl'}>
        {
          courseTemplateState ?        
            <LearningCourseTemplateWizardForm defaultValues={courseTemplateState} onSubmit={(template) => onSubmit(template)} courseTemplateId={id} isEdit={isEdit} links={links} />
        : undefined }
      </Container>
    </Page>
  );
}
