import { useSnackbar } from 'notistack';

import { Stack, Typography } from '@mui/material';

export default function useApiResponseHandler() {
  const { enqueueSnackbar } = useSnackbar();

  return {
    handleError: (error: Error, title?: string) => {
      if(!error) return;

      let message = (error as any).error?.message;

      if((error as any)?.data?.error?.details){
        // try validation messages
        message = (error as any)?.data?.error?.details;
      }

      if(!message){
        message = (error as any).error?.message
      }

      if(!message){
        message = (error as any).data?.error?.message
      }

      console.error('API error', error);

      if(!message && (error as any).status === 403){
        message = "Permission not granted";
      }

      if(!message){
        message = "Failed!";
      }

      if(title){
        enqueueSnackbar(<Stack><Typography variant='body1'>{title}</Typography><Typography variant='body2'>{message}</Typography></Stack>, { variant: 'error', autoHideDuration: 5000 });
      }
      else{
        enqueueSnackbar(message, { variant: 'error', autoHideDuration: 5000 });
      }
    }
  }
}
