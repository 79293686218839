// form
import { useFormContext } from 'react-hook-form';
import { appApi } from 'src/api/redux/app/appApi';

// @mui
import { ListItemText, MenuItem, SelectProps } from '@mui/material';

import RHFMultiSelect from './RHFMultiSelect';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  helperText?: React.ReactNode;
  preventMultiple?: boolean;
};

type Props = IProps & SelectProps;

export default function RHFTenantMultiSelect({ name, label, helperText, preventMultiple, ...other }: Props) {
  const tenantQuery = appApi.endpoints.tenantGetList.useQuery({
    maxResultCount: 200
  });
  const { control } = useFormContext();

  return (
    <RHFMultiSelect
      disabled={tenantQuery.isLoading || tenantQuery.isError}
      name={name}
      label={label || "Partners"}
      helperText={helperText}
      preventMultiple={preventMultiple}
      getSelectedOptionText={(selected) => (tenantQuery?.data?.items || []).find(a => a.id === selected)?.name || selected}
    >
      {(tenantQuery?.data?.items || []).map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
        >
          <ListItemText primary={option.name} />
        </MenuItem>
      ))}
    </RHFMultiSelect>
  );
}
