import useAppUserContext from './useAppUserContext';

function hasPermission(
  key: string,
  grantedPolicies: {
    [key: string]: boolean | null;
  } | null
): boolean {
  return grantedPolicies != null && grantedPolicies[key] != null && (grantedPolicies[key] === true || grantedPolicies[key]?.toString().toLowerCase() === "true");
}

export default function usePermissionChecker() {
  const userContext = useAppUserContext();
  const currentGrantedPermissions = userContext?.auth?.grantedPolicies ? userContext?.auth?.grantedPolicies : {};

  return {
    has: (key: string): boolean => {
      if(!key) return false;
      return hasPermission(key, currentGrantedPermissions)
    },
    hasAny: (keys: string[]): boolean => {
      if(!keys || keys.length === 0) return false;
      for (const key in keys) {
        if (Object.prototype.hasOwnProperty.call(keys, key)) {
          const element = keys[key];
          if(hasPermission(element, currentGrantedPermissions)) return true;
        }
      }

      return false;
    },
    hasAll: (keys: string[]): boolean => {
      if(!keys || keys.length === 0) return false;
      for (const key in keys) {
        if (Object.prototype.hasOwnProperty.call(keys, key)) {
          const element = keys[key];
          if(!hasPermission(element, currentGrantedPermissions)) return false;
        }
      }

      return true;
    },
  };
}
