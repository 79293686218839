import useAppUserContext from './useAppUserContext';

function hasFeature(
  key: string,
  grantedFeatures: {
    [key: string]: string | null;
  } | null
): boolean {
  return grantedFeatures != null && grantedFeatures[key] != null && grantedFeatures[key]?.toString().toLowerCase() === "true";
}

export default function useFeatureChecker() {
  const userContext = useAppUserContext();
  const currentGrantedFeatures = userContext?.features?.values ? userContext.features.values : {};

  return {
    has: (key: string): boolean => {
      if(!key) return false;
      return hasFeature(key, currentGrantedFeatures)
    },
    hasAny: (keys: string[]): boolean => {
      if(!keys || keys.length === 0) return false;
      for (const key in keys) {
        if (Object.prototype.hasOwnProperty.call(keys, key)) {
          const element = keys[key];
          if(hasFeature(element, currentGrantedFeatures)) return true;
        }
      }

      return false;
    },
    hasAll: (keys: string[]): boolean => {
      if(!keys || keys.length === 0) return false;
      for (const key in keys) {
        if (Object.prototype.hasOwnProperty.call(keys, key)) {
          const element = keys[key];
          if(!hasFeature(element, currentGrantedFeatures)) return false;
        }
      }

      return true;
    },
  };
}
