import RHFTenantMultiSelect from 'src/components/hook-form/RHFTenantMultiSelect';

import { Stack, Typography } from '@mui/material';

export default function LearningCourseTemplateWizardFormLicensingStep() {
  return (
    <Stack rowGap={6}>
      <Stack gap={1}>
      <Typography variant='h5' gutterBottom>License this course to partners</Typography>
        <Stack gap={4}>
          <RHFTenantMultiSelect name="licensedTenantIds" />
        </Stack>
      </Stack>
    </Stack>  
  )
}