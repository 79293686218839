import { kebabCase } from 'change-case';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import {
    Activ8CoursesProgramsCoursesLearningCourseProgramDto,
    Activ8CoursesProgramsLearningCourseProgramStatus, appApi
} from 'src/api/redux/app/appApi';
import permissionKeys from 'src/appPermissionKeys';
import Iconify from 'src/components/Iconify';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import usePermissionChecker from 'src/hooks/usePermissionChecker';
import { PATH_DASHBOARD } from 'src/routes/paths';

import { Alert, MenuItem, Stack, TextField, Typography } from '@mui/material';

export interface LearningCourseProgramActionsProps {
    row: Activ8CoursesProgramsCoursesLearningCourseProgramDto;
    handleCloseMenu: VoidFunction;
    onDataRefetch: VoidFunction;
    onImport: VoidFunction | undefined;
};

export function LearningCourseProgramActions({ row, handleCloseMenu, onDataRefetch, onImport }: LearningCourseProgramActionsProps) {
    const confirm = useConfirm();
    const { enqueueSnackbar } = useSnackbar();
    const { status } = row;
    const [learningCourseProgramDeletePost] = appApi.endpoints.learningCourseProgramDelete.useMutation();
    const [learningCourseProgramSetProgramStatusPost] = appApi.endpoints.learningCourseProgramSetProgramStatus.useMutation();
    const [learningCourseProgramClonePost] = appApi.endpoints.learningCourseProgramCloneCourseProgram.useMutation();
    const navigate = useNavigate();
    const cancellationReasonRef = useRef('');
    const { handleError } = useApiResponseHandler();
    const handleCancellationReasonTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // console.log('heree event.target.value', event.target.value)
        cancellationReasonRef.current = event.target.value;
    };

    const navigateToProgram = (id: string) => {
        navigate(PATH_DASHBOARD.tenant.program.edit(kebabCase(id)));
    };

    const handleCloneRow = () => {
        const id = row.id;
        confirm({
            title: 'Clone program',
            content:
                <Stack gap={3}>
                    <Typography variant='body1'>{`Are you sure you want to clone this program?`}</Typography>
                    <Alert severity='info'><Stack gap={2}><p>All details will be cloned.</p><p>The cloned program will be set as draft and not be assigned to any participants or facilitators.</p></Stack></Alert>
                </Stack>
        })
            .then(async () => {
                try {
                    const newProgramId = await learningCourseProgramClonePost({
                        courseProgramId: id as string
                    }).unwrap();
                    enqueueSnackbar(`Program cloned successfully`, { autoHideDuration: 10000 });
                    navigateToProgram(newProgramId);

                    onDataRefetch();
                }
                catch (error) {
                    handleError(error);
                }
            })
            .catch(() => { });;
    };

    const handleChangeStatusRow = (newStatus: Activ8CoursesProgramsLearningCourseProgramStatus) => {
        const id = row.id as string;
        let warningText: string = '';
        switch (newStatus) {
            case 'Draft':
                warningText = 'This action will stop facilitators and participants to view the program and complete online modules.';
                break;
            case 'Live':
                warningText = 'This action will allow facilitators and participants to view the program and complete online modules.';
                break;
            case 'Complete':
                warningText = 'This action will stop facilitators and participants to view the program and complete online modules.';
                break;
            case 'Cancelled':
                warningText = 'This action will stop facilitators and participants to view the program and complete online modules.';
                break;
        }
        confirm({
            title: 'Change program status',
            // confirmationButtonProps: {
            //     disabled: newStatus == 'Cancelled' ? !cancellationReasonText : false
            // },
            content:
                <Stack gap={3}>
                    {
                        newStatus === 'Cancelled' ?
                            <>
                                <Typography variant='body1'>{`Are you sure you want to cancel this program?`}</Typography>
                                <TextField inputRef={cancellationReasonRef} required defaultValue={''} onChange={(e) => handleCancellationReasonTextChange(e)} label='Cancellation reason' helperText='Reason will be provided to participants' />
                                <Alert severity='warning'>An email will be sent to all participants informing them of the cancellation</Alert>
                            </>
                            :
                            <>
                                <Typography variant='body1'>{`Are you sure you want to change the program status to ${newStatus}?`}</Typography>
                                <Alert severity='info'>{warningText}</Alert>
                            </>
                    }
                </Stack>
        })
            .then(async () => {
                await handleSendSetStatus(id, newStatus);
            })
            .catch(() => { });;
    };

    const handleSendSetStatus = async (id: string, newStatus: Activ8CoursesProgramsLearningCourseProgramStatus) => {
        try {
            await learningCourseProgramSetProgramStatusPost({
                activ8CoursesProgramsCoursesProgramStatusSetRequestDto: {
                    learningCourseProgramId: id,
                    status: newStatus,
                    cancellationReasonMessage: newStatus === 'Cancelled' ? cancellationReasonRef.current : ''
                }
            }).unwrap();
            enqueueSnackbar('Program status updated');
            onDataRefetch();
        }
        catch (error) {
            handleError(error);
        }
    }

    const handleDeleteRow = () => {
        confirm({ description: `Are you sure you want to delete the program?` })
            .then(async () => {
                try {
                    await learningCourseProgramDeletePost({ id: row.id as string }).unwrap();
                    enqueueSnackbar(`Program deleted`);
                    onDataRefetch();
                }
                catch (error) {
                    handleError(error);
                }
            })
            .catch(() => { });;
    };

    return (
        <>
            {status !== 'Live' ?
                <MenuItem
                    onClick={() => {
                        handleChangeStatusRow('Live');
                        handleCloseMenu();
                    }}
                    sx={{ color: 'success.main' }}
                >
                    <Iconify icon={'eva:play-circle-fill'} />
                    Set as live
                </MenuItem>
                : undefined}

            {status !== 'Complete' ?
                <MenuItem
                    onClick={() => {
                        handleChangeStatusRow('Complete');
                        handleCloseMenu();
                    }}
                    sx={{ color: 'success.main' }}
                >
                    <Iconify icon={'eva:checkmark-circle-fill'} />
                    Set complete
                </MenuItem>
                : undefined}

            {
                status !== 'Draft' ?
                    <MenuItem
                        onClick={() => {
                            handleChangeStatusRow('Draft');
                            handleCloseMenu();
                        }}
                    >
                        <Iconify icon={'eva:edit-outline'} />

                        Set as draft
                    </MenuItem>
                    : undefined}

            {
                status !== 'Cancelled' ?
                    <MenuItem
                        onClick={() => {
                            handleChangeStatusRow('Cancelled');
                            handleCloseMenu();
                        }}
                        sx={{ color: 'error.main' }}
                    >
                        <Iconify icon={'eva:stop-circle-outline'} />
                        Cancel
                    </MenuItem>
                    : undefined
            }

            {onImport && (status === 'Draft' || status === 'Live') ?
                <MenuItem
                    onClick={() => {
                        onImport();
                    }}
                >
                    <Iconify icon={'eva:cloud-upload-outline'} />
                    Import
                </MenuItem> : undefined}

            <MenuItem
                onClick={() => {
                    handleCloneRow();
                    handleCloseMenu();
                }}
            >
                <Iconify icon={'eva:copy-fill'} />
                Clone
            </MenuItem>

            <MenuItem
                onClick={() => {
                    handleDeleteRow();
                    handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
            >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
            </MenuItem>
        </>
    )
}