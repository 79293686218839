// form

import { Controller, useFormContext } from 'react-hook-form';

import { FormHelperText, TextField, TextFieldProps } from '@mui/material';

// @mui


// ----------------------------------------------------------------------

type IProps = {
  name: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  disableAutoComplete?: boolean;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({ name, endAdornment, startAdornment, helperText, disableAutoComplete, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const helperTextComponent = error?.message && helperText ? 
                    <>
                      <span style={{display: 'block'}}>{error?.message}</span>
                      <span style={{display: 'block'}}>{helperText}</span>
                    </>
                    : (helperText ? helperText : 
                      (error?.message ? <>{error?.message}</> : undefined)
                      )

        return (
          <TextField InputProps={{ startAdornment: startAdornment, endAdornment: endAdornment }} {...field} inputProps={{...other.inputProps, autoComplete: disableAutoComplete ? 'no' : other.autoComplete}} fullWidth error={!!error} {...other} helperText={helperTextComponent}  FormHelperTextProps={{ component: 'div'} as any} />
        )
      }}
    />
  );
}
