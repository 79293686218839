import appSettings from 'src/appSettings';

function setLoggedInUser(tokenRes: any) {
    const { access_token, refresh_token, scope: grantedScopes, expires_in } = tokenRes;
    const storage = localStorage;
    storage.setItem('access_token', access_token);
    storage.setItem('refresh_token', refresh_token);
    storage.setItem('access_token_stored_at', '' + Date.now());
    if (grantedScopes) {
        storage.setItem('granted_scopes', JSON.stringify(grantedScopes.split(' ')));
    }
    if (expires_in) {
        const expiresInMilliSeconds = expires_in * 1000;
        const now = new Date();
        const expiresAt = now.getTime() + expiresInMilliSeconds;
        storage.setItem('expires_at', '' + expiresAt);
    }

    // const oidcStorage = sessionStorage.getItem(`oidc.user:${appSettings.oAuthAuthority}:${appSettings.oAuthClientId}`)
    // if (!oidcStorage) {
    //     return null;
    // }
    // console.log('heree impersonate', tokenRes);

    const storageUser = JSON.parse(sessionStorage.getItem(`oidc.user:${appSettings.oAuthAuthority}:${appSettings.oAuthClientId}`) as string);
    storageUser.access_token = access_token;
    storageUser.refresh_token = refresh_token;
    sessionStorage.setItem(`oidc.user:${appSettings.oAuthAuthority}:${appSettings.oAuthClientId}`, JSON.stringify(storageUser));
}

export default setLoggedInUser;