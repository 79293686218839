import { useFieldArray } from 'react-hook-form';

import { Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';

import {
    LearningCourseProgramEditableChapterSessionItem
} from './LearningCourseProgramEditableChapterSessionItem';
import { LearningProgramChapterFormValuesSchema } from './wizard/LearningCourseProgramWizardForm';
import {
    ILearningCourseProgramWizardFormDeliveryStepProps
} from './wizard/Steps/LearningCourseProgramWizardFormDeliveryStep';

export type ILearningCourseProgramEditableChapterItemProps = {
    chapter: LearningProgramChapterFormValuesSchema;
    chapterIndex: number;
} & ILearningCourseProgramWizardFormDeliveryStepProps

export function LearningCourseProgramEditableChapterItem({ courseProgramId, courseTemplate, courseTemplateContent, chapter, chapterIndex, control }: ILearningCourseProgramEditableChapterItemProps) {
    const { fields, append, remove } = useFieldArray({
        control,
        name: `chapters.${chapterIndex}.sessions` as unknown as 'chapters.0.sessions',

        // shouldUnregister: false,
    });

    const contentChapter = courseTemplateContent?.chapters?.find(c => c.id === chapter.learningCourseTemplateContentChapterId);

    return (
        <Grid item xs={12}>
            <Card variant='outlined'>
                <CardContent sx={{ width: '100%' }}>
                    <Stack direction='column' rowGap={1}>
                        <Typography variant='h6'>
                            {contentChapter?.name}
                        </Typography>

                        <Stack direction='column' rowGap={2} divider={<Divider orientation="horizontal" flexItem />} justifyContent='space-between' sx={{ flexGrow: 1 }}>
                            {
                                fields.map((session, index) =>
                                    <div key={session.id} style={{ width: '100%' }}>
                                        <LearningCourseProgramEditableChapterSessionItem chapter={chapter} courseTemplate={courseTemplate} courseTemplateContent={courseTemplateContent} courseProgramId={courseProgramId} session={session} chapterIndex={chapterIndex} sessionIndex={index} control={control} />
                                    </div>
                                )
                            }
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}