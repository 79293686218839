import * as React from 'react';

import { Chip, MenuListProps, PaperProps } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface IProps {
    label: string;
    id: string;
    stopPropagation?: boolean;
    variant?: 'filled' | 'outlined' | undefined;
    size?: 'small' | 'medium' | undefined;
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
    children?: React.ReactNode;
    icon?: React.ReactElement;
    MenuListProps?: MenuListProps;
    PaperProps?: PaperProps;
}

export default function ChipMenuButton({ id, label, variant, color, size, children, stopPropagation, icon, MenuListProps, PaperProps }: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if(stopPropagation) event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    if(stopPropagation) event.stopPropagation();

    setAnchorEl(null);
  };

  return (
    <div>
      <Chip
        id={"chipmenu-button-" + id}
        aria-controls={open ? 'chipmenu-menu-' + id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        label={label}
        variant={variant}
        color={color}
        size={size}
        icon={icon}
       />
       {children ? 
        <Menu
          id={"chipmenu-menu-" + id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          closeAfterTransition={true}
          MenuListProps={{
            'aria-labelledby': 'chipmenu-button-' + id,
            ...MenuListProps
          }}
          PaperProps={{
            ...PaperProps
          }}
        >
          {children}
          {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem> */}
        </Menu> : undefined
        }
    </div>
  );
}