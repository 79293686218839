import { sentenceCase } from 'change-case';
import { get } from 'lodash-es';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Activ8AppNotificationsAppNotification } from 'src/api/redux/app/appApi';
import appNotificationTypeKeys from 'src/appNotificationTypeKeys';
import { PATH_DASHBOARD } from 'src/routes/paths';
import uuidv4 from 'src/utils/uuidv4';

import {
  Alert, AlertColor, Box, Button, Card, CardHeader, CardProps, Divider, MenuItem, Stack, Table,
  TableBody, TableCell, TableContainer, TableRow
} from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';

import Iconify from '../../../../components/Iconify';
// components
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import { TableHeadCustom, TableMoreMenu } from '../../../../components/table';
// utils
import { fCurrency } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------


interface Props extends CardProps {
  title?: string;
  tableData: Activ8AppNotificationsAppNotification[];
}

export default function AppNotifications({
  title,
  tableData,
  ...other
}: Props) {

  return (
    <Card color='primary' {...other}>
      <CardHeader title={title} sx={{ mb: 1.2, pt: 1.4 }} />

      <Stack sx={{ width: '100%' }} spacing={1}>
        {!tableData || tableData.length === 0 ?
          <>
            <Alert severity='success'>All clear! You have no notifications requiring action</Alert>
          </>
          :
          tableData.map((row) => {
            const key = uuidv4();
            return (
              <AppNotificationRow key={key} {...row} />
            )
          })
        }
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------


function AppNotificationRow(row: Activ8AppNotificationsAppNotification) {
  let severity: AlertColor = 'info';
  const navigate = useNavigate();

  switch (row.severity) {
    case 'Information': severity = 'info'; break;
    case 'Warning': severity = 'warning'; break;
    case 'Danger': severity = 'error'; break;
  }

  let action: React.ReactNode = undefined;
  switch (row.typeKey) {
    case appNotificationTypeKeys.tenant.partner.profile.incomplete:
      action = <Button variant='outlined' color="inherit" size="small" onClick={() => navigate(PATH_DASHBOARD.tenant.partner.profile.edit)}>Complete profile</Button>;
      break;
    case appNotificationTypeKeys.tenant.program.onlineModulesIncomplete:
      action = <Button variant='outlined' color="inherit" size="small" onClick={() => navigate(PATH_DASHBOARD.tenant.program.overview(get(row.properties, 'CourseProgramId')))}>View program</Button>;
      break;
  }

  return (
    <Alert severity={severity} action={action}>{row.textMessage}</Alert>
  );
}
