import { Activ8CoursesTemplatesLearningCourseTemplateDto } from 'src/api/redux/app/appApi';

import { Grid, Paper, Stack } from '@mui/material';

type Props = {
    template: Activ8CoursesTemplatesLearningCourseTemplateDto;
  };
  
  export default function LearningCourseTemplateOverviewDashboard({ template }: Props) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
            <Stack direction='column' spacing={2} >
              {/* <UserDetailsCard user={user} />
              <UserParentDetailsCard user={user} /> */}
            </Stack>
        </Grid>
      </Grid>
    )
  }