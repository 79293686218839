import { kebabCase } from 'change-case';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import {
    Activ8CoursesTemplatesLearningCourseTemplateDto,
    Activ8CoursesTemplatesLearningCourseTemplateStatus, appApi
} from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import { PATH_DASHBOARD } from 'src/routes/paths';

import { Alert, Icon, MenuItem, Stack, Typography } from '@mui/material';

export interface LearningCourseTemplateActionsProps {
    row: Activ8CoursesTemplatesLearningCourseTemplateDto;
    handleCloseMenu: VoidFunction;
    onDataRefetch: VoidFunction;
    handleDownloadContent: VoidFunction;
};

export function LearningCourseTemplateActions({ row, handleCloseMenu, onDataRefetch, handleDownloadContent }: LearningCourseTemplateActionsProps) {
    const confirm = useConfirm();
    const { enqueueSnackbar } = useSnackbar();
    const { internalName, status, creationTime, lastModificationTime, enrollmentAllowedForIdentityRoleName, courseReferenceCode } = row;
    const [learningCourseTemplateDeletePost] = appApi.endpoints.learningCourseTemplateDelete.useMutation();
    const [learningCourseTemplateSetTemplateStatusPost] = appApi.endpoints.learningCourseTemplateSetTemplateStatus.useMutation();
    const [learningCourseTemplateClonePost] = appApi.endpoints.learningCourseTemplateCloneCourseTemplate.useMutation();
    const navigate = useNavigate();
    const { handleError } = useApiResponseHandler();

    const navigateToTemplate = (id: string) => {
        navigate(PATH_DASHBOARD.master.courseTemplate.edit(kebabCase(id)));
    };

    const handleCloneRow = () => {
        const id = row.id;
        confirm({
            title: 'Clone course template',
            content:
                <Stack gap={3}>
                    <Typography variant='body1'>{`Are you sure you want to clone this course?`}</Typography>
                    <Alert severity='info'><Stack gap={2}><p>All details, chapters and content will be cloned.</p><p>The cloned course will be set as draft and not be assigned to any partners.</p></Stack></Alert>
                </Stack>
        })
            .then(async () => {
                try {
                    const newTemplateId = await learningCourseTemplateClonePost({
                        courseTemplateId: id as string
                    }).unwrap();
                    enqueueSnackbar(`Course cloned successfully`, { autoHideDuration: 10000 });
                    navigateToTemplate(newTemplateId);

                    onDataRefetch();
                }
                catch (error) {
                    handleError(error);
                }
            })
            .catch(() => { });;
    };

    const handleChangeStatusRow = (status: Activ8CoursesTemplatesLearningCourseTemplateStatus) => {
        const id = row.id as string;
        let warningText: string = '';
        switch (status) {
            case 'Draft':
            case 'Paused':
                warningText = 'This will prevent any new programs from being created by licensed partners for this course but the change will not affect any programs that are currently in progress or have ran to completion.';
                break;
            case 'Live':
                warningText = 'This will allow licensed partners to create programs for this course.';
                break;
        }
        confirm({
            title: 'Change course status',
            description:
                <Stack gap={3}>
                    <Typography variant='body1'>{`Are you sure you want to change the course status to ${status}?`}</Typography>
                    <Alert severity='info'>{warningText}</Alert>
                </Stack>
        })
            .then(async () => {
                try {
                    await learningCourseTemplateSetTemplateStatusPost({
                        activ8CoursesTemplatesTemplateStatusSetRequestDto: {
                            learningCourseTemplateId: id,
                            status: status
                        }
                    }).unwrap();
                    enqueueSnackbar('Course status updated');
                    onDataRefetch();
                }
                catch (error) {
                    handleError(error);
                }
            })
            .catch(() => { });;
    };

    const handleDeleteRow = () => {
        confirm({ description: `Are you sure you want to delete the course?` })
            .then(async () => {
                try {
                    await learningCourseTemplateDeletePost({ id: row.id as string }).unwrap();
                    enqueueSnackbar(`Course deleted`);
                    onDataRefetch();
                }
                catch (error) {
                    handleError(error);
                }
            })
            .catch(() => { });
    };

    return (
        <>
            {status !== 'Live' ?
                <MenuItem
                    onClick={() => {
                        handleChangeStatusRow('Live');
                        handleCloseMenu();
                    }}
                    sx={{ color: 'success.main' }}
                >
                    <Iconify icon={'eva:play-circle-fill'} />
                    Set as live
                </MenuItem>
                : undefined}

            {
                status !== 'Draft' ?
                    <MenuItem
                        onClick={() => {
                            handleChangeStatusRow('Draft');
                            handleCloseMenu();
                        }}
                    >
                        <Iconify icon={'eva:edit-outline'} />

                        Set as draft
                    </MenuItem>
                    : undefined}

            {
                status !== 'Paused' ?
                    <MenuItem
                        onClick={() => {
                            handleChangeStatusRow('Paused');
                            handleCloseMenu();
                        }}
                    >
                        <Iconify icon={'eva:pause-circle-outline'} />
                        Set as paused
                    </MenuItem>
                    : undefined}

            <MenuItem
                onClick={() => {
                    handleCloneRow();
                    handleCloseMenu();
                }}
            >
                <Iconify icon={'eva:copy-fill'} />
                Clone
            </MenuItem>

            <MenuItem
                onClick={() => {
                    handleDownloadContent();
                    handleCloseMenu();
                }}
            >
                <Iconify icon={'eva:download-outline'} />
                Content
            </MenuItem>

            <MenuItem
                onClick={() => {
                    handleDeleteRow();
                    handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
            >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
            </MenuItem>
        </>
    )
}