import { groupBy, map } from 'lodash-es';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Activ8CoursesProgramsCoursesLearningCourseProgramCreateDto,
  Activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto, appApi
} from 'src/api/redux/app/appApi';
import useApiResponseHandler from 'src/hooks/useApiResponseHandler';
import LearningCourseProgramWizardForm, {
  LearningProgramFormValuesSchema
} from 'src/sections/courses/programs/wizard/LearningCourseProgramWizardForm';
import { isJavaScriptDate } from 'src/utils/formatTime';
import uuidv4 from 'src/utils/uuidv4';

import { Container } from '@mui/material';

import Page from '../../../components/Page';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function LearningCourseProgramWizard() {
  const { pathname } = useLocation();
  const { id = uuidv4() } = useParams();
  const isEdit = pathname.includes('/edit');
  const { handleError } = useApiResponseHandler();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getLearningCourseProgramQuery = isEdit ? appApi.endpoints.learningCourseProgramGet.useQuery({
    id: id
  },
    {
      refetchOnMountOrArgChange: true
    }) : undefined;

  const [createCourseProgramPost] = appApi.endpoints.learningCourseProgramCreate.useMutation({});
  const [updateCourseProgramPost] = appApi.endpoints.learningCourseProgramUpdate.useMutation({});
  const [learningCourseProgramFacilitatorCreatePost] = appApi.endpoints.learningCourseProgramFacilitatorCreate.useMutation({});

  const programName = getLearningCourseProgramQuery?.data?.internalName || '';
  let pageTitle = !isEdit ? 'Create program' : programName;

  const links = [
    { name: 'Programs', href: PATH_DASHBOARD.tenant.program.list },
  ];

  if (isEdit) {
    links.push({ name: programName, href: PATH_DASHBOARD.tenant.program.overview(id) })
    pageTitle = 'Edit program';
  }

  links.push({ name: isEdit ? 'Edit program' : pageTitle, href: '' });

  const isLoading = !isEdit ? false : (getLearningCourseProgramQuery?.isLoading);

  const courseProgramState = isEdit ?
    (isLoading ? undefined :
      {
        learningCourseTemplateId: getLearningCourseProgramQuery?.data?.learningCourseTemplateId,
        // assignFacilitatorIdentityUserIds: getLearningCourseProgramQuery?.data?.facilitators?.map(f => { return {id: f.facilitatorIdentityUserId, label: `${f.facilitatorIdentityUser?.name || ''} ${f.facilitatorIdentityUser?.surname || ''}` }}) || [],
        internalName: getLearningCourseProgramQuery?.data?.internalName,
        publicName: getLearningCourseProgramQuery?.data?.publicName,
        publicShortDescription: getLearningCourseProgramQuery?.data?.publicShortDescription,
        publicLongRichTextDescription: getLearningCourseProgramQuery?.data?.publicLongRichTextDescription,
        status: getLearningCourseProgramQuery?.data?.status,
        isListedOnPartnerPublicWebsite: getLearningCourseProgramQuery?.data?.isListedOnPartnerPublicWebsite,
        conductionAddress: getLearningCourseProgramQuery?.data?.conductionAddress,
        conductionIanaTimezone: getLearningCourseProgramQuery?.data?.conductionIanaTimezone,
        supportContactEmail: getLearningCourseProgramQuery?.data?.supportContactEmail,
        supportContactPhone: getLearningCourseProgramQuery?.data?.supportContactPhone,
        //bannerImage
        chapters: map(groupBy((getLearningCourseProgramQuery?.data?.sessions || []), a => a.learningCourseTemplateContentChapterId as string), (sessionGroup, chapterId) => {
          // console.log('heree chGroup', chGroup);
          return {
            learningCourseTemplateContentChapterId: chapterId,
            sessions: sessionGroup.map(s => {
              return {
                ...s
              }
            })
          }
        }),
      } as LearningProgramFormValuesSchema)
    :
    {
      learningCourseTemplateId: '',
      // assignFacilitatorIdentityUserIds: [],
      internalName: '',
      publicName: '',
      publicShortDescription: '',
      publicLongRichTextDescription: '',
      status: 'Draft',
      isListedOnPartnerPublicWebsite: false,
      conductionAddress: '',
      conductionIanaTimezone: '',
      supportContactEmail: '',
      supportContactPhone: '',
      //bannerImage
      chapters: [],
    } as LearningProgramFormValuesSchema;

  function convertFormToApiRequestBody(formValues: LearningProgramFormValuesSchema): Activ8CoursesProgramsCoursesLearningCourseProgramCreateDto | Activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto {
    // console.log('heree convertFormToApiRequestBody', formValues)
    return {
      learningCourseTemplateId: formValues.learningCourseTemplateId,
      // assignFacilitatorIdentityUserIds: (formValues.assignFacilitatorIdentityUserIds || []).map(a => a.id),
      internalName: formValues.internalName,
      publicName: formValues.publicName,
      publicShortDescription: formValues.publicShortDescription,
      publicLongRichTextDescription: formValues.publicLongRichTextDescription,
      status: formValues.status,
      isListedOnPartnerPublicWebsite: formValues.isListedOnPartnerPublicWebsite,
      conductionAddress: formValues.conductionAddress,
      conductionIanaTimezone: formValues.conductionIanaTimezone,
      supportContactEmail: formValues.supportContactEmail,
      supportContactPhone: formValues.supportContactPhone,
      sessions: (formValues.chapters || []).flatMap(c => c.sessions as any).map(s => {
        // console.log('heree c.sessions', s.sessionDate, DateTime.fromISO(s.sessionDate), DateTime.fromJSDate(s.sessionDate));
        return {
          ...s,
          sessionDate: s.sessionDate === null || s.sessionDate === undefined || s.sessionDate === '' ? null : (isJavaScriptDate(s.sessionDate) ? DateTime.fromJSDate(s.sessionDate) : DateTime.fromISO(s.sessionDate)).toFormat('yyyy-MM-dd'),
          sessionStartTime: s.sessionStartTime === null || s.sessionStartTime === undefined || s.sessionStartTime === '' ? null : DateTime.fromISO('2000-01-01T' + s.sessionStartTime).toFormat('HH:mm:ss.0'),
          sessionFinishTime: s.sessionFinishTime === null || s.sessionFinishTime === undefined || s.sessionFinishTime === '' ? null : DateTime.fromISO('2000-01-01T' + s.sessionFinishTime).toFormat('HH:mm:ss.0'),
          learningCourseProgramId: id
        }
      })
      // id?: string;
      // learningCourseProgramId?: string;
      // learningCourseTemplateContentChapterId?: string;
      // learningCourseTemplateContentSessionId?: string;
      // sessionDate?: NodaTimeLocalDate;
      // sessionStartTime?: NodaTimeLocalTime;
      // sessionFinishTime?: NodaTimeLocalTime;
    }
  }

  const onSubmit = async (program: LearningProgramFormValuesSchema): Promise<void> => {
    let navigateToProgramId: string;
    try {
      if (isEdit) {
        await updateCourseProgramPost({ activ8CoursesProgramsCoursesLearningCourseProgramUpdateDto: convertFormToApiRequestBody(program), id: id }).unwrap();
        navigateToProgramId = id as string;
      }
      else {
        const createdProgram = await createCourseProgramPost({ activ8CoursesProgramsCoursesLearningCourseProgramCreateDto: { ...convertFormToApiRequestBody(program), newId: id } }).unwrap();
        navigateToProgramId = createdProgram.id as string;

        // if(program.assignFacilitatorIdentityUserIds && program.assignFacilitatorIdentityUserIds.length > 0){
        //   for (let index = 0; index < program.assignFacilitatorIdentityUserIds.length; index++) {
        //     try {
        //       const assignFacilitatorId = program.assignFacilitatorIdentityUserIds[index].id;
        //       await learningCourseProgramFacilitatorCreatePost({
        //         activ8CoursesProgramsFacilitatorsFacilitatorToLearningCourseProgramAssignmentCreateDto: {
        //           facilitatorIdentityUserId: assignFacilitatorId,
        //           learningCourseProgramId: createdProgram.id
        //         }
        //       }).unwrap();
        //     }
        //     catch(error){
        //       handleError(error, 'Failed to assign trainer');
        //     }
        //   }
        // }
      }

      getLearningCourseProgramQuery?.refetch();
    }
    catch (error) {
      handleError(error);
      return;
    }

    navigate(PATH_DASHBOARD.tenant.program.overview(navigateToProgramId));
    enqueueSnackbar(!isEdit ? 'Created!' : 'Updated!');
  }

  return (
    <Page title={pageTitle}>
      <Container maxWidth={'xl'}>
        {
          courseProgramState ?
            <LearningCourseProgramWizardForm defaultValues={courseProgramState} onSubmit={(program) => onSubmit(program)} courseProgramId={id} isEdit={isEdit} links={links} />
            : undefined}
      </Container>
    </Page>
  );
}
