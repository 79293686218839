// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

import {
    Activ8CoursesContentLearningCourseContentBlock,
    Activ8CoursesContentLearningCourseContentResource,
    Activ8CoursesContentQuizResultsLearningCourseContentQuizResult
} from 'src/api/redux/app/appApi';
import Iconify from 'src/components/Iconify';
import { ResourceContentManager } from 'src/sections/shared/ResourceContentManager';

import { Box, Button, SxProps, Typography, useTheme } from '@mui/material';

import {
    LearningCourseProgramSessionContentQuizBlockView
} from './LearningCourseProgramSessionContentQuizBlockView';
import { htmlToReact } from 'src/utils/htmlToReact';

type LearningCourseProgramSessionContentBlockViewProps = {
    contentBlock: Activ8CoursesContentLearningCourseContentBlock;
    prepareContentResourceReadUrl: (file: Activ8CoursesContentLearningCourseContentResource, isDownload: boolean) => Promise<string>;
    onQuizOptionSelectToggle: (optionId: string, isSelected: boolean) => void;
    onQuizStart: (quizContentBlock: Activ8CoursesContentLearningCourseContentBlock) => void;
    currentQuizQuestionIndex: number | undefined;
    isQuizComplete: boolean;
    quizResults: Activ8CoursesContentQuizResultsLearningCourseContentQuizResult[];
}

export function LearningCourseProgramSessionContentBlockView({ contentBlock, prepareContentResourceReadUrl, onQuizOptionSelectToggle, onQuizStart, currentQuizQuestionIndex, isQuizComplete, quizResults }: LearningCourseProgramSessionContentBlockViewProps) {
    const theme = useTheme();
    const quizResult = quizResults && quizResults.length > 0 ? quizResults.find(q => q.learningCourseContentQuizId === contentBlock.quiz?.id) : undefined;

    const boxStyle: SxProps = isQuizComplete ? {
        border: '2px solid ' + theme.palette.grey[300],
        background: theme.palette.grey[200]
    } : {
        border: '2px solid ' + theme.palette.primary.main,
        background: theme.palette.grey[300]
    }

    return (
        <div>
            {contentBlock.type === 'RichText' ?
                <div className="ql-container">
                    <div className="ql-editor">{htmlToReact(contentBlock.richText as string)}</div>
                </div>
                : undefined}

            {contentBlock.type === 'Resources' || contentBlock.type === 'Media' ?
                <ResourceContentManager
                    defaultValue={contentBlock.resources || []}
                    hideDropzone={true}
                    preload={true}
                    preventDownload={contentBlock.type === 'Media'}
                    restrictedToMedia={contentBlock.type === 'Media'}
                    direction={contentBlock.type === 'Resources' ? 'column' : 'row'}
                    autoplay={false}
                    prepareReadUrl={prepareContentResourceReadUrl}
                />
                : undefined}

            {contentBlock.type === 'Quiz' && currentQuizQuestionIndex === undefined ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', m: 2, borderRadius: '8px', p: 2, ...boxStyle }}>
                    <Typography variant='h5' sx={{ mb: 2 }}>Quiz {contentBlock.quiz?.description}</Typography>
                    {isQuizComplete ?
                        <Iconify sx={{ m: 0, p: 0, color: theme.palette.success.main }} icon='eva:checkmark-circle-2-outline' fontSize={40} />
                        :
                        <Button variant='contained' onClick={() => { onQuizStart(contentBlock) }}>Start quiz</Button>}
                </Box>
                : undefined}

            {contentBlock.type === 'Quiz' && currentQuizQuestionIndex !== undefined ?
                <>
                    <LearningCourseProgramSessionContentQuizBlockView quizResult={quizResult} contentBlock={contentBlock} currentQuizQuestionIndex={currentQuizQuestionIndex} onQuizOptionSelectToggle={onQuizOptionSelectToggle} />
                </>
                : undefined}
        </div>
    )
}